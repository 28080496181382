<ng-container *ngIf="displayedSteps">
    <mat-horizontal-stepper labelPosition="bottom" (selectionChange)="updateStates($event)" [linear]="true">
        <mat-step *ngFor="let item of displayedSteps; let i = index" [completed]="displayedSteps[i].value">
            <mat-card>
                <mat-card-content>
                    <div class="question">{{item.question.text}}</div>
                    <mat-radio-group class="choices" [(ngModel)]="displayedSteps[i].value">
                        <mat-radio-button *ngFor="let option of item.question.choices" [value]="option.value">{{option.text}}</mat-radio-button>
                    </mat-radio-group>
                </mat-card-content>
                <mat-card-actions>
                    <button *ngIf="i !== 0" mat-raised-button matStepperPrevious float-left>Zurück</button>
                    <button *ngIf="i < displayedSteps.length - 1" mat-raised-button matStepperNext float-right>Weiter</button>
                    <button *ngIf="i === displayedSteps.length - 1" mat-raised-button (click)="submitSurvey()" float-right>Speichern</button>
                </mat-card-actions>
            </mat-card>
        </mat-step>
        <ng-template matStepperIcon="number">
            <ion-icon icon="edit"></ion-icon>
        </ng-template>
    </mat-horizontal-stepper>
</ng-container>
