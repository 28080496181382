import {RecurrenceAdapterOptions, RecurrenceRuleModel} from 'calendar/models/recurrence.model';
import {OccurrenceModel} from 'calendar/models/occurrence.model';
import {Observable, of} from 'rxjs';
import {Schedule} from '@rschedule/rschedule';
import 'rxjs-compat/add/observable/from';
import * as moment from 'moment';
import {MomentDateAdapter} from '@rschedule/moment-date-adapter';

export class RecurrenceAdapterResult {
    private result: OccurrenceModel[];

    constructor(private rule: RecurrenceRuleModel | RecurrenceRuleModel[],
                private options: RecurrenceAdapterOptions) { }

    private fetch() {
        const schedule = new Schedule({
            dateAdapter: MomentDateAdapter,
            rrules: Array.isArray(this.rule) ? this.rule : [this.rule]
        });


        this.result = schedule
            .occurrences(this.options)
            .toArray()
            .map(x => ({
                date: x.date
            }));
    }


    fetchAll(): OccurrenceModel[] {
        if (!this.result)
            this.fetch();
        return this.result;
    }

    toObservable(): Observable<OccurrenceModel> {
        if (!this.result)
            this.fetch();
        return of<OccurrenceModel>(...this.result);
    }
}
