import {DefaultMigration, MigrationData} from './default-migration';
import {SurveyModel} from '@core/models';
import {DatabaseCollectionProvider} from '@core/database';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SurveysDefault implements DefaultMigration<SurveyModel> {
    constructor(private db: DatabaseCollectionProvider, private http: HttpClient) { }

    async getData(): Promise<MigrationData<SurveyModel>> {
        const domain = await this.db.getApiDomain();
        const url = `https://${domain}/api/defaults/surveys`;
        try {
            const result = await this.http.get<any>(url).toPromise();
            console.log('result', result);
            return result;
        } catch (e) {
            console.warn(e);
        }
    }
}
