import { Injectable } from '@angular/core';
import {DeviceInterface, LoopBackConfig, PatientApi} from '@plugin/cloud-connect/api';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppSettings} from '@plugin/settings';
import {HttpClient} from '@angular/common/http';

export type ConnectionStage = { stage: 'init' | 'connect' | 'auth' | 'finished' | 'already-connecting', params: any };

@Injectable({ providedIn: 'root' })
export class CloudConnectService {
    private connecting: boolean = false;

    constructor(private settings: AppSettings, private patientApi: PatientApi, private http: HttpClient) { }

    connect(token: string, device: DeviceInterface): Observable<ConnectionStage> {
        const subject = new BehaviorSubject<ConnectionStage>({ stage: 'init', params: { token: token }});
        if (this.connecting) {
            subject.next({ stage: 'already-connecting', params: null });
            return subject;
        } else this.connecting = true;

        const authEndpoint = `https://auth.de-rena.de/api-info?token=${token}`;
        subject.next({ stage: 'connect', params: { authEndpoint: authEndpoint } });
        this.http.get(authEndpoint, { responseType: 'text' }).subscribe(
            async (res) => {
                LoopBackConfig.setBaseURL(res);
                subject.next({ stage: 'auth', params: { endpoint: res }});

                try {
                    const result = await this.patientApi.connectV2({ token: token, device: device }).toPromise();
                    if (!result) {
                        subject.error({ stage: 'auth', message: 'Ungültiges Token.', params: null });
                    } subject.next({ stage: 'finished', params: result });
                    this.connecting = false;
                } catch (e) {
                    subject.error({ stage: 'auth', message: null, params: e });
                    this.connecting = false;
                }
            },
            error => {
                subject.error({ stage: 'connect', params: error });
                this.connecting = false;
            }
        );

        return subject.asObservable();
    }
}
