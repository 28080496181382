import {Validator} from '@library/core-validation/src/lib/validators/validator';

export class MaxLengthValidator implements Validator<string> {
    constructor(private maxLength: number) { }

    validate(value: string): boolean {
        if (value === undefined)
            return true;

        if (value === null)
            return true;

        if (typeof(value) !== 'string')
            return true;

        return value.length < this.maxLength;
    }
}
