<ion-card style="border-left: 10px solid transparent;">
    <ion-item *ngIf="items?.length" class="ion-justify-content-end">
        <ion-label>Alle auswählen</ion-label>
        <ion-checkbox (ngModelChange)="checkAll($event)" [(ngModel)]="checkAllValue" slot="end"></ion-checkbox>
    </ion-item>
    <ion-card-content *ngIf="!items?.length" class="ion-text-center">
        Für diesen Tag sind keine Zeitfenster hinterlegt.
    </ion-card-content>
</ion-card>
<ion-card *ngFor="let item of items; let i = index" style="border-left: 10px solid transparent;" [ngStyle]="{ 'border-color': item.color }">
    <ion-item>
        <span class="time">{{item.start.format('HH:mm')}} - {{item.end.format('HH:mm')}}</span>
        <span class="life-section">{{item.title}}</span>
        <ion-checkbox slot="end" [(ngModel)]="items[i].checked" (ngModelChange)="updateCheckAll()"></ion-checkbox>
    </ion-item>
</ion-card>
