import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SurveyStepModel} from 'survey/models/survey-step.model';
import {SurveyStepperModel} from 'survey/models/survey-stepper.model';

export enum SurveyState {
    Pending = 0,
    Transmission = 1,
    Submitted = 2
}

@Component({
    selector: 'plugin-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss']
})

export class SurveyComponent implements OnInit {
    @Input() steps: SurveyStepperModel;
    @Output() save: EventEmitter<any> = new EventEmitter();

    displayedSteps: SurveyStepModel[];

    constructor() {}

    submitSurvey() {
        this.save.emit(this.steps);
    }

    updateStates(e) {
        this.steps.update(e.selectedIndex);
    }

    ngOnInit() {
        this.steps.getSteps().subscribe(steps => {
            this.displayedSteps = steps;
        });
    }
}
