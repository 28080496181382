import {Injectable} from '@angular/core';
import {Device} from '@ionic-native/device/ngx';
import {Platform} from '@ionic/angular';
import {DeviceInterface} from '@plugin/cloud-connect/api';
import * as uuid from 'uuid';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';

@Injectable({ providedIn: 'root' })
export class DeviceService implements DeviceInterface {
    private _uuid: string;
    private uuidTask: Promise<string>;

    constructor(private device: Device, private currentPlatform: Platform, private uniqueDeviceID: UniqueDeviceID) {
        this.currentPlatform.ready().then(() => {
            if (this.isIOS) {
                this.uuidTask = this.uniqueDeviceID.get();
                this.uuidTask.then(x => this._uuid = x).catch(e => console.error(e));
            }
        });
    }

    get isAndroid(): boolean { return this.currentPlatform.is('android'); }

    get isIOS(): boolean { return this.currentPlatform.is('ios'); }

    get isBrowser(): boolean { return !this.currentPlatform.is('cordova') && !!navigator }

    get deviceId(): string {
      
      if (this.isAndroid)
          return this.device.uuid;
      
      if (this.isIOS)
          return this._uuid;

      if (this.isBrowser)
          return uuid();

      return null;
    }

    get manufacturer(): string {
        if (this.isBrowser || this.isAndroid)
            return navigator.vendor || navigator.appCodeName;
        return this.device.manufacturer
    }

    get model(): string {
        if (this.isBrowser || this.isAndroid)
            return navigator.userAgent;
        return this.device.model
    }

    get platform(): string {
        if (this.isBrowser || this.isAndroid)
            return navigator.platform;
        return this.device.platform
    }

    get version(): string {
        if (this.isBrowser || this.isAndroid)
            return navigator.userAgent;
        return this.device.version
    }

    async complete(): Promise<void> {
        if (this.isBrowser || this.isAndroid)
            return;
        await this.uuidTask;
    }

    toJSON(): DeviceInterface {
        return {
            deviceId: this.deviceId,
            manufacturer: this.manufacturer,
            model: this.model,
            platform: this.platform,
            version: this.version
        }
    }
}
