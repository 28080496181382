import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../models';
import { Localization } from '../localization';
import { localizations } from '../localizations';

const availableLanguages = localizations.map((localization) => localization.language);
const localizationMaps = buildLocalizationMaps(localizations);

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    private readonly languageCodeSubject: BehaviorSubject<Language>;

    get activeLanguage$(): Observable<Language> {
        return this.languageCodeSubject.asObservable();
    }

    get availableLanguages(): Language[] {
        return availableLanguages;
    }

    constructor() {
        this.languageCodeSubject = new BehaviorSubject(localizations[0].language);
    }

    setLanguage(language: Language): void {
        const has = localizationMaps.has(language.code);

        if (!has) {
            throw new Error('language not available');
        }

        this.languageCodeSubject.next(language);
    }

    getLocalization(key: string, parameters: { [key: string]: string } = {}): string {
        const localizationMap = localizationMaps.get(this.languageCodeSubject.value.code);

        if (localizationMap == undefined) {
            return `__${key}__`;
        }

        let text = localizationMap.get(key);

        if (text == undefined) {
            return `__${key}__`;
        }

        for (const [key, value] of Object.entries(parameters)) {
            const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'gi');
            text = text.replace(regex, value);
        }

        return text;
    }
}

function buildLocalizationMaps(localizations: Localization[]): Map<string, Map<string, string>> {
    const maps = new Map<string, Map<string, string>>();

    for (const localization of localizations) {
        const has = maps.has(localization.language.code);

        if (has) {
            throw new Error(`localization ${localization.language.code} already registered`);
        }

        const localizationMap = buildLocalizationMap(localization);
        maps.set(localization.language.code, localizationMap);
    }

    return maps;
}

function buildLocalizationMap(localization: Localization): Map<string, string> {
    const entries = Object.entries(localization.keys);
    const map = new Map<string, string>();

    for (const [key, text] of entries) {
        map.set(key, text);
    }

    return map;
}

