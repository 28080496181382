<mat-grid-list cols="3" rowHeight="20px">
    <mat-grid-tile [colspan]="1" [rowspan]="2">
        <button mwlCalendarPreviousView
                class="mat-button"
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">Zurück</button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="2">
        <button mwlCalendarToday
                class="mat-button"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">Heute</button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" [rowspan]="2">
        <button mwlCalendarNextView
                class="mat-button"
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">Weiter</button>
    </mat-grid-tile>
</mat-grid-list>
