import { Component, Input } from '@angular/core';
import { ColorPickerItem } from './color-picker-item';
import { ModalController } from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import Color from 'color';

@Component({
    selector: 'core-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent{
    @Input() colors: ColorPickerItem[];

    constructor(private modalController: ModalController, private snackBar: MatSnackBar) { }

    toRGBA(code, alpha): string {
        return Color(code).rgb().alpha(alpha).toString();
    }

    dismiss() {
        this.modalController.dismiss({ color: null });
    }

    selectColor(color: ColorPickerItem) {
        if (color.inUse) {
            this.snackBar.open('Diese Farbe wird bereits verwendet.', null, { duration: 2000 });
            return;
        }
        this.modalController.dismiss({ color: color });
    }
}
