import {Injectable} from '@angular/core';
import {DeviceNotificationService} from '@plugin/device-notifications';
import {DatabaseCollectionProvider} from '@core/database';
import {RxCollection, RxDatabase} from 'rxdb';
import {EventDefinition} from 'lib/plugin-calendar/src/lib/models/event-definition.model';
import {DayEvaluationModel} from '@core/models';
import {DATABASE_COLLECTIONS} from '../../../../lib/core-models/src/schema';
import {Subscription} from 'rxjs';
import {NotificationInterceptor} from './notification-interceptor';
import {NotificationUpdate} from './notification-update';
import {NotificationReference} from './notification-reference';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class NotificationSchedulerService {
    private eventCollection: RxCollection<EventDefinition>;
    private dayEvaluationCollection: RxCollection<DayEvaluationModel>;
    private database: RxDatabase;

    private subscriptions: Subscription[];
    private readonly interceptors: NotificationInterceptor[];

    constructor(private notificationService: DeviceNotificationService, private collectionProvider: DatabaseCollectionProvider) {
        this.interceptors = [];
    }

    get running(): boolean { return !!this.subscriptions }

    async init() {
        this.subscriptions = [];
        if (this.database)
            return;

        this.eventCollection = this.collectionProvider.use(DATABASE_COLLECTIONS.EventCollection);
        this.dayEvaluationCollection = this.collectionProvider.use(DATABASE_COLLECTIONS.DayEvaluationCollection);
        this.database = this.collectionProvider.db;
        this.notificationService.subscribeOnClick();
    }

    register(interceptor: NotificationInterceptor) {
        console.log('registering', interceptor);
        this.interceptors.push(interceptor);
    }

    async start() {
        console.log('starting notification scheduler');

        if (this.running) {
            throw new Error('service already started');
        }

        await this.init();

        for (let interceptor of this.interceptors) {
            this.subscriptions.push(
                interceptor.listen().subscribe(x => this.handle(interceptor.groupId, x))
            )
        }
    }

    stop() {
        if (!this.running) {
            throw new Error('service not running');
        }

        this.subscriptions.forEach(x => x.unsubscribe());
        delete this.subscriptions;
    }

    getRefId(group: string, entityId: string) {
        return `${group}-${entityId}`;
    }

    async getRef(group: string, entityId: string): Promise<NotificationReference> {
        return <any>(
            await this.database.getLocal(this.getRefId(group, entityId))
        ).toJSON();
    }

    async createRef(group: string, entityId: string, notificationId: number, data: any): Promise<NotificationReference> {
        if (data.trigger)
            data.trigger = moment(data.trigger).toISOString();

        return <any>(
            await this.database.insertLocal(
                this.getRefId(group, entityId), <NotificationReference>{
                    entityId: entityId,
                    group: group,
                    notificationId: notificationId,
                    lastUpdate: data
                })
        ).toJSON();
    }

    async updateRef(group: string, entityId: string, data: any): Promise<NotificationReference> {
        if (data.trigger)
            data.trigger = moment(data.trigger).toISOString();

        const doc = await this.database.getLocal(this.getRefId(group, entityId));
        if(!doc){
            return null;
        }
        const notificationId = doc.get('notificationId');

        return <any>(
            await this.database.upsertLocal(
                this.getRefId(group, entityId), <NotificationReference>{
                    entityId: entityId,
                    group: group,
                    notificationId: notificationId,
                    lastUpdate: data
                })
        ).toJSON();
    }

    async deleteRef(group: string, entityId: string) {
        const doc = await this.database.getLocal(this.getRefId(group, entityId));
        if (!doc)
            return null;

        const notificationId = doc.get('notificationId');
        await doc.remove();
        return notificationId;
    }

    async handle(group: string, update: NotificationUpdate) {
        console.log('notification', 'handle', group, update);
        switch (update.updateType) {
            case 'create': {
                let notificationId = this.notificationService.send({
                    title: update.title,
                    body: update.message,
                    schedule: { at: new Date(moment(update.trigger).toISOString()) },
                    silent: false,
                });
                let ref = await this.createRef(group, update.id, notificationId, update);
                console.log('notification', 'create', update, ref, notificationId);
                break;
            }

            case 'update': {
                let notificationId = await this.deleteRef(group, update.id);
                await this.createRef(group, update.id, notificationId, update);
                console.log('notification', 'update', update, notificationId);
                this.notificationService.update(notificationId, {
                    title: update.title,
                    body: update.message,
                    schedule: { at: new Date(moment(update.trigger).toISOString()) },
                    silent: false,
                });
                break;
            }

            case 'delete': {
                const notificationId = await this.deleteRef(group, update.id);
                if (!notificationId) {
                    break;
                }
                await this.notificationService.remove(notificationId);
                console.log('notification', 'delete', update, notificationId);
                break;
            }
        }
    }
}
