import {Component, Input, OnInit} from '@angular/core';
import {EventDefinition} from 'calendar/models/event-definition.model';
import * as moment from 'moment';
import {CalendarDataSource} from 'calendar/datasources/calendar.datasource';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'plugin-calendar-event-add',
    templateUrl: './calendar-event-add.component.html',
    styleUrls: ['./calendar-event-add.component.scss']
})
export class CalendarEventAddComponent implements OnInit {
    @Input() date: Date;

    event: EventDefinition<any>;

    constructor(private eventDataSource: CalendarDataSource<any>, private router: Router, private location: Location) { }

    saveEvent(event: EventDefinition<any>) {
        this.eventDataSource.save(event).then(() => {
            this.event = null;
            setTimeout(async () => {
                this.location.back();
            }, 800);
        });
    }

    init(date?: Date) {
        this.event = {
            title: '',
            start: moment(date).toDate(),
            end: moment(date).add(30, 'minutes').toDate(),
            draggable: true,
            resizable: { afterEnd: true, beforeStart: true }
        }
    }

    ngOnInit() {
        this.init(this.date);
    }
}
