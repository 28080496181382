<ion-card>
    <ion-card-content>
        <ion-item>
            <ion-label position="floating">Neuer Vorsatz</ion-label>
            <ion-input [(ngModel)]="item" spellcheck="true" autoComplete="true" autocorrect="true" (keyup.enter)="add()"></ion-input>
            <ion-icon (click)="add()" slot="end" icon="save" style="margin-bottom: -16px"></ion-icon>
        </ion-item>
    </ion-card-content>
</ion-card>

<ion-card *ngIf="items && items.length">
    <ion-card-content>
        <ion-list>
            <ion-reorder-group (ionItemReorder)="reorder($event)" [disabled]="false">
                <ion-item *ngFor="let current of items">
                    <ion-label>{{current.text}}</ion-label>
                    <ion-reorder slot="start"></ion-reorder>
                    <ion-icon name="create" slot="end" (click)="edit(current.index)"></ion-icon>
                    <ion-icon name="trash" slot="end" (click)="delete(current.index)"></ion-icon>
                </ion-item>
            </ion-reorder-group>
        </ion-list>
    </ion-card-content>
</ion-card>