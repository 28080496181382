import {EventEmitter, Injectable} from '@angular/core';
import {DatabaseCollectionProvider, DatabaseRepository, DatabaseService, DbContext} from '@core/database';
import {TimeframeModel} from '@library/core-models/src/timeframe.model';
import {DayStatisticService} from '@plugin/statistics';
import * as moment from 'moment';
import {RxCollection, RxDocument} from 'rxdb';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {LifeSectionService} from '@plugin/life-section';
import {LifeSectionModel, TaskModel} from '@core/models';
import {DeviceNotificationService} from '@plugin/device-notifications';

@Injectable({
    providedIn: 'root'
})
export class TimeframeService {
    private timeframeCollection: RxCollection<TimeframeModel>;
    private eventCollection: RxCollection<EventDefinition>;
    private lifeSectionCollection: RxCollection<LifeSectionModel>;

    constructor(databaseService: DatabaseService, private dayStatisticService: DayStatisticService, private lifeSectionService: LifeSectionService, private notificationService: DeviceNotificationService) {
        databaseService.databaseAwaiter().then(collectionProvider => {
            this.timeframeCollection = collectionProvider.use<TimeframeModel>(DATABASE_COLLECTIONS.TimeframeCollection);
            this.eventCollection = collectionProvider.use<EventDefinition>(DATABASE_COLLECTIONS.EventCollection);
            this.lifeSectionCollection = collectionProvider.use<LifeSectionModel>(DATABASE_COLLECTIONS.LifeSectionCollection);
        })
    }

    async getAvailableTasks(timeframeId): Promise<TaskModel[]> {
        const lifeSection = await this.lifeSectionService.get(timeframeId);
        if (!lifeSection)
            return [];
        return lifeSection.tasks;
    }

    async cancel(timeframeId: string, reason: string) {
        let timeframe = await this.timeframeCollection.findOne(timeframeId).exec();
        await timeframe.atomicUpdate(doc => {
            doc.done = false;
            doc.canceled = true;
            doc.cancelReason = reason;
            return doc;
        });

        let tasks = [];
        let timeframeData = timeframe.toJSON();
        for (let task of timeframeData.tasks) {
            if (task.multi || !task.checked)
                tasks.push(task);
        }

        if (tasks.length) {
            tasks.forEach(x => x.checked = false);
            let lifeSection = await this.lifeSectionCollection.findOne(timeframeData.lifeSectionId).exec();
            await lifeSection.atomicUpdate(doc => {
                doc.tasks.push(...tasks);
                return doc;
            });
        }

        let event = await this.eventCollection.findOne(timeframe.eventId).exec();
        event.atomicUpdate(doc => {
            if (!doc.meta || !doc.meta.options) {
                doc.meta = {
                    feature: 'timeframe',
                    options: {
                        timeframeId: timeframeId,
                        lifeSectionId: timeframe.lifeSectionId
                    }
                }
            }
            doc.meta.options.hidden = true;

            this.notificationService.initialized
            return doc;
        })
    }

    async save(timeframe: TimeframeModel, updateStats: boolean = false): Promise<TimeframeModel> {
        let data = JSON.parse(JSON.stringify(timeframe));
        let result = await this.timeframeCollection.atomicUpsert(data);
        if (updateStats)
            await this.dayStatisticService.updateDayStatistic(moment(timeframe.start));
        return result.toJSON();
    }

    async single(id: any, dataOnly: boolean = true): Promise<TimeframeModel | RxDocument<TimeframeModel> | any> {
        let document = await this.timeframeCollection.findOne(id).exec();
        return dataOnly ? document.toJSON() : document;
    }
}
