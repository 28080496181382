import { Injectable } from '@angular/core';
import { AppSettings } from '@plugin/settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileService } from './file.service';
import { Platform } from '@ionic/angular';

export type BackgroundText = { fontSize: number, text: string, color: string };

export const BackgroundTextFontSizeTable = [
    { min: 0, max: 12, size: 36 },
    { min: 13, max: 20, size: 28 },
    { min: 21, max: 40, size: 24 },
    { min: 41, max: 100, size: 18 }
];

@Injectable({ providedIn: 'root' })
export class BackgroundManagerService {
    private readonly image: BehaviorSubject<any> = new BehaviorSubject<any>('/assets/default.png');
    private readonly text: BehaviorSubject<BackgroundText> = new BehaviorSubject<BackgroundText>({ fontSize: 0, text: '', color: '#000' });
    private buffer: any;
    private isMobile: boolean;

    constructor(private settings: AppSettings, private fileService: FileService, private platform: Platform) {
        this.isMobile = (this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova');
    }

    private async load() {
        const backgroundText = this.settings.get<BackgroundText>('background-text', { fontSize: 0, text: '', color: '#000' });
        backgroundText.fontSize = this.suggestFontSize(backgroundText.text);
        this.text.next(backgroundText);

        this.buffer = this.settings.get('background-image');
        if (this.buffer) {
            this.image.next(this.buffer);
        }
    }

    suggestFontSize(text: string): number {
        const entry = BackgroundTextFontSizeTable.find(x => x.min <= text.length && x.max >= text.length);
        return entry ? entry.size : 14;
    }

    setText(text: string, color: string) {
        const backgroundText = { fontSize: this.suggestFontSize(text), text: text, color: color };
        this.settings.set('background-text', backgroundText);
        this.text.next(backgroundText);
    }

    async setImage(uri: string) {
        this.settings.set('background-image', uri);
        this.buffer = uri;
    }

    backgroundImage(): Observable<any> {
        if (!this.buffer) {
            this.load();
        }
        return this.image;
    }

    backgroundText(): Observable<BackgroundText> {
        if (!this.buffer) {
            this.load();
        }
        return this.text;
    }
}
