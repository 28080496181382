import {JsonSchema} from 'rxdb';

export const useProperties = (schema: JsonSchema) => {
    const props = JSON.parse(JSON.stringify(schema.properties));
    const keys = Object.keys(props);
    for (let key of keys) {
        if (props[key]['primary'])
            delete props[key]['primary'];
    }
    return props;
};
