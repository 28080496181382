/* tslint:disable */
import {
  Coach,
  PatientToken,
  ReplicationEndpoint,
  Device
} from '../index';

declare var Object: any;
export interface PatientInterface {
  "studyNumber"?: string;
  "endpointPrefix"?: string;
  "description"?: string;
  "created"?: Date;
  "survey"?: any;
  "lastPhq9Value"?: number;
  "lastPhq9Date"?: Date;
  "suicidalityRating"?: number;
  "lastSync"?: Date;
  "wellbeing"?: number;
  "lastDayEvaluation"?: Date;
  "wellbeingDate"?: number;
  "unlinkReason"?: number;
  "deleted"?: Date;
  "id"?: any;
  "primaryCoachId"?: any;
  "endpointId"?: any;
  primaryCoach?: Coach;
  coaches?: Coach[];
  tokens?: PatientToken[];
  endpoint?: ReplicationEndpoint;
  devices?: Device[];
}

export class Patient implements PatientInterface {
  "studyNumber": string;
  "endpointPrefix": string;
  "description": string;
  "created": Date;
  "survey": any;
  "lastPhq9Value": number;
  "lastPhq9Date": Date;
  "suicidalityRating": number;
  "lastSync": Date;
  "wellbeing": number;
  "lastDayEvaluation": Date;
  "wellbeingDate": number;
  "unlinkReason": number;
  "deleted": Date;
  "id": any;
  "primaryCoachId": any;
  "endpointId": any;
  primaryCoach: Coach;
  coaches: Coach[];
  tokens: PatientToken[];
  endpoint: ReplicationEndpoint;
  devices: Device[];
  constructor(data?: PatientInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Patient`.
   */
  public static getModelName() {
    return "Patient";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Patient for dynamic purposes.
  **/
  public static factory(data: PatientInterface): Patient{
    return new Patient(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Patient',
      plural: 'Patients',
      path: 'Patients',
      idName: 'id',
      properties: {
        "studyNumber": {
          name: 'studyNumber',
          type: 'string'
        },
        "endpointPrefix": {
          name: 'endpointPrefix',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "created": {
          name: 'created',
          type: 'Date'
        },
        "survey": {
          name: 'survey',
          type: 'any'
        },
        "lastPhq9Value": {
          name: 'lastPhq9Value',
          type: 'number'
        },
        "lastPhq9Date": {
          name: 'lastPhq9Date',
          type: 'Date'
        },
        "suicidalityRating": {
          name: 'suicidalityRating',
          type: 'number'
        },
        "lastSync": {
          name: 'lastSync',
          type: 'Date'
        },
        "wellbeing": {
          name: 'wellbeing',
          type: 'number'
        },
        "lastDayEvaluation": {
          name: 'lastDayEvaluation',
          type: 'Date'
        },
        "wellbeingDate": {
          name: 'wellbeingDate',
          type: 'number'
        },
        "unlinkReason": {
          name: 'unlinkReason',
          type: 'number'
        },
        "deleted": {
          name: 'deleted',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "primaryCoachId": {
          name: 'primaryCoachId',
          type: 'any'
        },
        "endpointId": {
          name: 'endpointId',
          type: 'any'
        },
      },
      relations: {
        primaryCoach: {
          name: 'primaryCoach',
          type: 'Coach',
          model: 'Coach',
          relationType: 'belongsTo',
                  keyFrom: 'primaryCoachId',
          keyTo: 'id'
        },
        coaches: {
          name: 'coaches',
          type: 'Coach[]',
          model: 'Coach',
          relationType: 'hasMany',
          modelThrough: 'CoachPatient',
          keyThrough: 'coachId',
          keyFrom: 'id',
          keyTo: 'patientId'
        },
        tokens: {
          name: 'tokens',
          type: 'PatientToken[]',
          model: 'PatientToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'patientId'
        },
        endpoint: {
          name: 'endpoint',
          type: 'ReplicationEndpoint',
          model: 'ReplicationEndpoint',
          relationType: 'belongsTo',
                  keyFrom: 'endpointId',
          keyTo: 'id'
        },
        devices: {
          name: 'devices',
          type: 'Device[]',
          model: 'Device',
          relationType: 'hasMany',
          modelThrough: 'PatientDevice',
          keyThrough: 'deviceId',
          keyFrom: 'id',
          keyTo: 'patientId'
        },
      }
    }
  }
}
