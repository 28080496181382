import {Injectable} from '@angular/core';
import * as Sentry from 'sentry-cordova';
import {Severity} from 'sentry-cordova';

@Injectable({ providedIn: 'root' })
export class LoggingService {
    write(message: string, logLevel: Severity = Severity.Log) {
        return Sentry.captureMessage(message, logLevel);
    }

    writeEvent(message: string, logLevel: Severity) {
        return Sentry.captureMessage(message, logLevel);
    }
}
