<div class="ion-text-center" no-padding no-margin>
    <ion-text>Bist Du auf den Stolperstein <b>"{{text}}"</b> gestoßen?</ion-text>
    <ion-radio-group>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button color="primary" [expand]="true" [fill]="!occurredValue ? 'outline' : 'solid'" (click)="occurred = readOnly ? occurred : true">Ja</ion-button>
                </ion-col>
                <ion-col>
                    <ion-button color="primary" [expand]="true" [fill]="occurredValue ? 'outline' : 'solid'" (click)="occurred = readOnly ? occurred : false">Nein</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-radio-group>
</div>

