import {RxJsonSchema} from 'rxdb';
import {TimeframeSchema} from '@library/core-models/src/schema/timeframe.schema';
import {useProperties} from '@library/core-models/src/schema/schema-helper';

export const TimeframeEvaluationSchema: RxJsonSchema | any = {
    "version": 0,
    "type": "object",
    "title": "Day Stats",
    "properties": {
        "timeframeId": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "timeframeSnapshot": {
            "type": "object",
            "properties": useProperties(TimeframeSchema)
        },
        "resolution": {
            "type": ["object", "null"],
            "properties": {
                "rating": ["number", "null"]
            }
        },
        "conflict": {
            "type": ["object", "null"],
            "properties": {
                "occurred": ["boolean", "null"],
                "rating": ["number", "null"]
            }
        },
        "wellbeing": {
            "type": ["number", "null"],
        },
        "durationVariance": {
            "type": ["number", "null"],
        },
        "eventDate": {
            "type": ["string", "null"],
        },
        "created": {
            "type": ["string", "null"],
        },
        "readonly": {
            "type": ["boolean", "null"],
        }
    }
};
