import {RxJsonSchema} from 'rxdb';

export const SurveySchema: RxJsonSchema | any = {
    "version": 3,
    "type": "object",
    "title": "surveys",
    "additionalProperties": true,
    "properties": {
        "key": {
            "type": "string",
            "primary": true,
        },
        "type": {
            "type": "string"
        },
        "title": {
            "type": "string"
        },
        "description": {
            "type": "string"
        },
        "start": {
            "type": "string"
        },
        "until": {
            "type": ["string", "null"]
        },
        "frequency": {
            "type": ["string", "null"]
        },
        "interval": {
            "type": ["number", "null"]
        },
        "questions": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "question": {
                        "type": "string"
                    },
                    "choices": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "property": {
                                "text": {
                                    "type": "string",
                                },
                                "value": {
                                    "type": ["string", "number", "boolean"]
                                }
                            }
                        }
                    }
                }
            }
        },
        "labels": {
            "type": "object",
            "properties": {
                "menu_item_title": {
                    "type": "string"
                },
                "statistic_title": {
                    "type": "string"
                },
                "statistic_description": {
                    "type": "string"
                },
                "statistic_graph_name": {
                    "type": "string"
                },
                "statistic_graph_color": {
                    "type": "string"
                },
                "statistic_max_ticks": {
                    "type": "number"
                },
                "survey_name": {
                    "type": "string"
                },
                "stateball_good_label":{
                    "type":"string"
                },
                "stateball_medium_label":{
                    "type":"string"
                },
                "stateball_bad_label":{
                    "type":"string"
                },
                "stateball_critical_label":{
                    "type":"string"
                },
                "stateball_good_max_value":{
                    "type":"number"
                },
                "stateball_medium_max_value":{
                    "type":"number"
                },
                "stateball_bad_max_value":{
                    "type":"number"
                },
                "stateball_critical_max_value":{
                    "type":"number"
                },
            }
        }
    }
};
