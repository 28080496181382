<ng-container *ngIf="ready">
    <ion-app *ngIf="view === 'app'">
        <ion-split-pane contentId="main">
            <ion-menu #menu contentId="main">
                <ion-header>
                    <ion-toolbar color="header">
                        <ion-title>DE-RENA</ion-title>
                        <ion-buttons slot="end">
                            <ion-button (click)="gotToSettings(); menu.toggle()">
                                <ion-icon name="settings"></ion-icon>
                            </ion-button>
                            <ion-button *ngIf="!disableSync" (click)="cloudConnect(); menu.toggle()">
                                <ion-icon color="menuIcon" [src]="'/assets/link-icon.svg'"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-toolbar>
                </ion-header>
                <ion-content>
                    <ion-list>
                        <ng-container *ngFor="let group of appPages">
                            <ion-label class="list-header">{{group.title}}</ion-label>
                            <ion-menu-toggle auto-hide="false" *ngFor="let p of group.items">
                                <ion-item [featureToggle]="p['feature']" [routerDirection]="p['root'] ? 'root' : 'forward'" [routerLink]="[p['url']]">
                                    <ion-icon slot="start" [name]="p['icon']"></ion-icon>
                                    <ion-label>
                                        {{p["title"]}}
                                    </ion-label>
                                </ion-item>
                            </ion-menu-toggle>
                        </ng-container>
                    </ion-list>
                </ion-content>
            </ion-menu>
            <ion-router-outlet id="main"></ion-router-outlet>
        </ion-split-pane>
    </ion-app>
    <ion-app *ngIf="view === 'login'">
        <ion-content>
            <ion-card>
                <ion-card-content>
                    <div style="text-align: center; padding: 20px 10px">
                        <img src="/assets/splash-screen.png" alt="">
                    </div>

                    <div class="ion-text-center" *ngIf="!trialExpired">
                        Wähle die Test-Option, wenn Du DE-RENA 14 Tage testen möchtest.
                    </div>
                    <div class="spacer">&nbsp;</div>
                    <ion-button *ngIf="!trialExpired" expand="block" class="ion-margin-top" (click)="connectToTrial()">
                        14 Tage testen
                    </ion-button>
                    <div class="ion-text-center" style="padding-top: 10px;" *ngIf="!trialExpired">oder</div>
                    <div class="ion-text-center" style="padding-top: 10px;">Scanne den Zugangscode, den Du von Deinem Coach erhalten hast. Klicke hierzu auf das kleine QR-Code Symbol rechts. Alternativ kannst Du Deinen Code manuell eingeben.</div>
                    <ion-item>
                        <ion-label position="floating">Zugangscode</ion-label>
                        <ion-input [(ngModel)]="value" autofocus="true"></ion-input>
                        <ion-icon style="position: relative; top: 13px" slot="end" name="qr-code-outline" (click)="scanCode()"></ion-icon>
                    </ion-item>
                    <ion-button expand="block" style="margin-top: 10px;" (click)="onClickConnect()">
                        <ion-icon slot="start" name="log-in"></ion-icon>
                        Mit Code anmelden
                    </ion-button>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-app>
</ng-container>
<ion-app *ngIf="view === 'pin'">
    <ion-content>
        <pin-lock></pin-lock>
    </ion-content>
</ion-app>
<ion-app *ngIf="view === 'privacy-policy'">
    <ion-content>
        <privacy-policy></privacy-policy>
    </ion-content>
</ion-app>
