import {EventDefinition} from 'calendar/models/event-definition.model';
import {BaseModel, DocumentIndexCreator, TaskModel} from '@core/models';
import * as moment from 'moment';
import {ConflictsModel} from '@library/core-models/src/conflicts.model';
import {ResolutionModel} from '@library/core-models/src/resolution.model';

export interface EventTemplateColorModel {
    primary: string;
    secondary: string;
}

export interface EventTemplateActionModel {
    label: string;
    cssClass?: string;
    onClick({ event }: {
        event: EventDefinition;
    }): any;
}

export interface EventTemplateModel extends BaseModel {
    id?: string | number;
    weekday: number;
    lifeSectionId: string;
    groupIndex?: number;
    title: string;
    start: Date;
    end?: Date;
    parent?: string | number;
    color?: EventTemplateColorModel;
    tasks: TaskModel[];
    conflict: ConflictsModel;
    resolution: ResolutionModel;
    actions?: EventTemplateActionModel[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    recurrence?: null;
    isNew?: boolean;
    meta?: any;
}

export const EventTemplateIndexCreator: DocumentIndexCreator<EventTemplateModel> = (o, i) => {
    let weekday = moment(o.start).weekday();
    if (weekday-- === 0)
        weekday = 6;

    const index = `${o.groupIndex}/${weekday}`;
    if (!i) {
        let parts = o._id.split('/');
        i = parts[parts.length - 1];
    }

    return {
        fullIndexedId: `${index}/${i}`,
        id: i,
        index: index
    }
};
