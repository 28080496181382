export interface ReorderableModel {
    index?: number;
}

export function reorderArray<T extends ReorderableModel>(array: T[], oldIndex: number, newIndex: number): T[] {
    array[oldIndex].index = newIndex;

    if (oldIndex < newIndex) {
        for (let i = newIndex; i !== 0; --i) {
            if (i === oldIndex || !array[i])
                continue;

            array[i].index = array[i].index-1;
        }
    } else {
        for (let i = newIndex; i < array.length; ++i) {
            if (i === oldIndex || !array[i])
                continue;

            array[i].index = array[i].index+1;
        }
    }

    let idx = 0;
    array = array.sort((a, b) => a.index - b.index);
    array.forEach(x => x.index = idx++);

    return array;
}