import { Injectable } from '@angular/core';
import { File, RemoveResult, Entry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';

@Injectable({ providedIn: 'root' })
export class FileService {

    readonly backgroundImagesDir = '';

    constructor(private file: File, private webView: WebView) { }

    async copyFile(uri: string): Promise<Entry> {
        if (!uri) {
            return;
        }

        const newBasePath = this.file.dataDirectory;
        const tempFileName = uri.substr(uri.lastIndexOf('/') + 1);
        const tempBasePath = uri.substr(0, uri.lastIndexOf('/') + 1);
        const newFileName = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4) + tempFileName;
        return await this.file.copyFile(tempBasePath, tempFileName, newBasePath, newFileName);
    }

    async listDirFiles(dirName: string): Promise<Entry[]> {
        return await this.file.listDir(this.file.dataDirectory, dirName);
    }

    async deleteFile(fileName: string): Promise<RemoveResult> {
        return await this.file.removeFile(this.file.dataDirectory, fileName);
    }

    convertFileUrlToIonicUrl(fileUrl: string) {
        return this.webView.convertFileSrc(fileUrl);
    }

    getFileUrl(fileName: string) {
        return this.file.dataDirectory + fileName;
    }
}
