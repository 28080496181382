import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LifeSectionModel} from '@core/models';

@Component({
    selector: 'plugin-life-section-edit',
    templateUrl: './life-section-edit.component.html'
})
export class LifeSectionEditComponent implements OnInit {
    @Output() itemChange = new EventEmitter<LifeSectionModel>();
    @Output() changeColor = new EventEmitter();
    @Input() get item(): LifeSectionModel { return this.itemValue }

    itemValue: LifeSectionModel;
    fragment: any;

    constructor() { }

    set item(val: LifeSectionModel) {
        this.itemValue = val;
        this.itemChange.emit(this.itemValue);
    }

    ngOnInit() {}
}
