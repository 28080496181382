/* tslint:disable */
import {
  Coach,
  Patient
} from '../index';

declare var Object: any;
export interface PatientTokenInterface {
  "id": string;
  "used"?: boolean;
  "coachId"?: any;
  "patientId"?: any;
  coach?: Coach;
  patient?: Patient;
}

export class PatientToken implements PatientTokenInterface {
  "id": string;
  "used": boolean;
  "coachId": any;
  "patientId": any;
  coach: Coach;
  patient: Patient;
  constructor(data?: PatientTokenInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PatientToken`.
   */
  public static getModelName() {
    return "PatientToken";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PatientToken for dynamic purposes.
  **/
  public static factory(data: PatientTokenInterface): PatientToken{
    return new PatientToken(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PatientToken',
      plural: 'PatientTokens',
      path: 'PatientTokens',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "used": {
          name: 'used',
          type: 'boolean'
        },
        "coachId": {
          name: 'coachId',
          type: 'any'
        },
        "patientId": {
          name: 'patientId',
          type: 'any'
        },
      },
      relations: {
        coach: {
          name: 'coach',
          type: 'Coach',
          model: 'Coach',
          relationType: 'belongsTo',
                  keyFrom: 'coachId',
          keyTo: 'id'
        },
        patient: {
          name: 'patient',
          type: 'Patient',
          model: 'Patient',
          relationType: 'belongsTo',
                  keyFrom: 'patientId',
          keyTo: 'id'
        },
      }
    }
  }
}
