import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LifeSectionModel} from '@core/models';

@Component({
    selector: 'plugin-life-section-list',
    templateUrl: './life-section-list.component.html'
})
export class LifeSectionListComponent {
    @Input() items: LifeSectionModel[];

    @Output() delete: EventEmitter<LifeSectionModel> = new EventEmitter();
    @Output() edit: EventEmitter<LifeSectionModel> = new EventEmitter();

    cleanupUI(e) {
        e.closeSlidingItems();
    }
}
