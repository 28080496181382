import {Validator} from '@library/core-validation/src/lib/validators/validator';

export class ValueRequiredValidator<T = any> implements Validator<T> {
    validate(value: T): boolean {
        if (value === undefined)
            return false;

        if (value === null)
            return false;

        if (typeof(value) === 'string') {
            if (value.length === 0)
                return false;
        }

        if (Array.isArray(value)) {
            if (value.length === 0)
                return false;
        }

        return true;
    }
}
