import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';

export class TooltipFormatter extends CalendarEventTitleFormatter {
    monthTooltip(event: CalendarEvent): string {
        return;
    }

    weekTooltip(event: CalendarEvent): string {
        return;
    }

    dayTooltip(event: CalendarEvent): string {
        return;
    }
}