import {Injectable} from '@angular/core';
import {Notifier} from '@library/core-validation/src/lib/notifiers';
import {ValidationHolder} from '@library/core-validation/src/lib/holders';

@Injectable({ providedIn: 'root' })
export class ValidationService {
    constructor(private notifier: Notifier) { }

    createHolder<TInput>(): ValidationHolder<TInput> {
        return new ValidationHolder<TInput>(this.notifier);
    }
}
