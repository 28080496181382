import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {DatabaseCollectionProvider, DatabaseService} from '@core/database';
import * as moment from 'moment';
import {DefaultData} from '../setup/default-data';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SetupService {
    constructor(private settings: AppSettings, private databaseService: DatabaseService, private http: HttpClient) { }

    async hasDefaultData(): Promise<boolean> {
        await this.settings.awaiter();
        console.log('has default data', DefaultData);
        return this.settings.get('default-created', false);
    }

    async createDefaultData(): Promise<void> {
        await this.settings.awaiter();
        const db = await this.databaseService.databaseAwaiter();

        for (let Provider of DefaultData) {
            const instance = await new Provider(db, this.http).getData();
            const collection = db.use(instance.collection);

            for (let entry of instance.data) {
                console.log('migrating default', entry, 'to collection', collection);
                await collection.atomicUpsert(entry);
            }
        }
        this.settings.set('init-timestamp', moment().unix());
        return this.settings.set('default-created', true);
    }
}
