import { Injectable } from '@angular/core';
import { CoreSocketInterface } from '@library/core-socket/src/lib/core-socket.interface';


@Injectable({
    providedIn: 'root'
})
export class CoreSocketService implements CoreSocketInterface {

}


/* SERVER SIDE

var express = require('express');
var socket = require('socket.io');

//App setup

var app = express();
var server = app.listen(2500, function () {
    console.log("listening on requests on port 2500");
});


//Socket setup
var io = socket(server);

io.on('connection', function (socket) {
    console.log("made socket connection", socket.id);

    socket.on('chat', function (data) {
        io.sockets.emit('chat', data);
    });

    socket.on('typing', function (data) {
        socket.broadcast.emit('typing', data);
    });

    socket.on('pingi', function (data) {
        socket.emit('pongi', data);
    });

    socket.on('sendData', function (data){
        socket.emit('dataReceived', true);
    });

    setTimeout(() => {
        socket.emit('waitTest', {name: "Wait()", emitted: true, data:"Some Data"});
    }, 20000);

    let counter = 1;
    setInterval(() => {
        socket.emit('listenTest', {name: "Listen()", emitted: true, data:"Listen Test", count: counter++});
    }, 5000);
});

*/
