import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import { DatabaseCollectionProvider } from '@library/core-database/src/public_api';
import { Patient, PatientApi } from '@library/plugin-cloud-connect/src/lib/api';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PinLockService {
    private valid: boolean = false;
    private state = new Subject();
    pinChange: boolean = false;

    private statusCodes = {
        EXPIRED: 1,
        INVALID: 2,
        USED: 3,
        VALID: 4
    };

    constructor(private appSettings: AppSettings, private db: DatabaseCollectionProvider, private patientApi: PatientApi, private http: HttpClient) { }

    waitForPin() {
        if (!environment.enablePinLock) {
            return new Promise<void>(resolve => resolve());
        }

        return new Promise<void>(resolve => this.state.subscribe(() => resolve()))
    }

    hasPin(): boolean {
        if (!environment.enablePinLock)
            return true;
        return this.appSettings.get('device-pin', false);
    }

    isValid(): boolean {
        if (!environment.enablePinLock)
            return true;
        return this.valid;
    }

    setupPin(value) {
        if (!environment.enablePinLock)
            return;

        this.appSettings.set('device-pin', value);
        this.valid = true;
        this.state.next();
    }

    validatePin(value: string): boolean{
        if (!environment.enablePinLock)
            return true;

        let pin = this.appSettings.get('device-pin');
        if (!pin)
            return false;

        if (pin !== value)
            return false;

        this.valid = true;
        this.state.next();
        return true;
    }

    async resetPIN(){
        const syncSettings = await this.db.getSyncSettings();
        let patientId = syncSettings.uri.split('-')[1];
        let arr = syncSettings.uri.split('@')[1];
        let hospital = arr.substring(0, arr.indexOf(':'));

        const url = 'https://' + hospital + '/api/Patients/' + patientId + '/resetPin';
        //await this.patientApi.resetPIN(patient.id).toPromise();
        await this.http.post(url, null).toPromise();
    }

    async validatePuk(puk: string){
        let syncSettings = await this.db.getSyncSettings();
        let arr = syncSettings.uri.split('@')[1];
        let hospital = arr.substring(0, arr.indexOf(':'));
        const patientId = syncSettings.uri.split('-')[1];

        let data = {
            puk: puk,
            patientId: patientId
        };
        const httpParams: HttpParams = new HttpParams({fromObject: data});

        const url = 'https://' + hospital + '/api/PatientPuks' + '/validate';
        let status  = await this.http.get(url, {params: httpParams}).toPromise();
        if(status['status'] === this.statusCodes.VALID){
            this.appSettings.set('device-pin', false);
            return true;
        }else{
            return false;
        }
    }
}
