import {Notifier} from '@library/core-validation/src/lib/notifiers/notifier';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';

@Injectable()
export class SnackBarNotifier implements Notifier {
    constructor(private snackBar: MatSnackBar) { }

    show(message: string): void {
        this.snackBar.open(message, null, { duration: 3000 });
    }
}
