import { NgModule } from '@angular/core';
import { SurveyComponent } from './survey.component';
import { DatabaseModule } from '@core/database';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
    declarations: [SurveyComponent],
    imports: [
        DatabaseModule,
        CommonModule,
        IonicModule,
        MatRadioModule,
        MatToolbarModule,
        MatCardModule,
        MatStepperModule,
        MatDividerModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatGridListModule
    ],
    exports: [SurveyComponent],
    providers: []
})
export class SurveyModule { }
