import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {CalendarDateFormatter, CalendarEventTitleFormatter} from 'angular-calendar';
import {DateFormatterDe} from '../formatters/date-formatter.de';
import {TooltipFormatter} from '../formatters/tooltip-formatter';
import {WeekDay} from 'calendar-utils';
import {Subject} from 'rxjs';
import moment from 'moment';
import {AppSettings} from '@plugin/settings';

@Component({
    selector: 'plugin-event-templates-day',
    templateUrl: './event-templates-day.component.html',
    styleUrls: ['./event-templates-day.component.scss'],
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: DateFormatterDe
        },
        {
            provide: CalendarEventTitleFormatter,
            useClass: TooltipFormatter
        }
    ]
})
export class EventTemplatesDayComponent implements OnInit {
    //@Input() get events(): EventDefinition[] { return this.eventsValue }
    @Input() get day(): Date { return this.dayValue }
    @Input() refresher: Subject<any> = new Subject<any>();
    @Input() events: EventDefinition[];
    @Input() get dragging(): boolean { return this.draggingValue }

    @Output() eventsChange: EventEmitter<EventDefinition[]> = new EventEmitter();
    @Output() dayChange: EventEmitter<Date> = new EventEmitter();
    @Output() dayClicked: EventEmitter<WeekDay> = new EventEmitter();
    @Output() hourClicked: EventEmitter<Date> = new EventEmitter();
    @Output() eventClicked: EventEmitter<EventDefinition | any> = new EventEmitter();
    @Output() eventTimeChange: EventEmitter<any> = new EventEmitter();
    @Output() draggingChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('wrapper', {static: true}) wrapper: ElementRef;

    dayValue: Date;
    eventsValue: EventDefinition[];
    draggingValue: boolean;
    disableDragAndScroll: boolean;

    constructor(private settings: AppSettings) { }

    /*set events(value: EventDefinition[]) {
        this.eventsValue = value;
        this.eventsChange.emit(value);
    }*/

    set day(value: Date) {
        this.dayValue = value;
        this.dayChange.emit(value);
        this.refresher.next(value);
    }

    set dragging(value: boolean) {
        this.draggingValue = value;
        this.draggingChange.emit(value);
    }

    ngOnInit() {
        this.disableDragAndScroll = !this.settings.get('calendar-drag-scroll', false);
        this.refresher.next(this.day);
        let sub = this.refresher.subscribe(() => {
            sub.unsubscribe();
            setTimeout(() => {
                if (this.wrapper) {
                    let now = moment().startOf('hour');
                    let hour = now.hour();
                    let ratio = hour / 24;
                    let element: HTMLElement = this.wrapper.nativeElement;
                    element.scrollTo({ top: element.scrollHeight * ratio, behavior: 'auto' });
                }
            }, 100)
        })
    }
}
