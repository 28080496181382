<plugin-calendar-event *ngIf="event" [event]="event" (save)="saveEvent($event)"></plugin-calendar-event>
<ng-container *ngIf="!event">
    <div class="cdk-overlay-container">
        <div class="cdk-overlay-backdrop cdk-overlay-backdrop-showing"></div>
        <div class="cdk-global-overlay-wrapper" dir="ltr" style="justify-content: center; align-items: center;">
            <div id="cdk-overlay-8" class="cdk-overlay-pane" style="max-width: 80vw; pointer-events: auto; position: static;">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
</ng-container>