import {Validator} from '@library/core-validation/src/lib/validators/validator';

export class MinLengthValidator implements Validator<string> {
    constructor(private minLength: number) { }

    validate(value: string): boolean {
        if (value === undefined)
            return false;

        if (value === null)
            return false;

        if (typeof(value) !== 'string')
            return false;

        return value.length >= this.minLength;
    }
}
