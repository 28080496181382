import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventTemplateSelectionItem} from '@library/plugin-event-templates/src/lib/models/event-template-selection-item';

@Component({
    selector: 'plugin-event-template-selection',
    templateUrl: './event-template-selection.component.html',
    styleUrls: ['./event-template-selection.component.scss']
})
export class EventTemplateSelectionComponent implements OnInit {
    @Input() get items(): EventTemplateSelectionItem[] { return this.itemsValue }
    @Output() itemsChange: EventEmitter<EventTemplateSelectionItem[]> = new EventEmitter();

    itemsValue: EventTemplateSelectionItem[];
    checkAllValue: boolean;

    constructor() { }

    set items(value: EventTemplateSelectionItem[]) {
        this.itemsValue = value;
        this.itemsChange.emit(value);
    }

    checkAll(checked) {
        let result = [...this.items];
        result.forEach(x => x.checked = checked);
        this.items = result;
    }

    updateCheckAll() {
        this.checkAllValue = !this.items.some(x => !x.checked);
    }

    ngOnInit() { }
}
