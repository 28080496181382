import {DefaultMigration, MigrationData} from './default-migration';
import {FormModel} from '@core/models';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {DatabaseCollectionProvider} from '@core/database';
import {HttpClient} from '@angular/common/http';

export class FormDefault implements DefaultMigration<FormModel> {
    constructor(private db: DatabaseCollectionProvider, private http: HttpClient) { }

    getData(): MigrationData<FormModel> {
        return {
            collection: DATABASE_COLLECTIONS.FormCollection,
            data: [
                {
                    key: 'socio-demographic-data',
                    title: 'Soziodemographische Daten',
                    description: 'Fülle das Formular aus und drücke anschließend auf speichern. Die Angabe Deiner Daten ist freiwillig.',
                    done: false,
                    form: [
                        { name: 'age', label: 'Alter', type: 'number', validators: ['required'] },
                        { name: 'education', label: 'Höchster Schulabschluss', type: 'select', options: [ 'Keinen Abschluss', 'Hauptschule', 'Realschule', '(Fach-)Hochschulereife/Abitur' ] },
                        { name: 'appUsage', label: 'Smartphone Nutzung', type: 'multi-select', options: [ 'Spiele', 'Kalender', 'Soziale Medien', 'E-Mail', 'SMS', 'Telefonieren' ] },
                        { name: 'usagePerDay', label: 'Smartphone Gebrauch in Stunden', type: 'number' }
                    ]
                }
            ]
        }
    }
}
