import {ModuleWithProviders, NgModule} from '@angular/core';
import {DeviceNotificationService} from './device-notification.service';
import {IonicModule} from '@ionic/angular';
import {DatabaseModule} from '@core/database';
import {SettingsModule} from '@plugin/settings';
import { LocalNotifications } from '@capacitor/local-notifications';

@NgModule({
    imports: [
        IonicModule,
        DatabaseModule,
        SettingsModule
    ],
    providers: [
        { provide: LocalNotifications, useValue: LocalNotifications },
    ],
    declarations: [],
    exports: []
})
export class DeviceNotificationModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DeviceNotificationModule,
            providers: [
                { provide: LocalNotifications, useValue: LocalNotifications },
                { provide: DeviceNotificationService, useClass: DeviceNotificationService },
            ]
        }
    }
}
