import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {SurveyProviderService, SurveyState} from './survey-provider.service';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SurveyGuard implements CanActivate {
    constructor(private surveyService: SurveyProviderService, private snackBar: MatSnackBar) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>(async resolve => {
            const params = route.children[0].params;
            const survey = await this.surveyService.getCurrentSurvey(params.survey);

            console.log("params.survey", params.survey);
            const state = await this.surveyService.getSurveyState(survey, moment(params.date));
            switch (state) {
                case SurveyState.Available:
                    resolve(true);
                    break;
                case SurveyState.AlreadyDone:
                    this.snackBar.open('Du hast diesen Fragebogen bereits bearbeitet.', null, { duration: 5000 });
                    resolve(false);
                    break;
                case SurveyState.AvailableInFuture:
                    this.snackBar.open('Dieser Fragebogen ist noch nicht verfügbar.', null, { duration: 5000 });
                    resolve(false);
                    break;

                // not needed anymore
                case SurveyState.Expired:
                    this.snackBar.open('Dieser Fragebogen ist abgelaufen und nicht mehr verfügbar.', null, { duration: 5000 });
                    resolve(false);
                    break;
            }
        });
    }
}
