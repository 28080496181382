import {BaseModel} from '@core/models';

export interface DocumentIndex {
    id: string;
    index: string;
    fullIndexedId: string;
}

export type DocumentIndexCreator<T> = (o: T, i?: string) => DocumentIndex;
export const DefaultDocumentIndexCreator: DocumentIndexCreator<BaseModel> = (o, i) => {
    return {
        id: o._id || i,
        fullIndexedId: o._id || i,
        index: '',
    };
};
