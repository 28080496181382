import {ModuleWithProviders, NgModule} from '@angular/core';
import { LifeSectionEditComponent } from './life-section-edit/life-section-edit.component';
import { LifeSectionListComponent } from './life-section-list/life-section-list.component';
import { LifeSectionConflictsComponent } from './life-section-conflicts/life-section-conflicts.component';
import { LifeSectionResolutionComponent } from './life-section-resolution/life-section-resolution.component';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {DatabaseModule} from '@core/database';
import {LifeSectionService} from './life-section.service';
import {FormsModule} from '@angular/forms';
import {ValidationModule} from '@core/validation';

@NgModule({
  declarations: [LifeSectionEditComponent, LifeSectionListComponent, LifeSectionConflictsComponent, LifeSectionResolutionComponent],
  imports: [
      CommonModule,
      IonicModule,
      FormsModule,
      DatabaseModule,
      ValidationModule
  ],
  exports: [LifeSectionEditComponent, LifeSectionListComponent, LifeSectionConflictsComponent, LifeSectionResolutionComponent]
})
export class LifeSectionModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: LifeSectionModule,
            providers: [
                { provide: LifeSectionService, useClass: LifeSectionService },
            ]
        }
    }
}
