import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export const DEFAULT_WELLBEING_RATING: number = 4;

@Component({
    selector: 'plugin-timeframe-wellbeing',
    templateUrl: './timeframe-wellbeing.component.html',
    styleUrls: ['./timeframe-wellbeing.component.scss']
})
export class TimeframeWellbeingComponent implements OnInit {
    @Input() readOnly: boolean;

    ratingValue: number;
    @Input() get rating(): number { return this.ratingValue };
    @Output() ratingChange: EventEmitter<number> = new EventEmitter();
    set rating(value: number) {
        this.ratingValue = value;
        this.ratingChange.emit(value);
    }

    ngOnInit() {
        if (!this.ratingValue)
            this.ratingValue = DEFAULT_WELLBEING_RATING;
    }
}
