import {isFunction} from 'util';

export type UnorderedMapLike<TValue> = Map<string, TValue> | { [index: string]: TValue };

export class UnorderedMap<TValue> extends Map<string, TValue> {
    constructor(entries?: UnorderedMapLike<TValue> | null) {
        if (!entries) {
            super([]);
        } else {
            let isMap = entries['keys'] && isFunction(entries['keys']);
            if (isMap) {
                super([...(entries as Map<string, TValue>).keys()].map(key => <[string, TValue]>[key, entries[key]]));
            } else {
                super(Object.keys(entries).map(key => <[string, TValue]>[key, entries[key]]));
            }
        }
    }

    count(): number {
        return [...this.keys()].length;
    }

    toJSON(): UnorderedMapLike<TValue> {
        const result = { };
        for (let [key, value] of this) {
            result[key] = value;
        }
        return JSON.parse(JSON.stringify(result));
    }
}
