/*
 * Public API Surface of database
 */

export * from './lib/repositories/pouch-db.repository';
export * from './lib/repositories/database.repository';
export * from './lib/repositories/rxdb.repository';
export * from './lib/database.module';
export * from './lib/database.service';
export * from './lib/db/db-context';
export * from './lib/collections/database-collection.provider';
