import { Injectable } from '@angular/core';
import {AlertController, LoadingController, Platform} from '@ionic/angular';
import {AppSettings} from '@plugin/settings';
import {HttpClient} from '@angular/common/http';

export const DEFAULT_UPDATE_CHANNEL = 'master';
export const SNAPSHOT_ARTIFACT_SERVER_URL =  'https://builds.de-rena.de';
export type SnapshotManifest = { href: string, integrity: string }[];

@Injectable({
    providedIn: 'root'
})
export class UpdateService {
    private updateRunning: boolean;
    private enabled: boolean = false;

    constructor(
        private loadingController: LoadingController,
        private alertController: AlertController,
        private platform: Platform,
        private settings: AppSettings,
        private http: HttpClient) { }

    async getChannels(): Promise<string[]> {
        if (!this.enabled) {
            return [];
        }

        //const configuration = await Deploy.getConfig();
        const configuration = { appId: '41cfcd83' };
        return this.http.get<string[]>(`${SNAPSHOT_ARTIFACT_SERVER_URL}/apps/${configuration.appId}/channels`).toPromise()
    }

    async setChannel(channel: string) {
        this.settings.set('update-channel', channel);
        if (this.enabled) {
            //await Deploy.setConfig({ channel: channel });
        }
    }

    async getManifest(snapshotId: string): Promise<SnapshotManifest> {
        if (!this.enabled) {
            return null;
        }
        //const configuration = await Deploy.getConfig();
        //return await this.http.get<SnapshotManifest>(`${SNAPSHOT_ARTIFACT_SERVER_URL}/apps/${configuration.appId}/snapshots/${snapshotId}/manifest`).toPromise()
    }

    async checkForUpdates(launchDialog: boolean = false): Promise<boolean> {
        if (!this.enabled)
            return false;

        if (!this.platform.is('cordova'))
            return false;

        if (!navigator.onLine) {
          return false;
        }

        if (this.updateRunning) {
            const d = await this.alertController.create({
                id: 'update-running',
                message: 'Aktualisierung wird bereits durchgeführt.',
                buttons: [
                    {
                        text: 'Okay',
                        role: 'cancel'
                    }
                ]
            });
            d.present();
            return true;
        }

        try {
            const channel = this.settings.get('update-channel', DEFAULT_UPDATE_CHANNEL);
            console.log('update channel >', channel);
            const config = {
                channel: channel,
                appId: '41cfcd83',
                updateMethod: 'none',
                minBackgroundDuration: 30,
                maxVersions: 1,
                host: "https://builds.de-rena.de"
            }
            /*await Deploy.setConfig(config);
            console.log('after Deploy.configure')
            await Deploy.sync(p => {
                console.log('progress >', p);
            });*/
        } catch (e) {
            console.log('error from checkForUpdates >>>', e);
            return false;
        }
    }

    launchDialog(): Promise<boolean> {
        return new Promise(async resolve => {
            const dialog = await this.alertController.create({
                header: 'Aktualisierung',
                subHeader: 'Eine neue DE-RENA Version ist verfügbar!',
                buttons: [
                    {
                        text: 'Installieren',
                        handler: value => {
                            this.updateRunning = true;
                            this.update();
                            resolve(true);
                        }
                    },
                    {
                        text: 'Abbrechen',
                        role: 'cancel'
                    }
                ]
            });

            dialog.onWillDismiss().then(x => {
                if (x.role === 'cancel')
                    resolve(false);
            });


            dialog.present();
        })
    }

    async update() {
        console.warn('deprecated method update() called');
    }
}
