import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import * as Color from 'color';
import {AppSettings} from '@plugin/settings';
import {ColorHelper} from '@core/models';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document, private settings: AppSettings) {
        settings.awaiter().then(() => {
            this.setGlobalCSS(
                settings.get('theme', CSSTextGenerator(defaults))
            )
        })
    }

    setTheme(theme, name?: string) {
        const cssText = CSSTextGenerator(theme);
        this.setGlobalCSS(cssText);
        this.settings.set('theme', cssText);
        if (name)
            this.settings.set('theme-name', name);
    }

    setVariable(name, value) {
        this.document.documentElement.style.setProperty(name, value);
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }
}

const defaults = {
    header: '#3880ff',
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8'
};

function contrast(color, ratio = 0.8) {
    color = Color(color);
    return color.isDark() ? color.lighten(ratio) : color.darken(ratio);
}

function toRGB(color) {
    let helper = new ColorHelper(color);
    return `${helper.red()},${helper.green()},${helper.blue()}`;
}

function CSSTextGenerator(colors) {
    colors = { ...defaults, ...colors };

    const {
        header,
        primary,
        secondary,
        tertiary,
        success,
        warning,
        danger,
        dark,
        medium,
        light,
        primaryTextColor,
    } = colors;

    const shadeRatio = 0.1;
    const tintRatio = 0.1;

    return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};
    --ion-background-color: ${light};
    --ion-text-color: ${dark};
    --ion-text-color-rgb: ${toRGB(dark)};
    
    --ion-color-header: ${header};
    --ion-color-header-rgb: ${toRGB(header)};
    --ion-color-header-contrast: ${contrast(header)};
    --ion-color-header-contrast-rgb: ${toRGB(contrast(header))};
    --ion-color-header-shade:  ${Color(header).darken(shadeRatio)};
    --ion-color-header-tint:  ${Color(header).lighten(tintRatio)};
    
    --ion-toolbar-background-color: ${contrast(primary, 0.1)};
    --ion-toolbar-text-color: ${contrast(primary, 0.4)};
    --ion-item-background-color: ${contrast(light, 0.3)};
    --ion-item-text-color: ${contrast(dark, 0.3)};
    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: ${toRGB(primary)};
    --ion-color-primary-contrast: ${contrast(primary)};
    --ion-color-primary-contrast-rgb: ${toRGB(contrast(primary))};
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};
    --ion-color-primary-tint:  ${Color(primary).lighten(tintRatio)};
    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${toRGB(secondary)};
    --ion-color-secondary-contrast: ${contrast(secondary)};
    --ion-color-secondary-contrast-rgb: ${toRGB(contrast(secondary))};
    --ion-color-secondary-shade:  ${Color(secondary).darken(shadeRatio)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};
    --ion-color-tertiary:  ${tertiary};
    --ion-color-tertiary-rgb: ${toRGB(tertiary)};
    --ion-color-tertiary-contrast: ${contrast(tertiary)};
    --ion-color-tertiary-contrast-rgb: ${toRGB(contrast(tertiary))};
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
    --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio)};
    --ion-color-success: ${success};
    --ion-color-success-rgb: ${toRGB(success)};
    --ion-color-success-contrast: ${contrast(success)};
    --ion-color-success-contrast-rgb: ${toRGB(contrast(success))};
    --ion-color-success-shade: ${Color(success).darken(shadeRatio)};
    --ion-color-success-tint: ${Color(success).lighten(tintRatio)};
    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: ${toRGB(warning)};
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: ${toRGB(contrast(warning))};
    --ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
    --ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};
    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: ${toRGB(danger)};
    --ion-color-danger-contrast: ${contrast(danger)};
    --ion-color-danger-contrast-rgb: ${toRGB(contrast(danger))};
    --ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
    --ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};
    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: ${toRGB(dark)};
    --ion-color-dark-contrast: ${contrast(dark)};
    --ion-color-dark-contrast-rgb: ${toRGB(contrast(dark))};
    --ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
    --ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};
    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: ${toRGB(medium)};
    --ion-color-medium-contrast: ${contrast(medium)};
    --ion-color-medium-contrast-rgb: ${toRGB(contrast(medium))};
    --ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
    --ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};
    --ion-color-light: ${light};
    --ion-color-light-rgb: ${toRGB(light)};
    --ion-color-light-contrast: $${contrast(light)};
    --ion-color-light-contrast-rgb: ${toRGB(contrast(light))};
    --ion-color-light-shade: ${Color(light).darken(shadeRatio)};
    --ion-color-light-tint: ${Color(light).lighten(tintRatio)};
`;
}
