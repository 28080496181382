/*
 * Public API Surface of plugin-life-section
 */

export * from './lib/life-section.module';
export * from './lib/life-section.service';
export * from './lib/life-section.validation';
export * from './lib/life-section-edit/life-section-edit.component';
export * from './lib/life-section-list/life-section-list.component';
export * from './lib/assets/life-section-color';
