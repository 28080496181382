// @ts-ignore
import PouchDB from 'pouchdb';
// @ts-ignore
import PouchDBFind from 'pouchdb-find';
PouchDB.plugin(PouchDBFind);

import {DbContext} from 'lib/core-database/src/lib/db/db-context';
import {PouchDbContext} from 'lib/core-database/src/lib/db/pouchdb/pouchdb-context';
import {BaseModel, DefaultDocumentIndexCreator, DocumentIndexCreator} from '@core/models';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DatabaseRepository, DocumentChange} from '@library/core-database/src/lib/repositories/database.repository';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PouchDbRepository extends DatabaseRepository {
    private readonly internalDb: PouchDB.Database;
    private readonly applicationDb: PouchDB.Database;
    private readonly changeStream: Subject<any> = new Subject();
    settings: any = null;

    constructor() {
        super();
        this.internalDb = new PouchDB('internal-db');
        this.applicationDb = new PouchDB('app-db');

        this.applicationDb.changes({
            live: true,
            since: 'now',
            include_docs: true
        }).on('change', value => this.handleChange(value));

        this.applicationDb.getIndexes((err, result) => {
            if (err || result.indexes.length === 1) {
                this.applicationDb.createIndex({
                    index: { fields: ['$$namespace$$'] }
                });
            }
        });
    }

    async initSync(): Promise<DatabaseRepository> {
        if (this.settings !== null)
            return this;

        try {
            this.settings = await this.internalDb.get('settings');
        } catch (e) {
            this.settings = null;
        }

        return this;
    }

    async installSync(replicationUri: string): Promise<void> {
        await this.internalDb.put({
            _id: 'settings',
            replicationUri: replicationUri
        });

        await this.initSync();
    }

    async isSyncInstalled(): Promise<boolean> {
        let repo: any = await this.initSync();
        return repo.settings !== null;
    }

    use<Model extends BaseModel>(model: string, indexCreator?: DocumentIndexCreator<Model>): DbContext<Model> {
        if (!indexCreator)
            indexCreator = DefaultDocumentIndexCreator;
        return new PouchDbContext<Model>(this.applicationDb, model, indexCreator);
    }

    handleChange(change: PouchDB.Core.ChangesResponseChange<any>) {
        const idCounterPart = change.doc._id.split('/').pop();
        if (idCounterPart) change.doc._id = idCounterPart;
        this.changeStream.next({
            deleted: change.deleted,
            fullIndexedId: change.id,
            id: change.id.split('/').pop(),
            document: !change.deleted ? change.doc : null
        });
    }

    changes<T>(modelName?: string): Observable<DocumentChange<T>> {
        return this.changeStream.asObservable().pipe(
            filter((value: DocumentChange<any>, index) => {
                if (!modelName)
                    return true;

                if (value.fullIndexedId.startsWith(`App/Model/${modelName}`))
                    return true;

                return false;
            }),
            map(item => {
                if (!item.document)
                    return item;

                item.document['_fullIndexedId'] = item.fullIndexedId;
                return item;
            })
        );
    }

    getDriver(): PouchDB.Database {
        return this.applicationDb;
    }
}
