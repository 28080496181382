import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'plugin-dialog-discard-changes',
    template: `
        <h2 mat-dialog-title>Änderungen verwerfen</h2>
        <mat-dialog-content>Bist Du sicher?</mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button mat-dialog-close>Abbrechen</button>
            <a mat-button color="warn" href="javascript:history.back()">Verwerfen</a>
        </mat-dialog-actions>
    `
})
export class DialogDiscardChangesComponent implements OnInit {
    constructor() { }

    ngOnInit() {}
}
