import {RxJsonSchema} from 'rxdb';

export const TimeframeSchema: RxJsonSchema | any = {
    "version": 1,
    "type": "object",
    "title": "timeframes",
    "properties": {
        "id": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "lifeSectionId": {
            "type": "string"
        },
        "eventId": {
            "type": "string"
        },
        "start": {
            "type": "string"
        },
        "end": {
            "type": "string"
        },
        "resolution": {
            "type": ["object", "null"]
        },
        "conflict": {
            "type": ["object", "null"]
        },
        "tasks": {
            "type": ["array", "null"],
            "properties": {
                "type": "object"
            }
        },
        "done": {
            "type": "boolean"
        },
        "canceled": {
            "type": "boolean"
        },
        "cancelReason": {
            "type": ["string", "null"]
        },
    }
};

