import {Injectable} from '@angular/core';
import {
    MaxLengthValidator,
    MinLengthValidator,
    ValidationHolder,
    ValidationService,
    Validator,
    ValueRequiredValidator
} from '@core/validation';
import {LifeSectionModel} from '@core/models';

@Injectable({ providedIn: 'root' })
export class LifeSectionValidation {
    private readonly holder: ValidationHolder<LifeSectionModel>;

    constructor(private validationService: ValidationService) {
        this.holder = validationService.createHolder<LifeSectionModel>();
        this.setupValidators();
    }

    private setupValidators() {
        this.holder.add('"Bezeichnung" darf nicht leer sein und muss aus mindestens drei Zeichen bestehen!', [new ValueRequiredValidator(), new MinLengthValidator(3)], x => x.name);
        this.holder.add('"Bezeichnung" darf nicht mehr als 24 Zeichen lang sein!', new MaxLengthValidator(24), x => x.name);
        this.holder.add('Bitte wähle eine Farbe aus!', new ValueRequiredValidator(), x => x.color);
    }

    validateSingle<T>(value: T, validator: Validator<T>, error: string): boolean {
        return this.validationService.createHolder<T>().add(error, validator, x => x).validate(value);
    }

    validate(lifeSection: LifeSectionModel): boolean {
        return this.holder.validate(lifeSection);
    }
}
