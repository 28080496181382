import {SurveyEventProvider} from '../survey-event.provider';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {SurveyModel, SurveyResultModel} from '@core/models';
import {DatabaseCollectionProvider} from '@core/database';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import * as moment from 'moment';

export class DynamicSurveyEventProvider implements SurveyEventProvider {
    private readonly frequencyMap = {
        'WEEKLY': 'week',
        'DAILY': 'day',
        'HOURLY': 'hour',
        'MINUTLY': 'minute'
    };

    constructor(private db: DatabaseCollectionProvider) { }

    async getEvents(survey: SurveyModel): Promise<EventDefinition[]> {
        let nextDate = moment();
        let results = (await this.db.use<SurveyResultModel>(DATABASE_COLLECTIONS.SurveyResultCollection).find().exec()).map(x => x.toJSON());

        if (results.length > 0) {
            const last = results[results.length - 1];
            const lastSubmitDate = moment(last.submitDate).startOf('day');
            const diff = nextDate.diff(lastSubmitDate, this.frequencyMap[survey.frequency], true);

            if (diff < survey.interval) {
                nextDate = moment(lastSubmitDate).add(survey.interval, this.frequencyMap[survey.frequency]);
            }
        }

        return [{
            id: nextDate.format('YYYYMMDD'),
            title: survey.title,
            start: new Date(nextDate.toISOString()),
            allDay: true,
            color: {
                primary: "#FF5733",
                secondary: "#ffe5ae"
            },
            meta: {
                feature: 'internal-route',
                options: {
                    route: '/survey',
                    params: [survey.key, nextDate.toISOString()],
                    dialog: {
                        label: `Zu ${survey.title}`,
                        icon: 'log-out',
                        showCancel: true
                    }
                }
            }
        }]
    }
}
