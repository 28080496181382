/* tslint:disable */
import {
  AccessToken,
  Patient,
  PatientToken
} from '../index';

declare var Object: any;
export interface CoachInterface {
  "firstname"?: string;
  "lastname"?: string;
  "email": string;
  "active"?: boolean;
  "endpoints"?: Array<any>;
  "realm"?: string;
  "username"?: string;
  "emailVerified"?: boolean;
  "id"?: any;
  "password"?: string;
  accessTokens?: AccessToken[];
  patients?: Patient[];
  patientTokens?: PatientToken[];
}

export class Coach implements CoachInterface {
  "firstname": string;
  "lastname": string;
  "email": string;
  "active": boolean;
  "endpoints": Array<any>;
  "realm": string;
  "username": string;
  "emailVerified": boolean;
  "id": any;
  "password": string;
  accessTokens: AccessToken[];
  patients: Patient[];
  patientTokens: PatientToken[];
  constructor(data?: CoachInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Coach`.
   */
  public static getModelName() {
    return "Coach";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Coach for dynamic purposes.
  **/
  public static factory(data: CoachInterface): Coach{
    return new Coach(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Coach',
      plural: 'Coaches',
      path: 'Coaches',
      idName: 'id',
      properties: {
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "endpoints": {
          name: 'endpoints',
          type: 'Array&lt;any&gt;'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'AccessToken[]',
          model: 'AccessToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        patients: {
          name: 'patients',
          type: 'Patient[]',
          model: 'Patient',
          relationType: 'hasMany',
          modelThrough: 'CoachPatient',
          keyThrough: 'patientId',
          keyFrom: 'id',
          keyTo: 'coachId'
        },
        patientTokens: {
          name: 'patientTokens',
          type: 'PatientToken[]',
          model: 'PatientToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'coachId'
        },
      }
    }
  }
}
