import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'varianceDuration'
})
export class VarianceDurationPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value === 0)
            return 'unverändert';

        let signInterpretation = (value < 0) ? 'kürzer' : 'länger';
        let absoluteValue = Math.abs(value);

        if (absoluteValue < 120)
            return `${absoluteValue} Minuten ${signInterpretation} ${signInterpretation} als geplant`;

        let hours = Math.floor(absoluteValue / 60);
        let minutes = absoluteValue % 60;

        if (minutes) return `${hours} Std. ${minutes} Min. ${signInterpretation} als geplant`;
        else return `${hours} Stunden ${signInterpretation} als geplant`;
    }
}
