import {ModuleWithProviders, NgModule} from '@angular/core';
import {DatabaseModule} from '@core/database';
import {TimeframeService} from '@library/plugin-timeframe/src/lib/timeframe.service';
import {TimeframeConflictComponent} from './timeframe-conflict/timeframe-conflict.component';
import {TimeframeResolutionComponent} from './timeframe-resolution/timeframe-resolution.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {StatisticsModule} from '@plugin/statistics';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DatabaseModule,
        StatisticsModule,
    ],
    declarations: [
        TimeframeConflictComponent,
        TimeframeResolutionComponent
    ],
    providers: [],
    exports: [
        TimeframeConflictComponent,
        TimeframeResolutionComponent
    ],
})
export class TimeframeModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: TimeframeModule,
            providers: [
                { provide: TimeframeService, useClass: TimeframeService },
            ]
        }
    }
}
