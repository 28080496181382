import {ModuleWithProviders, NgModule} from '@angular/core';
import {DayStatisticService} from '@library/plugin-statistics/src/lib/services/day-statistic.service';
import {DatabaseModule} from '@core/database';

@NgModule({
    imports: [
        DatabaseModule
    ],
    declarations: [],
    exports: []
})
export class StatisticsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: StatisticsModule,
            providers: [
                { provide: DayStatisticService, useClass: DayStatisticService },
            ]
        }
    }
}
