import {BaseModel} from '@core/models';
import Color from 'color';

export interface ColorModel extends BaseModel {
    name?: string;
    code: string;
}

export const Colors = {
    Blue: { name: 'Blau', code: '0664c0' },
    Green: { name: 'Grün', code: '0f882a' },
    Yellow: { name: 'Gelb', code: 'dcbd24' },
    Orange: { name: 'Orange', code: 'de6a11' },
    Red: { name: 'Rot', code: 'c82406' },
    Purple: { name: 'Violett', code: '9619c8' },
    DarkGreen: { name: 'Dunkelgrün', code: '217c1b' }
};

export class ColorHelper {
    private code: string;
    private color: ColorModel;

    constructor(color: ColorModel | string | any) {
        if (color['rgb']) {
            this.color = { code: color.rgb().toString(), name: null };
        } else if (typeof(color) === 'string') {
            this.color = { code: color, name: null };
        } else {
            this.color = color;
            if(this.color.code.charAt(0) !== '#' ){
                this.color.code = `#${this.color.code}`;
            }
        }
        this.reset();
    }

    lighten(pct: number): ColorHelper {
        this.code = Color(this.code).lighten(pct).rgb().toString();
        return this;
    }

    darken(pct: number): ColorHelper {
        this.code = Color(this.code).darken(pct).rgb().toString();
        return this;
    }

    red() {
        return Color(this.code).rgb().red();
    }

    green() {
        return Color(this.code).rgb().green();
    }

    blue() {
        return Color(this.code).rgb().blue();
    }

    reset() {
        this.code = this.color.code;
    }

    toString(): string {
        return this.code;
    }

    static create(colorName: string): ColorHelper {
        if (!Colors[colorName])
            throw new Error(`color ${colorName} does not exist!`);
        return new ColorHelper(Colors[colorName]);
    }
}
