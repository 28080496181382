import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationService } from '../services/localization.service';

@Pipe({
    name: 'localize'
})
export class LocalizePipe implements PipeTransform, OnDestroy {
    private readonly asyncPipe: AsyncPipe;
    private readonly localizationService: LocalizationService;
    private readonly transform$: ReplaySubject<{ key: string; parameters: { [key: string]: string } }>;
    private readonly localized$: Observable<string>;

    constructor(changeDetectorRef: ChangeDetectorRef, localizationService: LocalizationService) {
        this.asyncPipe = new AsyncPipe(changeDetectorRef);
        this.localizationService = localizationService;

        this.transform$ = new ReplaySubject(1);

        this.localized$ = combineLatest([this.transform$, this.localizationService.activeLanguage$]).pipe(
            map(([{ key, parameters }]) => this.localizationService.getLocalization(key, parameters))
        );
    }

    ngOnDestroy(): void {
        this.asyncPipe.ngOnDestroy();
        this.transform$.unsubscribe();
    }

    transform(key: string, parameters?: { [key: string]: string }): any {
        this.transform$.next({ key, parameters });
        return this.asyncPipe.transform(this.localized$);
    }
}

