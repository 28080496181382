import {Type} from '@angular/core';
import {LifeSectionsDefault} from './life-sections.default';
import {SurveysDefault} from './surveys.default';
import {DefaultMigration} from './default-migration';
import {FormDefault} from './form.default';

export const DefaultData: Type<DefaultMigration>[] = [
    LifeSectionsDefault,
    SurveysDefault,
    FormDefault
];
