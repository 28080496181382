import {Validator} from '@library/core-validation/src/lib/validators/validator';
import {Moment} from 'moment';

export type CalendarTimeSpan = { start: Moment, end: Moment };

export class TimeSpanValidator implements Validator<CalendarTimeSpan> {
    validate(value: CalendarTimeSpan): boolean {
        if (!value.start)
            return false;

        if (!value.end)
            return true;

        return value.end.isSameOrAfter(value.start, 'minute');
    }
}
