import { Injectable } from '@angular/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AppSettings } from '@plugin/settings';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LiveUpdateService {
    constructor(private http: HttpClient, private settings: AppSettings, private alertController: AlertController) {}

    async checkForUpdates() {
        const { version } = environment;
        const updateChannel = this.settings.get('update-channel', 'master');
        const manifest = await this.http.get<Manifest>(`https://static.de-rena.de/apps/derena/manifest.json`).toPromise();

        const { bundle, channel } = manifest.channels.find(x => x.channel === updateChannel);
        if (!channel) {
            console.error(`Channel ${updateChannel} not found in manifest`);
            return;
        }

        if (version !== bundle.version) {
            console.log(`New version available: ${bundle.version}`);
            await LiveUpdate.downloadBundle({
                url: `https://static.de-rena.de/apps/derena/channels/${channel}/${bundle.filename}`,
                bundleId: bundle.version,
            }).catch(console.error);

            await this.applyUpdateDialog(bundle.version);
        }
    }

    async applyUpdateDialog(version: string) {
        const alert = await this.alertController.create({
            id: 'update-available',
            header: 'Neues Update verfügbar',
            message: `Es ist ein neues Update verfügbar. Möchten Sie es jetzt installieren?`,
            buttons: [
                {
                    text: 'Nein',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Ja',
                    handler: async () => {
                        await LiveUpdate.setBundle({ bundleId: version });
                        await LiveUpdate.reload();
                    }
                }
            ]
        });

        await alert.present();
    }

    async disableRollback() {
        await LiveUpdate.ready();
    }
}

export type Manifest = {
    channels: {
        channel: string;
        bundle: {
            version: string;
            filename: string;
        }
    }[];
}
