import {RxJsonSchema} from 'rxdb';

export const FormsSchema: RxJsonSchema | any = {
    "version": 0,
    "type": "object",
    "title": "App Forms",
    "properties": {
        "key": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "title": {
            "type": "string"
        },
        "description": {
            "type": "string"
        },
        "done": {
            "type": "boolean"
        },
        "form": {
            "type": "array",
            "items": {
                "type": "object"
            }
        }
    }
};
