import {RxJsonSchema} from 'rxdb';

export const SettingsSchema: RxJsonSchema | any = {
    "version": 0,
    "type": "object",
    "title": "App Settings",
    "properties": {
        "key": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "value": {
            "type": ["string", "number", "integer", "null", "boolean", "object", "array"]
        }
    }
};
