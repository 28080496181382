import {ModuleWithProviders, NgModule} from '@angular/core';
import {AppSettings} from '@library/plugin-settings/src/lib/app-settings';
import {DatabaseModule} from '@core/database';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FeatureToggleDirective} from '@library/plugin-settings/src/lib/feature-toggle.directive';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DatabaseModule,
        HttpClientModule
    ],
    declarations: [
        FeatureToggleDirective,
    ],
    exports: [
        FeatureToggleDirective,
    ]
})
export class SettingsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SettingsModule,
            providers: [
                { provide: AppSettings, useClass: AppSettings },
            ]
        }
    }
}
