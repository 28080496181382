<ng-container *ngIf="!!conflict">
    <ion-item lines="none">
        <ion-label>
            <h5><b>{{conflict.text}}</b></h5>
        </ion-label>
        <ion-icon slot="end" name="create" (click)="edit()"></ion-icon>
        <ion-icon slot="end" name="trash" (click)="delete()"></ion-icon>
    </ion-item>
    <ion-text padding-horizontal><i>{{conflict.solution}}</i></ion-text>
</ng-container>

<ng-container *ngIf="!conflict">
    <ion-item lines="none">
        <ion-label>Bitte auswählen</ion-label>
        <ion-icon slot="end" name="add-circle-outline" (click)="add()"></ion-icon>
    </ion-item>
</ng-container>
