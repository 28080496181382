import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VarianceDurationPipe} from '@library/plugin-timeframe-evaluation/src/lib/variance-duration.pipe';

export const DEFAULT_VARIANCE_VALUE: number = 0;

@Component({
    selector: 'plugin-actual-duration',
    templateUrl: './actual-duration.component.html',
    styleUrls: ['./actual-duration.component.scss'],
    providers: [VarianceDurationPipe]
})
export class ActualDurationComponent implements OnInit {
    @Input() readOnly: boolean;
    @Input() plannedDuration: number;

    varianceValue: number;
    @Input() get variance(): number { return this.varianceValue };
    @Output() varianceChange: EventEmitter<number> = new EventEmitter();
    set variance(value: number) {
        this.varianceValue = value;
        this.varianceChange.emit(value);
    }

    get min(): number {
        return -this.plannedDuration;
    }

    get max(): number {
        return this.plannedDuration;
    }

    get step(): number {
        if (this.plannedDuration >= 240) {
            return 30;
        }

        if (this.plannedDuration >= 120) {
            return 15;
        }

        return 5;
    }

    ngOnInit() { }
}
