<ion-header>
    <ion-toolbar color="header">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">Farbauswahl</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
      <ion-card-content>
          <div class="color-row">
              <div *ngFor="let color of colors"
                   class="color-cell" (click)="selectColor(color)"
                   [ngStyle]="{'background-color': toRGBA(color.code, color.inUse ? 0.35 : 1),
                               'border': '2px solid ' + toRGBA(color.code, color.inUse ? 1 : 0)}">

                  <ion-icon *ngIf="color.inUse" class="lock-icon" icon="lock" [ngStyle]="{'color': toRGBA(color.code, 1)}"></ion-icon>
              </div>
          </div>
      </ion-card-content>
  </ion-card>
</ion-content>
