import {ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ResolutionModel} from '@library/core-models/src/resolution.model';

@Component({
    selector: 'plugin-timeframe-resolution',
    templateUrl: './timeframe-resolution.component.html',
    styleUrls: ['./timeframe-resolution.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class TimeframeResolutionComponent implements OnInit {
    @Input() resolution: ResolutionModel;
    @Output() removeResolution = new EventEmitter();
    @Output() editResolution = new EventEmitter();
    @Output() addNew = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    delete(){
        this.removeResolution.emit();
    }

    edit(){
        this.editResolution.emit();
    }

    add(){
        this.addNew.emit();
    }
}
