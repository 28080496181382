import { NgModule } from '@angular/core';
import { PluginRatingBarComponent } from './plugin-rating-bar.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PluginRatingBarComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule
  ],
  exports: [PluginRatingBarComponent]
})
export class PluginRatingBarModule { }
