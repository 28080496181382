import {ChangeDetectionStrategy, Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {ConflictsModel} from '@library/core-models/src/conflicts.model';

@Component({
    selector: 'plugin-timeframe-conflict',
    templateUrl: './timeframe-conflict.component.html',
    styleUrls: ['./timeframe-conflict.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class TimeframeConflictComponent implements OnInit {
    @Input() conflict: ConflictsModel;
    @Output() removeConflict = new EventEmitter();
    @Output() editConflict = new EventEmitter();
    @Output() addNew = new EventEmitter();

    constructor() { }

    ngOnInit() {}

    delete(){
        this.removeConflict.emit();
    }

    edit(){
        this.editConflict.emit();
    }

    add(){
        this.addNew.emit();
    }
}
