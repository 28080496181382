<ion-list #itemList>
    <ion-item-sliding *ngFor="let item of items">
        <ion-item (click)="edit.emit(item)">
            <ion-avatar slot="start">
                <svg width='50' height='50'>
                    <rect height="46" width="46" attr.fill="{{item.color.code}}" stroke-width="2" attr.stroke="{{item.color.code}}"></rect>
                </svg>
            </ion-avatar>
            <ion-label>
                <h2>{{item.name}}</h2>
            </ion-label>
        </ion-item>
        <ion-item-options side="end" >
            <ion-item-option color="danger" (click)="delete.emit(item); cleanupUI(itemList)">
                <ion-icon slot="icon-only" name="trash"></ion-icon>
            </ion-item-option>
            <ion-item-option color="primary" (click)="edit.emit(item); cleanupUI(itemList)">
                <ion-icon slot="icon-only" name="create"></ion-icon>
            </ion-item-option>
        </ion-item-options>
    </ion-item-sliding>
</ion-list>
