import { Component, OnInit } from '@angular/core';
import {CloudConnectService} from './cloud-connect.service';
import {CloudCredentialsModel} from './models/cloud-credentials.model';

@Component({
    selector: 'plugin-cloud-connect',
    template: `
    <div>
        <button (click)="connect()">Verbinden</button>
        <pre>{{ credentials | json }}</pre>
    </div>
  `,
    styles: []
})
export class CloudConnectComponent implements OnInit {
    credentials: CloudCredentialsModel;

    constructor(private connectionService: CloudConnectService) { }

    connect() {
    }

    ngOnInit() {}
}
