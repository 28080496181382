export const SideMenu: Array<any> = [
    {
        title: 'Stammdaten',
        items: [
            {
                title: 'Lebensbereiche',
                url: '/life-sections',
                icon: 'apps'
            },
            {
                title: 'Musterwoche',
                url: '/event-templates',
                icon: 'clipboard'
            }
        ],
    },
    {
        title: 'Organisation',
        items: [
            {
                title: 'Kalender',
                url: '/calendar/day',
                icon: 'calendar'
            },
            {
                title: 'Mein Tag',
                url: '/',
                icon: 'home',
                root: true
            },
            {
                title: 'Tagesbewertung',
                url: '/evaluate-day',
                icon: 'star'
            },
            {
                title: 'Übungen',
                url: '/exercise',
                icon: 'walk'
            }
        ]
    },
    {
        title: 'Auswertung',
        items: [
            {
                title: 'Lebensbereichverteilung',
                url: '/statistics/timeframe',
                icon: 'pie-chart-outline'
            },
            {
                title: 'Befinden',
                url: '/statistics/wellbeing',
                icon: 'analytics'
            },
            {
                title: 'Depressivität',
                url: '/statistics/depression',
                icon: 'trending-up',
                feature: 'depression-chart'
            },
            {
                title: 'Balance',
                url: '/statistics/week-balance',
                icon: 'stats-chart-outline'
            },
        ]
    },
    {
        title: 'Hilfe und Weiteres',
        items: [
            {
                title: 'Erklärvideos',
                url:'/help-videos',
                icon: 'film'
            },
            {
                title: 'Abschlussfragebogen',
                url:'/final-survey',
                icon: 'book-outline'
            }
        ]
    }
];
