import { Injectable } from "@angular/core";
import { DeviceNotificationService } from "@library/plugin-device-notification/src/public_api";
import { AppSettings } from "@library/plugin-settings/src/public_api";
import moment, { Moment } from "moment";

type MobileAppointment = {
    id: number;
    hint: string;
    author: string;
    created: string;
    appointmentDatetime: string
    type: string;
    duration: number;
    deleted?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AppointmentsService {
    constructor(private appSettings: AppSettings, private notificationService: DeviceNotificationService) {}

    handle(appointments: MobileAppointment[]) {
        if (!appointments) {
            return;
        }
        let change = false;
        let delivered = this.appSettings.get('delivered-appointments', []);
        let appointmentNotifications: any[] = this.appSettings.get('appointment-notifications', []);

        for (let appointment of appointments) {
            if (appointment.deleted) {
                change = true;
                const index = appointmentNotifications.findIndex(x => x.id === appointment.id);
                if (index !== -1) {
                    this.removeNotifications(appointmentNotifications[index].notificationIds);
                    appointmentNotifications[index].notificationIds = [];
                } else {
                    appointmentNotifications.push({ id: appointment.id, notificationIds: [] });
                }
                if (delivered.indexOf(appointment.id) === -1) {
                    delivered.push(appointment.id);
                }
            }

            if (delivered.indexOf(appointment.id) === -1) {
                change = true;
                const notificationIds = this.createAppointmentPushNotifications(appointment);
                delivered.push(appointment.id);
                appointmentNotifications.push({ id: appointment.id, notificationIds: notificationIds });
            }
        }
        if (change) {
            this.appSettings.set('delivered-appointments', delivered);
            this.appSettings.set('appointment-notifications', appointmentNotifications);
        }
    }

    private createAppointmentPushNotifications(appointment: MobileAppointment): number[] {
        if (this.notificationService.supported && this.notificationService.initialized) {
            let createdNotifications = [];
            const message = this.composeNotificationMessage(appointment);
            const title = this.composeNotificationTitle(appointment);

            const first = this.notificationService.schedule(title, message, true, moment(appointment.appointmentDatetime));
            first ? createdNotifications.push(first) : null;

            const second = this.notificationService.schedule(title, message, true, moment(appointment.appointmentDatetime).subtract(60, 'minutes'));
            second ? createdNotifications.push(second) : null;

            let shouldSendNotificationNow = moment(appointment.appointmentDatetime).subtract(1, 'day').hours(12).minutes(0).diff(moment(), 'minutes') < 0;
            if (shouldSendNotificationNow) {
                const third = this.notificationService.schedule(title, message, true, moment().add(1, 'minute'));
                third ? createdNotifications.push(third) : null;
            } else {
                const third = this.notificationService.schedule(title, message, true, moment(appointment.appointmentDatetime).subtract(1, 'day').hours(12).minutes(0));
                third ? createdNotifications.push(third) : null;
            }

            return createdNotifications;
        }
    }

    private composeNotificationMessage(appointment: MobileAppointment) {
        let message = `Termin am ${moment(appointment.appointmentDatetime).format('DD.MM.YYYY')} um ${moment(appointment.appointmentDatetime).format('HH:mm')}`;
        if (!!appointment.hint) {
            message = message + `\nHinweis: ${appointment.hint}`;
        }
        if (appointment.duration > 0) {
            message = message + `\nDauer: ${appointment.duration} Minuten`;
        }

        return message;
    }

    private composeNotificationTitle(appointment: MobileAppointment) {
        if (appointment.type === 'Telefon') {
            return 'Tel. Coaching mit Deinem DE-RENA Coach';
        } else if (appointment.type === 'Klinik') {
            return 'Persönl. Coaching mit Deinem DE-RENA Coach';
        }

        return 'Termin mit Deinem DE-RENA Coach';
    }

    private removeNotifications(notificationIds: number[]) {
        if (notificationIds && notificationIds.length) {
            if (this.notificationService.supported && this.notificationService.initialized) {
                for (let id of notificationIds) {
                    this.notificationService.remove(id);
                }
            }
        }
    }
}
