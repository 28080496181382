export const LifeSectionColors = [
    { code: '#DC143C', inUse: false },
    { code: '#FF0000', inUse: false },
    { code: '#8B0000', inUse: false },
    { code: '#FF7F50', inUse: false },
    { code: '#FF6347', inUse: false },
    { code: '#FF4500', inUse: false },
    { code: '#FFD700', inUse: false },
    { code: '#FFA500', inUse: false },
    { code: '#FF8C00', inUse: false },
    { code: '#32CD32', inUse: false },
    { code: '#00FF00', inUse: false },
    { code: '#228B22', inUse: false },
    { code: '#006400', inUse: false },
    { code: '#ADFF2F', inUse: false },
    { code: '#00FF7F', inUse: false },
    { code: '#00FA9A', inUse: false },
    { code: '#3CB371', inUse: false },
    { code: '#2E8B57', inUse: false },
    { code: '#00FFFF', inUse: false },
    { code: '#66CDAA', inUse: false },
    { code: '#40E0D0', inUse: false },
    { code: '#48D1CC', inUse: false },
    { code: '#00CED1', inUse: false },
    { code: '#20B2AA', inUse: false },
    { code: '#5F9EA0', inUse: false },
    { code: '#008080', inUse: false },
    { code: '#00BFFF', inUse: false },
    { code: '#1E90FF', inUse: false },
    { code: '#6495ED', inUse: false },
    { code: '#4682B4', inUse: false },
    { code: '#4169E1', inUse: false },
    { code: '#0000FF', inUse: false },
    { code: '#0000CD', inUse: false },
    { code: '#00008B', inUse: false },
    { code: '#000080', inUse: false },
    { code: '#191970', inUse: false },
    { code: '#7B68EE', inUse: false },
    { code: '#483D8B', inUse: false },
    { code: '#FF00FF', inUse: false },
    { code: '#BA55D3', inUse: false },
    { code: '#9370DB', inUse: false },
    { code: '#8A2BE2', inUse: false },
    { code: '#9932CC', inUse: false },
    { code: '#8B008B', inUse: false },
    { code: '#800080', inUse: false },
    { code: '#4B0082', inUse: false },
    { code: '#FF1493', inUse: false },
];
