import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export const DEFAULT_SOLUTION_RATING: number = 4;

@Component({
  selector: 'plugin-conflict-solution-rating',
  templateUrl: './conflict-solution-rating.component.html',
  styleUrls: ['./conflict-solution-rating.component.scss']
})
export class ConflictSolutionRatingComponent implements OnInit {
    @Input() readOnly: boolean;
    @Input() text: string;

    ratingValue: number;
    @Input() get rating(): number { return this.ratingValue };
    @Output() ratingChange: EventEmitter<number> = new EventEmitter();
    set rating(value: number) {
        this.ratingValue = value;
        this.ratingChange.emit(value);
    }

    ngOnInit() {
        if (!this.ratingValue)
            this.ratingValue = DEFAULT_SOLUTION_RATING;
    }
}
