/* tslint:disable */
import { Injectable } from '@angular/core';
import { AccessToken } from '../../models/AccessToken';
import { Coach } from '../../models/Coach';
import { Email } from '../../models/Email';
import { Patient } from '../../models/Patient';
import { PatientToken } from '../../models/PatientToken';
import { Survey } from '../../models/Survey';
import { ReplicationEndpoint } from '../../models/ReplicationEndpoint';
import { Device } from '../../models/Device';
import { PatientPuk } from '../../models/PatientPuk';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    AccessToken: AccessToken,
    Coach: Coach,
    Email: Email,
    Patient: Patient,
    PatientToken: PatientToken,
    Survey: Survey,
    ReplicationEndpoint: ReplicationEndpoint,
    Device: Device,
    PatientPuk: PatientPuk,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
