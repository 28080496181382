import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AppSettings} from '@library/plugin-settings/src/lib/app-settings';

@Directive({
    selector: '[featureToggle]'
})
export class FeatureToggleDirective implements OnInit, OnDestroy {
    @Input('featureToggle') featureToggle: string = 'preview';

    private subscription: Subscription;
    private readonly initialDisplayValue: string;

    constructor(private elementRef: ElementRef, private settings: AppSettings) {
        this.initialDisplayValue = (this.elementRef.nativeElement as HTMLElement).style.display;
    }

    get featureName(): string {
        return `feature-${this.featureToggle}`;
    }

    setVisible(visible: boolean) {
        this.elementRef.nativeElement.style.display = visible ? this.initialDisplayValue : 'none';
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

    ngOnInit() {
        if (this.featureToggle === undefined) {
            this.setVisible(true);
            return;
        }

        this.subscription = this.settings
            .changes(this.featureName)
            .subscribe(
                x => this.setVisible(x.value)
            );

        this.setVisible(
            this.settings.get(this.featureName, false)
        );
    }
}
