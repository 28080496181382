import {DayStatsModel, EventTemplateModel, LifeSectionStatsModel, TimeframeEvaluationModel} from '@core/models';
import {LifeSectionStats} from '@library/plugin-statistics/src/lib/models/life-section-stats';
import {UnorderedMap, UnorderedMapLike} from '@core/shared';
import {TimeframeModel} from '@library/core-models/src/timeframe.model';

export class DayStats implements DayStatsModel {
    _id: any;
    _rev: any;

    id: string;
    lifeSectionStats: UnorderedMap<LifeSectionStats>;
    eventTemplates: EventTemplateModel[];
    timeframeEvaluations: TimeframeEvaluationModel[];
    timeframes: TimeframeModel[];

    averageConflictRating: number;
    averageResolutionRating: number;
    averageWellbeing: number;

    canceledCount: number;
    doneCount: number;
    pendingCount: number;
    pendingDuration: number;

    templateGroupIndex: number;

    constructor(data?: DayStatsModel) {
        this.timeframes = [];
        this.timeframeEvaluations = [];
        this.eventTemplates = [];

        if (data) {
            Object.assign(this, data);
            this.lifeSectionStats = new UnorderedMap<LifeSectionStats>();
            Object.keys(data.lifeSectionStats).forEach(
                key => this.lifeSectionStats.set(key, data.lifeSectionStats[key])
            );
        }
    }

    addDefaultLifeSectionStats(lifeSectionId: string) {
        this.lifeSectionStats.set(lifeSectionId, new LifeSectionStats({
            lifeSectionId: lifeSectionId,
            canceledTimeframeIds: [],
            doneTimeframeIds: [],
            pendingTimeframeIds: [],
            resolutionRating: new UnorderedMap<number>(),
            conflictRating: new UnorderedMap<number>(),
            timeframeWellbeing: new UnorderedMap<number>(),
            averageConflictRating: 0,
            averageResolutionRating: 0,
            averageWellbeing: 0,
            totalActualDuration: 0,
            totalPlannedDuration: 0,
            totalTemplateDuration: 0,
            pendingDuration: 0
        }));
    }

    writeStats(): DayStats {
        this.averageConflictRating = null;
        this.averageResolutionRating = null;
        this.averageWellbeing = null;
        this.lifeSectionStats = new UnorderedMap<LifeSectionStats>();

        for (let item of this.timeframes) {
            let lifeSectionId = item.lifeSectionId;
            if (!this.lifeSectionStats.has(lifeSectionId)) {
                this.addDefaultLifeSectionStats(lifeSectionId);
            }
            this.lifeSectionStats.get(lifeSectionId).addTimeframe(item);
        }

        for (let item of this.timeframeEvaluations) {
            let lifeSectionId = item.timeframeSnapshot.lifeSectionId;
            if (!this.lifeSectionStats.has(lifeSectionId)) {
                this.addDefaultLifeSectionStats(lifeSectionId);
            }
            this.lifeSectionStats.get(lifeSectionId).addEvaluation(item);
        }

        console.log('writing event-templates', this.eventTemplates);
        for (let item of this.eventTemplates) {
            let lifeSectionId = item.lifeSectionId;
            if (!this.lifeSectionStats.has(lifeSectionId)) {
                this.addDefaultLifeSectionStats(lifeSectionId);
            }
            this.lifeSectionStats.get(lifeSectionId).addTemplate(item);
        }

        this.pendingDuration = 0;

        let keys = [...this.lifeSectionStats.keys()];
        for (let key of keys) {
            let lifeSectionStats = this.lifeSectionStats.get(key);
            lifeSectionStats.writeStats();
            this.pendingDuration += lifeSectionStats.pendingDuration;
        }

        this.doneCount = this.timeframes.filter(x => x.done).length;
        this.canceledCount = this.timeframes.filter(x => x.canceled).length;
        this.pendingCount = this.timeframes.filter(x => !x.done && !x.canceled).length;

        this.updateAverageStats();
        return this;
    }

    updateAverageStats() {
        let wellbeingEvaluationCount = 0;
        let totalWellbeing = 0;
        for (let item of this.timeframeEvaluations) {
            if (item.wellbeing) {
                wellbeingEvaluationCount++;
                totalWellbeing += item.wellbeing;
            }
        }

        if (wellbeingEvaluationCount && totalWellbeing) {
            this.averageWellbeing = Math.round(totalWellbeing / wellbeingEvaluationCount);
        }
    }

    getLifeSectionStatsData(): UnorderedMapLike<LifeSectionStatsModel> {
        let keys = [...this.lifeSectionStats.keys()];
        let result = <UnorderedMapLike<LifeSectionStatsModel>>{ };
        for (let key of keys) {
            result[key] = this.lifeSectionStats.get(key).toData();
        }
        return result;
    }

    getLifeSectionBalance(): UnorderedMapLike<{ planned: number, actual: number }> {
        let keys = [...this.lifeSectionStats.keys()];
        let result = { };
        for (let key of keys) {
            let sectionStats = this.lifeSectionStats.get(key);
            sectionStats.writeStats();
            const data = sectionStats.toData();
            result[key] = {
                template: data.totalTemplateDuration,
                planned: data.totalPlannedDuration,
                actual: data.totalActualDuration
            }
        }
        return result;
    }

    toData(): DayStatsModel {
        return {
            id: this.id,
            _rev: this._rev,
            averageConflictRating: this.averageConflictRating,
            averageResolutionRating: this.averageResolutionRating,
            averageWellbeing: this.averageWellbeing,
            canceledCount: this.canceledCount,
            doneCount: this.doneCount,
            lifeSectionStats: this.getLifeSectionStatsData(),
            pendingCount: this.pendingCount,
            timeframeEvaluations: this.timeframeEvaluations,
            timeframes: this.timeframes,
            pendingDuration: this.pendingDuration,
            eventTemplates: this.eventTemplates,
            templateGroupIndex: this.templateGroupIndex
        }
    }
}
