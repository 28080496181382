<ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div class="cell-totals">
        <div *ngFor="let group of day.eventGroups" class="badge" [ngStyle]="{ 'background-color': group.color, 'color': group.fontColor }">&nbsp;</div>
    </div>
</ng-template>
<div #wrapper [style.overflowY]="dragging ? 'hidden' : 'scroll'" class="sc-ion-card-md-h sc-ion-card-md-s hydrated" style="height: 100%; margin: 0 0 0 0 !important;">
    <ng-container [ngSwitch]="view">
        <!--[activeDayIsOpen]="true"-->
        <mwl-calendar-month-view locale="de"
                                 *ngSwitchCase="'month'"
                                 [weekStartsOn]="1"
                                 [viewDate]="viewDate"
                                 [refresh]="refresh"
                                 [events]="events"
                                 [cellTemplate]="customCellTemplate"
                                 (dayClicked)="selectDay($event)"
                                 (beforeViewRender)="beforeMonthViewRender($event)"
                                 (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view locale="de"
                                *ngSwitchCase="'three-days'"
                                [weekStartsOn]="1"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                [hourSegments]="2"
                                [daysInWeek]="3"
                                [snapDraggedEvents]="false"
                                [scrollWrapper]="wrapper"
                                [disableDragAndScroll]="disableDragAndScroll"
                                (dragging)="dragging = $event"
                                (eventClicked)="clicked($event)"
                                (hourSegmentClicked)="addTemplateEvent($event.date)"
                                (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-week-view locale="de"
                                *ngSwitchCase="'week'"
                                [weekStartsOn]="1"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                [hourSegments]="2"
                                [snapDraggedEvents]="false"
                                [scrollWrapper]="wrapper"
                                [disableDragAndScroll]="disableDragAndScroll"
                                (dragging)="dragging = $event"
                                (eventClicked)="clicked($event)"
                                (hourSegmentClicked)="addTemplateEvent($event.date)"
                                (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-week-view locale="de"
                                *ngSwitchCase="'day'"
                                [weekStartsOn]="1"
                                [viewDate]="viewDate"
                                [events]="events"
                                [refresh]="refresh"
                                [hourSegments]="2"
                                [daysInWeek]="1"
                                [snapDraggedEvents]="false"
                                [scrollWrapper]="wrapper"
                                [disableDragAndScroll]="disableDragAndScroll"
                                (move)="move.emit($event)"
                                (dragging)="dragging = $event"
                                (hourSegmentClicked)="addTemplateEvent($event.date)"
                                (eventClicked)="clicked($event)"
                                (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
    </ng-container>
    <div style="padding: 20px 0;">&nbsp;</div>
</div>

