import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DatabaseService} from '@core/database';
import {AppSettings} from '@plugin/settings';
import {environment} from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class DataUpdateService {
    private pendingUpdates: string[] = [];
    private pendingUpdate: boolean = false;

    constructor(private http: HttpClient, private database: DatabaseService, private settings: AppSettings) { }

    async checkForUpdates(): Promise<boolean> {
        const db = await this.database.databaseAwaiter();
        await this.settings.awaiter();
        const domain = await db.getApiDomain();
        const url = `https://${domain}/api/app/updates`;

        let params = new HttpParams();
        params = params.append('databaseSchemaVersion', environment.databaseSchemaVersion + '');

        try {
            const result = await this.http.get<{ id: string, updates: string[] }>(url, {params: params}).toPromise();
            console.log('result from check for data updates >>>',result);
            const lastUpdateId = this.settings.get('last-data-update-id', '0');
            if (result.id === lastUpdateId)
                return false;

            this.pendingUpdates = [];
            const appliedUpdates = this.settings.get<string[]>('applied-data-updates', []);
            for (let update of result.updates) {
                if (!appliedUpdates.includes(update))
                    this.pendingUpdates.push(update);
            }

            this.pendingUpdate = true;
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }

    }

    async installUpdate() {
        if (!this.pendingUpdate)
            return;

        for (let update of this.pendingUpdates) {
            await this.applyUpdate(update);
        }

        const appliedUpdates = this.settings.get<string[]>('applied-data-updates', []);
        appliedUpdates.push(...this.pendingUpdates);
        this.settings.set('applied-data-updates', appliedUpdates);

        this.pendingUpdate = false;
        this.pendingUpdates = [];
    }

    private async applyUpdate(update: string): Promise<boolean> {
        const db = await this.database.databaseAwaiter();
        const domain = await db.getApiDomain();
        const url = `https://${domain}/api/app/updates/${update}`;
        const result = await this.http.get<{ target: string, operation: string, value?: any, options: { where?: any, data?: any[] } }>(url).toPromise();
        if (!result) {
            return false;
        }

        const collection = db.use(result.target);
        let docs = null;
        switch (result.operation) {
            case 'delete':
                docs = await collection.find(result.options.where).exec();
                for (let doc of docs) {
                    await doc.remove();
                }
                break;

            case 'update':
                for (let doc of result.options.data) {
                    await collection.atomicUpsert(doc);
                }
                break;

            case 'insert':
                for (let doc of result.options.data) {
                    await collection.upsert(doc);
                }
                break;

            default:
                console.warn(`operation ${result.operation} is not implemented!`);
        }
        return true;
    }

}
