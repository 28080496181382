import { Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Platform } from '@ionic/angular';
import { DeviceFeedback } from '@ionic-native/device-feedback/ngx';

@Injectable({ providedIn: 'root' })
export class DeviceFeedbackProvider {
    constructor(private platform: Platform, private android: DeviceFeedback) {}

    async tap() {
        await this.platform.ready();
        if (this.platform.is('cordova')) {
            if (this.platform.is('android')) this.android.haptic(3);
            else if (this.platform.is('ios')) await Haptics.impact({ style: ImpactStyle.Light });
        }
    }

    async longPress() {
        await this.platform.ready();
        if (this.platform.is('cordova')) {
            if (this.platform.is('android')) this.android.haptic(0);
            else if (this.platform.is('ios')) await Haptics.impact({ style: ImpactStyle.Heavy });
        }
    }
}

