import {DefaultMigration, MigrationData} from './default-migration';
import {LifeSectionModel} from '@core/models';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {DatabaseCollectionProvider} from '@core/database';
import * as uuid from 'uuid/v4';
import {HttpClient} from '@angular/common/http';

export class LifeSectionsDefault implements DefaultMigration<LifeSectionModel> {
    constructor(private db: DatabaseCollectionProvider, private http: HttpClient) { }

    getData(): MigrationData<LifeSectionModel> {
        return {
            collection: DATABASE_COLLECTIONS.LifeSectionCollection,
            data: [
                {
                    id: uuid(),
                    name: 'Arbeit',
                    shortName: 'ARB',
                    color: { code: '#1E90FF' },
                    enableSync: true,
                    enableEvaluation: true,
                    enableNotifications: true,
                    tasks: [],
                    conflicts: [],
                    resolutions: []
                },
                {
                    id: uuid(),
                    name: 'Partnerschaft',
                    shortName: 'PAR',
                    color: { code: '#DC143C' },
                    enableSync: true,
                    enableEvaluation: true,
                    enableNotifications: true,
                    tasks: [],
                    conflicts: [],
                    resolutions: []
                },
                {
                    id: uuid(),
                    name: 'Entspannung',
                    shortName: 'ENT',
                    color: { code: '#FFA500' },
                    enableSync: true,
                    enableEvaluation: true,
                    enableNotifications: true,
                    tasks: [],
                    conflicts: [],
                    resolutions: []
                },
                {
                    id: uuid(),
                    name: 'Sport',
                    shortName: 'SPO',
                    color: { code: '#9370DB' },
                    enableSync: true,
                    enableEvaluation: true,
                    enableNotifications: true,
                    tasks: [],
                    conflicts: [],
                    resolutions: []
                },
                {
                    id: uuid(),
                    name: 'Familie',
                    shortName: 'FAM',
                    color: { code: '#32CD32' },
                    enableSync: true,
                    enableEvaluation: true,
                    enableNotifications: true,
                    tasks: [],
                    conflicts: [],
                    resolutions: []
                },
                {
                    id: uuid(),
                    name: 'Freunde',
                    shortName: 'FRE',
                    color: { code: '#00FFFF' },
                    enableSync: true,
                    enableEvaluation: true,
                    enableNotifications: true,
                    tasks: [],
                    conflicts: [],
                    resolutions: []
                }
            ]
        }
    }
}
