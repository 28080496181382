import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CalendarDateFormatter, CalendarEventTitleFormatter, CalendarMonthViewDay} from 'angular-calendar';
import {Subject} from 'rxjs';
import {DateFormatterDe} from 'calendar/formatters/date-formatter.de';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {Router} from '@angular/router';
import {TooltipFormatter} from 'calendar/formatters/tooltip-formatter';
import {MetaFeature} from 'calendar/feature-events/meta-feature';
import moment from 'moment';
import Color from 'color';
import {AppSettings} from '@plugin/settings';

export const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    }
};

@Component({
    selector: 'plugin-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: DateFormatterDe
        },
        {
            provide: CalendarEventTitleFormatter,
            useClass: TooltipFormatter
        }
    ]
})
export class CalendarViewComponent implements OnInit {
    @Input() get viewDate(): Date { return this.viewDateValue }
    @Input() view: string = 'month';
    @Input() refresh: Subject<any> = new Subject<any>();
    @Input() get events(): EventDefinition[] { return this.eventsValue }
    @Input() get dragging(): boolean { return this.draggingValue }

    @Output() viewChange: EventEmitter<string> = new EventEmitter();
    @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
    @Output() draggingChange: EventEmitter<boolean> = new EventEmitter();
    @Output() eventsChange: EventEmitter<EventDefinition[]> = new EventEmitter();
    @Output() openEvent: EventEmitter<EventDefinition> = new EventEmitter();
    @Output() timeChange = new EventEmitter<{ event: EventDefinition, start: any, end: any }>();
    @Output() new: EventEmitter<any> = new EventEmitter();
    @Output() move: EventEmitter<any> = new EventEmitter();

    @ViewChild('wrapper', {static: true}) wrapper: ElementRef;

    eventsValue: EventDefinition[];

    scroll: boolean;
    draggingValue: boolean;
    viewDateValue: Date;
    disableDragAndScroll: boolean;

    constructor(private router: Router, private settings: AppSettings) { }

    set viewDate(value: Date) {
        this.viewDateValue = value;
        this.viewDateChange.emit(value);
    }

    set dragging(value: boolean) {
        this.draggingValue = value;
        console.log('dragging', value);
        this.draggingChange.emit(value);
    }

    set events(value: EventDefinition[]) {
        this.eventsValue = value;
        if(this.view === 'month' && value){
            this.eventsValue = value.filter(x => !x.allDay);
        }

        if(!!value){
            this.eventsValue.forEach(item => {
                if(item.allDay){ return; }
                else if(!!item.recurrence) {
                    let dayToAdd = moment(this.viewDateValue).startOf('day').diff(moment(item.start).startOf('day'), 'days');
                    if(moment(item.recurrence.until).startOf('day').diff(moment(this.viewDate).startOf('day')) < 0 || dayToAdd <= 0){
                        return;
                    }
                    item.start = moment(item.start).add(dayToAdd, 'days').toDate();
                    item.end = moment(item.end).add(dayToAdd, 'days').toDate();
                }
            });
        }
        this.eventsChange.emit(value);
        this.refresh.next();
    }

    resetTemplateEvent() {
        this.events = this.events.filter(x => !x.isNew);
        this.refresh.next();
    }

    addTemplateEvent(date) {
        this.new.emit(date);
    }

    selectDay(event) {
        this.router.navigate([`/calendar/day/${moment(event.day.date).toISOString()}`]);
    }

    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach(cell => {
            const groups: any = {};
            cell.events.forEach((event: EventDefinition<MetaFeature>) => {
                if (event.meta.feature && event.color) {
                    let groupId = event.meta.options.lifeSectionId;
                    if (!groups[groupId]) {
                        const isDark = Color(event.color.primary).isDark();
                        const fontColor = isDark ? '#FFFFFF' : '#000000';
                        groups[groupId] = {
                            color: event.color.primary,
                            fontColor: fontColor,
                            entries: []
                        }
                    }

                    groups[groupId].entries.push(event);
                }
            });
            cell['eventGroups'] = Object.values(groups);
        });
    }

    eventTimesChanged({event, newStart, newEnd}) {
        this.timeChange.emit({
            event: event,
            start: newStart,
            end: newEnd
        })
    }

    clicked(e) {
        this.openEvent.emit(e);
    }

    ngOnInit() {
        this.disableDragAndScroll = !this.settings.get('calendar-drag-scroll', false);
        setTimeout(() => {
            if (this.wrapper) {
                let now = moment().startOf('hour');
                let hour = now.hour();
                let ratio = hour / 24;
                let element: HTMLElement = this.wrapper.nativeElement;
                element.scrollTo({ top: element.scrollHeight * ratio, behavior: 'auto' });
            }
        }, 250)
    }
}
