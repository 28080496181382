<ng-container *ngIf="item">
    <ion-card>
        <ion-card-content>
            <ion-item>
                <ion-label position="floating" color="medium">Lebensbereich Titel</ion-label>
                <ion-input [(ngModel)]="item.name" spellcheck="true" autoComplete="true" autocorrect="true"></ion-input>
                <svg width='40' height='40' slot="end" style="margin-bottom: -16px; box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);" (click)="changeColor.emit()">
                    <rect height="40" width="40" attr.fill="{{item.color.code}}" stroke-width="2" attr.stroke="{{item.color.code}}"></rect>
                </svg>
            </ion-item>
            <ion-item>
                <ion-label position="floating" color="medium">Kürzel</ion-label>
                <ion-input [(ngModel)]="item.shortName" [maxlength]="3" [minlength]="1" [autocapitalize]="true"></ion-input>
            </ion-item>
        </ion-card-content>
    </ion-card>
    <ion-card>
        <ion-card-header>
            <ion-label>Einstellungen</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-item lines="none">
                <ion-label>Kalender Benachrichtigungen</ion-label>
                <ion-toggle [(ngModel)]="item.enableNotifications"></ion-toggle>
            </ion-item>
            <ion-item lines="none">
                <ion-label>Lebensbereich bewerten</ion-label>
                <ion-toggle [(ngModel)]="item.enableEvaluation"></ion-toggle>
            </ion-item>
            <ion-item lines="none">
                <ion-label>Daten synchronisieren</ion-label>
                <ion-toggle [(ngModel)]="item.enableSync"></ion-toggle>
            </ion-item>
        </ion-card-content>
    </ion-card>
</ng-container>
