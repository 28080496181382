import {DatabaseCollectionProvider} from '@core/database';
import {Injectable} from '@angular/core';
import {AssetRef} from './asset';
import {HttpClient} from '@angular/common/http';
import {FileService} from '../../../../src/user/app/file.service';
import {Platform} from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class AssetManagerService {
    constructor(private pouchDb: DatabaseCollectionProvider, private http: HttpClient, private fileService: FileService, private platform: Platform) { }

    async downloadAsset(asset: AssetRef): Promise<AssetRef> {
        const domain = await this.pouchDb.getApiDomain();
        return await this.http.get<AssetRef>(`https://${domain}/api/AppSettings/asset/${asset.id}`).toPromise();
    }

    async verify(asset: AssetRef) {
        let doc = await this.pouchDb.db.getLocal(`asset/${asset.id}`);
        let docExists = !!doc;
        let mustFetch = !docExists;

        if (docExists) {
            let existingDoc: any = doc.toJSON();
            mustFetch = existingDoc['checksum'] !== asset.checksum;
        }

        if (mustFetch) {
            await this.fetchAssetById(asset.id);
        }
    }

    async fetchAssetById(assetId: string) {
      const domain = await this.pouchDb.getApiDomain();
      let result = await this.http.get<AssetRef>(`https://${domain}/api/AppSettings/asset/${assetId}`).toPromise();
      if (!result) {
        return null;
      }
      let document = await this.pouchDb.db.upsertLocal(`asset/${assetId}`, {
          id: result.id,
          type: result.type,
          checksum: result.checksum,
          data: result.data,
      });

      return document;
    }

    isInMemory(uri: string): boolean {
        return uri.startsWith('assets://')
    }

    transform(uri: string): string {
        const isMobile = (this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova');
        if (isMobile) {
            const url = this.fileService.getFileUrl(uri);
            return this.fileService.convertFileUrlToIonicUrl(url);
        } else {
            return this.fileService.getFileUrl(uri);
        }
    }

    async $asset(uri: string): Promise<string> {
        if (!this.isInMemory(uri))
            return this.transform(uri);

        uri = uri.substring(9);
        let doc = await this.pouchDb.db.getLocal(`asset/${uri}`);
        if (!doc) {
          const asset = await this.fetchAssetById(uri);
          if (asset) {
            return (asset.toJSON() as any).data;
          } else {
            return null;
          }
        }

        return ((await doc).toJSON() as any).data;
    }
}
