<div>
    <ion-item lines="none" *ngIf="!!resolution">
        <ion-label>{{resolution.text}}</ion-label>
        <ion-icon slot="end" name="create" (click)="edit()"></ion-icon>
        <ion-icon slot="end" name="trash" (click)="delete()"></ion-icon>
    </ion-item>
    <ion-item lines="none" *ngIf="!resolution">
        <ion-label>Bitte auswählen</ion-label>
        <ion-icon slot="end" name="add-circle-outline" (click)="add()"></ion-icon>
    </ion-item>
</div>
