import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {DatabaseCollectionProvider} from '@core/database';
import {RxCollection} from 'rxdb';
import {DayEvaluationModel, DayStatsModel} from '@core/models';
import {Moment} from 'moment';
import * as moment from 'moment';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {FeaturedEventProvider} from 'calendar/feature-events/meta-feature';
import {InternalRouteFeature} from 'calendar/feature-events/internal-route.feature';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {DayStatisticService} from '@plugin/statistics';

@Injectable({
    providedIn: 'root'
})
export class DayEvaluationEventService implements FeaturedEventProvider<InternalRouteFeature> {
    private active: boolean;
    private settings: AppSettings;
    private dayStatsService: DayStatisticService;
    private dayStatsCollection: RxCollection<DayStatsModel>;
    private dayEvaluationCollection: RxCollection<DayEvaluationModel>;

    activate(settings: AppSettings, collectionProvider: DatabaseCollectionProvider, dayStatsService: DayStatisticService) {
        this.active = true;
        this.settings = settings;
        this.dayStatsService = dayStatsService;
        this.dayStatsCollection = collectionProvider.use<DayStatsModel>(DATABASE_COLLECTIONS.DayStatsCollection);
        this.dayEvaluationCollection = collectionProvider.use<DayEvaluationModel>(DATABASE_COLLECTIONS.DayEvaluationCollection);
    }

    async getPendingDates(start: Moment, end: Moment): Promise<{date: Moment, suggestedWellbeing: number}[]> {
        // was always 'Mon Jan 19 1970 01:15:05 GMT+0100 (Mitteleuropäische Normalzeit)'
        const initDate = moment.unix(this.settings.get('init-timestamp')); 
        const maxDaysUp = this.settings.get('day-evaluation-availability-duration', 3);
        const today = moment();
        const oldestAvailable = moment(today).startOf('day').subtract(maxDaysUp, 'days');

        if (start.isBefore(initDate))
            start = moment(initDate);

        if (start.isBefore(oldestAvailable))
            start = moment(oldestAvailable);

        if (end.isAfter(today))
            end = moment(today).endOf('day');

        let existingEvaluations: { [date: string]: boolean } = { };
        for (let date = moment(start); date.isSameOrBefore(end, 'day'); date = moment(date).add(1, 'day')) {
            existingEvaluations[date.format('YYYYMMDD')] = false;
        }

        const statsData = await this.dayStatsService.getLastDays(start, end);
        const stats: { [index: number]: DayStatsModel } = { };
        for (let stat of statsData) {
                stats[+stat.id] = stat;
        }

        let evaluations =
            await this.dayEvaluationCollection
                .find()
                .where('day')
                .lte(end.format('YYYYMMDD'))
                .gte(start.format('YYYYMMDD'))
                .exec();

        evaluations.forEach(x => existingEvaluations[x['day']] = true);

        return Object.keys(existingEvaluations)
            .filter(x => !existingEvaluations[x])
            .map(x => moment(x, 'YYYYMMDD'))
            .sort((a,b) => b.diff(a))
            .map(date => {
               let dayStats = stats[date.unix()];
               return {
                   date: date,
                   suggestedWellbeing: dayStats ? dayStats.averageWellbeing : 4
               }
            })
    }

    async getEvents(start: Moment, end: Moment): Promise<EventDefinition[]> {
        if (!this.active)
            return [];

        const pendingDates = await this.getPendingDates(start, end);
        return pendingDates
            .map(x => x.date)
            .map(date => ({
                id: date.format('YYYYMMDD'),
                start: new Date(date.toISOString()),
                end: new Date(date.toISOString()),
                recurrence: null,
                title: `Tag bewerten`,
                allDay: true,
                meta: {
                    feature: 'internal-route',
                    options: {
                        route: '/evaluate-day',
                        params: [date.format('YYYYMMDD')],
                        dialog: {
                            label: 'Zur Tagesbewertung',
                            icon: 'log-out',
                            showCancel: true
                        }
                    }
                }
            }
        ))
    }
}
