import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {DatabaseModule} from '@core/database';
import {adapterFactory, CalendarModule as ExternalCalendarModule, DateAdapter} from 'angular-calendar';
import {RScheduleConfig} from '@rschedule/rschedule';
import {MomentDateAdapter} from '@rschedule/moment-date-adapter';
import {EventTemplatesWeekComponent} from './event-templates-week/event-templates-week.component';
import localeDe from '@angular/common/locales/de';
import {EventTemplatesDayComponent} from '@library/plugin-event-templates/src/lib/event-templates-day/event-templates-day.component';
import {EventTemplateService} from '@library/plugin-event-templates/src/lib/services/event-template.service';
import {EventTemplateSelectionComponent} from './event-template-selection/event-template-selection.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';

RScheduleConfig.defaultDateAdapter = MomentDateAdapter;

registerLocaleData(localeDe);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DatabaseModule,
        MatExpansionModule,
        MatListModule,
        ExternalCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    declarations: [
        EventTemplatesWeekComponent,
        EventTemplatesDayComponent,
        EventTemplateSelectionComponent
    ],
    exports: [
        EventTemplatesWeekComponent,
        EventTemplatesDayComponent,
        EventTemplateSelectionComponent
    ]
})
export class EventTemplatesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: EventTemplatesModule,
            providers: [
                { provide: EventTemplateService, useClass: EventTemplateService }
            ]
        }
    }
}
