import {RxJsonSchema} from 'rxdb';

export const SurveyResultSchema: RxJsonSchema | any = {
    "version": 1,
    "type": "object",
    "title": "Survey Result",
    "properties": {
        "key": {
            "type": "string",
            "primary": true
        },
        "surveyKey": {
            "type": "string"
        },
        "surveyEvaluationType": {
            "type": "string"
        },
        "surveyDate": {
            "type": "string"
        },
        "submitDate": {
            "type": "string"
        },
        "answers": {
            "type": "array",
            "items": {
                "type": ["number", "string"]
            }
        }
    }
};
