<div #wrapper [style.overflowY]="dragging ? 'hidden' : 'scroll'" class="sc-ion-card-md-h sc-ion-card-md-s hydrated" style="height: 100%; margin: 0 0 0 0 !important;">
    <mwl-calendar-week-view locale="de"
                            [viewDate]="day"
                            [events]="events"
                            [daysInWeek]="1"
                            [scrollWrapper]="wrapper"
                            (dragging)="dragging = $event"
                            [disableDragAndScroll]="disableDragAndScroll"
                            [snapDraggedEvents]="false"
                            [refresh]="refresher"
                            [hourSegments]="4"
                            (hourSegmentClicked)="hourClicked.emit($event.date)"
                            (eventClicked)="eventClicked.emit($event.event)"
                            (eventTimesChanged)="eventTimeChange.emit($event)">
    </mwl-calendar-week-view>
    <div style="padding-bottom: 10vh;">&nbsp;</div>
</div>

