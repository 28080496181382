import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class AppGlobals{
    private dateToUse: any;

    constructor() {
        this.dateToUse = new Date();
    }

    getDateToUse() {
        return this.dateToUse;
    }

    setDateToUse(date) {
        this.dateToUse = new Date(moment(date).toISOString());
    }
}
