import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ContextMenuInteraction} from '@core/shared';

@Component({
    selector: 'plugin-calendar-options',
    templateUrl: './calendar-options.component.html',
    styleUrls: ['./calendar-options.component.scss']
})
export class CalendarOptionsComponent implements OnInit {
    @Input() view: string;
    @Input() viewDate: Date;
    @Input() locale: string = 'de';

    @Output() viewChange: EventEmitter<string> = new EventEmitter();
    @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

    constructor() { }

    ngOnInit() { }
}