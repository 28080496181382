import {CollectionsDefinitions} from '@library/core-database/src/lib/definitions';
import {RxCollection, RxDatabase, createRxDatabase as openDatabase, addRxPlugin, RxDatabaseCreator, PouchDB} from 'rxdb';
import {RxDBJsonDumpPlugin} from 'rxdb/plugins/json-dump';
import {DATABASE_MODEL_SCHEMA} from '@library/core-models/src/schema';
import AdapterIndexedDb from 'pouchdb-adapter-idb';
import AdapterHttp from 'pouchdb-adapter-http';
import SqliteAdapter from 'pouchdb-adapter-cordova-sqlite';
import {Inject, Injectable, InjectionToken} from '@angular/core';

addRxPlugin(AdapterIndexedDb);
addRxPlugin(AdapterHttp);
addRxPlugin(SqliteAdapter);
addRxPlugin(RxDBJsonDumpPlugin);

export type AuthData = {
    hostname: string;
    username: string;
    password: string;
    remoteDb: string;
}
export type SyncAuthData = AuthData;
export type SyncSettings = { uri: string, name: string, collections: { remote: string, local: string }[] };
export const PRIMARY_DATABASE = 'derena_app_db';

export class CollectionManager {
    private readonly settingsDocumentId: string;

    constructor(private collection: RxCollection) {
        this.settingsDocumentId = `collection-settings-${this.collection.name}`;
    }

    get() {
        return this.collection;
    }

    async sync(remote?: string, save: boolean = false) {
        if (!remote) {
            const document = await this.collection.database.getLocal(this.settingsDocumentId);
            console.log('sync settings:', document);
            if (!document)
                return;

            remote = document.get('remote');
        } else if (save) {
            await this.collection.database.upsertLocal(this.settingsDocumentId, { remote: remote });
        }

        this.collection.sync({
            remote: remote,
            waitForLeadership: false,
            options: {
                heartbeat: 1000,
                live: true,
                retry: true
            }
        });
    }
}

export const POUCHDB_ADAPTER = new InjectionToken<string>('PouchDB Adapter');

@Injectable({ providedIn: 'root' })
export class DatabaseCollectionProvider {
    dbs: { [index: string]: RxDatabase };
    definitions: CollectionsDefinitions;
    primaryDatabase: string;
    defaultAdapter: string;

    get db(): RxDatabase { return this.dbs[this.primaryDatabase] }
    set db(value) { this.dbs[this.primaryDatabase] = value }

    constructor(@Inject(POUCHDB_ADAPTER) adapter) {
        this.defaultAdapter = adapter;
        this.primaryDatabase = PRIMARY_DATABASE;
        this.definitions = DATABASE_MODEL_SCHEMA;
        this.dbs = {};
    }

    private async initCollection<T>(name: string): Promise<RxCollection<T>> {
        return this.db.collections[this.definitions[name].name] || await this.db.collection(this.definitions[name]);
    }

    async setPrimaryDb(dbName: string) {
        this.primaryDatabase = dbName;
        await this.database(dbName);
    }

    async saveSyncSettings(settings) {
        await this.db.upsertLocal('sync-settings', settings);
    }

    async getSyncSettings(): Promise<SyncSettings> {
        try {
            const document = await this.db.getLocal('sync-settings');
            if (!document) {
                console.warn('no sync settings found!')
                return null;
            }

            return {
                uri: document.get('uri'),
                name: document.get('name'),
                collections: document.get('collections')
            }
        } catch (e) {
            console.warn('warning->getSyncSettings', e);
            return null;
        }
    }

    async removeSyncSettings() {
        const document = await this.db.getLocal('sync-settings');
        if (document) {
            await document.remove();
        }

        /**
         * @author Patrick Farnkopf <farnkopf@solutec.de>
         * @date 2020-07-23
         * After RxDB dependency upgrade local database-wide documents are not longer stored in _admin collection.
         */
        try {
            const adminDatabaseName = `${PRIMARY_DATABASE}-rxdb-0-_admin`;
            const adminDb = PouchDB(adminDatabaseName);
            if (adminDb) {
                await adminDb.destroy();
            }
        } catch (e) {
            console.log('removeSyncSettings', e);
        }
    }

    async database(dbName: string = this.primaryDatabase, adapter: string = this.defaultAdapter, options?: any): Promise<RxDatabase> {
        if (!this.dbs[dbName]) {
            const config: RxDatabaseCreator = {
                name: dbName,
                adapter: adapter,
                options: options
            };

            if (adapter === 'cordova-sqlite') {
                config.pouchSettings = {
                    iosDatabaseLocation: 'default',
                    revs_limit: 1,
                    auto_compaction: false
                }
            } else {
                config.pouchSettings = {
                    auto_compaction: false
                }
            }

            this.dbs[dbName] = await openDatabase(config)
        }
        return this.dbs[dbName];
    }

    async connect() {
        await this.database();

        /**
         * @author Patrick Farnkopf <farnkopf@solutec.de>
         * @date 2020-07-10
         *
         * After RxDB dependency upgrade local database-wide documents are no longer stored in _admin collection.
         * There are now stored in _internal collection. Unfortunately there isn't a migration strategy provided, so we must do it manually.
         */
        try {
            const syncSettings = await this.getSyncSettings();
            if (!syncSettings) {
                const adminDatabaseName = `${PRIMARY_DATABASE}-rxdb-0-_admin`;
                const adminDb = PouchDB(adminDatabaseName);
                if (adminDb) {
                    const settings = await adminDb.get('_local/sync-settings');
                    if (settings) {
                        delete settings._id;
                        delete settings._rev;
                        console.log('migrating sync-settings', settings);
                        await this.db.upsertLocal('sync-settings', settings);
                    }

                    const background = await adminDb.get('_local/asset/background-image-1');
                    if (background) {
                        delete background._id;
                        delete background._rev;
                        console.log('migrating asset/background-image-1', background);
                        await this.db.upsertLocal('asset/background-image-1', background);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }

        /* -------------------------------------------------------------------------------- */
    }

    async install<T>(type: (new (...args) => T) | string): Promise<CollectionManager> {
        if (!type) {
            return null;
        }
        let name = typeof(type) === 'string' ? type : type.name;
        if (!this.definitions[name]) {
            console.warn('collection definition', name, 'not found');
            return null;
        }

        const collection = await this.initCollection(name);
        this.db.collections[this.definitions[name].name] = collection;

        return new CollectionManager(collection);
    }

    async getRemoteCollection(collectionName: string): Promise<string> {
        const collection = this.use(collectionName);
        const settingsDocumentId = `sync-settings`;

        const document = await collection.database.getLocal(settingsDocumentId);
        if (!document)
            return null;

        const collections = document.get('collections');
        const remoteName = await collections.find(x => x.local === collectionName);
        if (!remoteName) {
            return null;
        }

        return `${document.get('uri')}/${remoteName.remote}`;
    }

    async getApiDomain(): Promise<string> {
        try {
            const settings = await this.db.getLocal('sync-settings');
            let d = settings.toJSON();
            let uri = d['uri'];
            let data = uri.split('@');
            let domain = data[1].split(':')[0];
            console.log('domain', domain);
            return domain;
        } catch (e) {
            console.log(e);
            return 'localhost';
        }
    }

    use<T>(type: (new (...args) => T) | string): RxCollection<T> {
        if (!type) {
            return null;
        }
        let name = typeof(type) === 'string' ? type : type.name;
        if (!this.definitions[name])
            throw new Error('requested model does not exist');

        if (!this.db)
            throw new Error('no database connected');

        if (!this.db.collections[this.definitions[name].name])
            throw new Error('requested collection was not installed yet!');

        return this.db.collections[this.definitions[name].name];
    }
}
