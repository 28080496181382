import {RxJsonSchema} from 'rxdb';

export const EventSchema: RxJsonSchema | any = {
    "version": 2,
    "type": "object",
    "title": "events",
    "indexes": ["day"],
    "properties": {
        "id": {
            "type": "string",
            "primary": true
        },
        "title": {
            "type": "string"
        },
        "day": {
            "type": "integer"
        },
        "start": {
            "type": ["string", "object"]
        },
        "end": {
            "type": ["string", "object"]
        },
        "parent": {
            "type": "string"
        },
        "color": {
            "type": "object",
            "properties": {
                "primary": {
                    "type": "string"
                },
                "secondary": {
                    "type": "string"
                }
            }
        },
        "actions": {
            "type": "array",
            "item": {
                "type": "object",
                "properties": {
                    "label": {
                        "type": "string"
                    },
                    "cssClass": {
                        "type": "string"
                    },
                    "onClick": {
                        "type": "object"
                    }
                }
            }
        },
        "allDay": {
            "type": "boolean"
        },
        "cssClass": {
            "type": "string"
        },
        "resizable": {
            "type": "object",
            "properties": {
                "beforeStart": {
                    "type": "boolean"
                },
                "afterEnd": {
                    "type": "boolean"
                }
            }
        },
        "draggable": {
            "type": "boolean"
        },
        "recurrence": {
            "type": ["object", "null"],
            "properties": {
                "start": {
                    "type": "string"
                },
                "until": {
                    "type": ["string", "null"]
                },
                "frequency": {
                    "type": ["number", "string", "null"]
                },
                "interval": {
                    "type": ["number", "null"]
                },
                "count": {
                    "type": ["number", "null"]
                },
                "weekStart": {
                    "type": ["string", "null"]
                },
                "byMonthOfYear": {
                    "type": ["number", "null"]
                },
                "byDayOfMonth": {
                    "type": ["number", "null"]
                },
                "byDayOfWeek": {
                    "type": ["array", "null"],
                    "items": { "type": "string" }
                },
                "byHourOfDay": {
                    "type": ["number", "null"]
                },
                "byMinuteOfHour": {
                    "type": ["number", "null"]
                },
                "bySecondOfMinute": {
                    "type": ["number", "null"]
                }
            }
        },
        "meta": {
            "type": ["object", "string"]
        }
    }
};
