import {SurveyEventProvider} from '../survey-event.provider';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {SurveyModel} from '@core/models';
import {RRule, Schedule} from '@rschedule/rschedule';
import * as moment from 'moment';

export class StaticSurveyEventProvider implements SurveyEventProvider {
    async getEvents(survey: SurveyModel): Promise<EventDefinition[]> {
        const result: EventDefinition[] = [];
        const rule = new RRule({ start: moment(survey.start), frequency: survey.frequency, interval: survey.interval, until: moment(survey.until) });
        const schedule = new Schedule({ rrules: [rule] });
        const data = schedule.occurrences({ take: 100 }).toArray();
        const dates = data!.map(x => moment(x.toISOString()));

        for (let date of dates) {
            result.push({
                id: date.format('YYYYMMDD'),
                title: survey.title,
                start: new Date(date.toISOString()),
                allDay: true,
                color: {
                    primary: "#FF5733",
                    secondary: "#ffe5ae"
                },
                meta: {
                    feature: 'internal-route',
                    options: {
                        route: '/survey',
                        params: [survey.key, date.toISOString()],
                        dialog: {
                            label: `Zu ${survey.title}`,
                            icon: 'log-out',
                            showCancel: true
                        }
                    }
                }
            });
        }

        return result;
    }
}
