import {RxJsonSchema} from 'rxdb';

export const EventTemplateSchema: RxJsonSchema | any = {
    "version": 1,
    "type": "object",
    "title": "event templates",
    "additionalProperties": true,
    "indexes": ["weekday"],
    "properties": {
        "id": {
            "type": "string",
            "primary": true
        },
        "title": {
            "type": "string"
        },
        "weekday": {
            "type": "integer"
        },
        "day": {
            "type": ["integer", "null"]
        },
        "groupIndex": {
            "type": ["integer", "null"]
        },
        "tasks": {
            "type": ["array", "null"]
        },
        "conflict": {
            "type": ["object", "null"]
        },
        "resolution": {
            "type": ["object", "null"]
        },
        "lifeSectionId": {
            "type": "string"
        },
        "start": {
            "type": ["string", "object"]
        },
        "end": {
            "type": ["string", "object"]
        },
        "parent": {
            "type": "string"
        },
        "color": {
            "type": "object",
            "properties": {
                "primary": {
                    "type": "string"
                },
                "secondary": {
                    "type": "string"
                }
            }
        },
        "allDay": {
            "type": "boolean"
        },
        "cssClass": {
            "type": "string"
        },
        "resizable": {
            "type": "object",
            "properties": {
                "beforeStart": {
                    "type": "boolean"
                },
                "afterEnd": {
                    "type": "boolean"
                }
            }
        },
        "draggable": {
            "type": "boolean"
        },
        "meta": {
            "type": ["object", "string"]
        }
    }
};
