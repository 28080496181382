import {Validator} from '@library/core-validation/src/lib/validators/validator';
import {Notifier} from '@library/core-validation/src/lib/notifiers';

export type ValidationTarget<TInput = any, TOutput = any> = {
    expression: (input: TInput) => TOutput;
    validator: Validator<TOutput>;
    errorMessage: string;
};

export class ValidationHolder<TInput> {
    private targets: ValidationTarget<any, any>[];

    constructor(private notifier: Notifier) {
        this.targets = [];
    }

    clear() {
        this.targets = [];
    }

    add(errorMessage: string, validators: Validator<any>[] | Validator<any>, expression: (input: TInput) => any): ValidationHolder<TInput> {
        if (!Array.isArray(validators))
            validators = [validators];

        for (let validator of validators) {
            this.targets.push({
                expression: expression,
                validator: validator,
                errorMessage: errorMessage
            })
        }

        return this;
    }

    validate(input?: TInput): boolean {
        let result = true;
        for (let target of this.targets) {
            if (!target.validator.validate(target.expression(input))) {
                this.notifier.show(target.errorMessage);
                result = false;
            }
        }
        return result;
    }
}
