import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'plugin-conflict-occurrence',
    templateUrl: './conflict-occurrence.component.html',
    styleUrls: ['./conflict-occurrence.component.scss']
})
export class ConflictOccurrenceComponent implements OnInit {
    @Input() readOnly: boolean;
    @Input() text: string;

    occurredValue: boolean;
    @Input() get occurred(): boolean { return this.occurredValue }
    @Output() occurredChange: EventEmitter<boolean> = new EventEmitter();
    set occurred(value: boolean) {
        this.occurredValue = value;
        this.occurredChange.emit(value);
    }

    constructor() { }

    ngOnInit() { }
}
