import {EventDefinition} from 'calendar/models/event-definition.model';
import {Observable, of} from 'rxjs';
import {RecurrenceAdapter} from 'calendar/services/recurrence-adapter';
import {RecurrenceAdapterOptions} from 'calendar/models/recurrence.model';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

export type EventInstanceProviderOptions = RecurrenceAdapterOptions;

export class EventInstanceProvider {
    constructor(private options: EventInstanceProviderOptions) {  }

    getInstances(definition: EventDefinition): Observable<EventDefinition> {
        definition.start = new Date(definition.start);
        definition.end = new Date(definition.end);
        definition.draggable = true;
        definition.resizable = { beforeStart: true, afterEnd: true };

        const occursOnce = !definition.recurrence;
        if (occursOnce)
            return of<any>([definition]);

        definition.recurrence.start = definition.recurrence.start ? moment(definition.recurrence.start) : moment(definition.start);
        definition.recurrence.until = definition.recurrence.until ? moment(definition.recurrence.until) : null;
        definition.recurrence.byDayOfWeek = definition.recurrence.byDayOfWeek || undefined;

        const counter = 0;
        const duration = moment(definition.end).diff(definition.start, 'minutes');
        return new RecurrenceAdapter(this.options)
            .assign(definition.recurrence)
            .toObservable()
            .pipe(
                map(occurrence => {
                    let event = Object.assign({}, definition);
                    event.id = null;
                    event['virtual'] = true;
                    event.start = new Date(moment(occurrence.date).toISOString());
                    event.end = new Date(moment(occurrence.date).add(duration, 'minutes').toISOString());
                    event.recurrence = null;
                    return event;
                })
            );
    }
}
