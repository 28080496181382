import {Injectable} from '@angular/core';
import {DatabaseService} from '@core/database';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import * as moment from 'moment';
import {Moment} from 'moment';
import {environment} from '../../environments/environment';
import {interval} from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class StatsSummaryService {
    constructor(private db: DatabaseService, private platform: Platform) { }

    async updateLastPhq9() {
        let latestDate: Moment = null;
        let latestSurvey = null;

        let db = await this.db.databaseAwaiter();
        let collection = db.use(DATABASE_COLLECTIONS.SurveyResultCollection);
        let surveys = await collection.find().where('surveyKey').eq('survey-phq9-de').exec();

        for (let surveyDoc of surveys) {
            let survey = surveyDoc.toJSON();
            let currentDate = moment(survey['surveyDate']);
            if (latestDate === null || currentDate.isAfter(latestDate)) {
                latestDate = moment(currentDate);
                latestSurvey = survey;
            }
        }

        if (!latestDate)
            return;

        await this.set('last-phq9-date', latestDate.toISOString());
        await this.set('last-phq9-items', latestSurvey['answers']);
        await this.set('last-survey-type', latestSurvey['surveyEvaluationType'] || 'PHQ-9');
    }

    async updateVersion() {
        let os = this.platform.is('android') ? ' - a' : this.platform.is('ios') ? ' - i' : '';
        await this.set('version', environment.version + os);
    }

    async startUpdater() {
        interval(90 * 1000)
            .subscribe(async () => {
                await this.update();
            })
    }

    async update() {
        await this.updateLastPhq9();
        await this.updateVersion();
    }

    async set(key, value) {
        let db = await this.db.databaseAwaiter();
        let collection = db.use(DATABASE_COLLECTIONS.PatientStatsCollection);
        await collection.atomicUpsert({
            key: key,
            value: value,
            lastChange: moment().toISOString()
        });
    }
}
