import {RxJsonSchema} from 'rxdb';
import {TimeframeEvaluationSchema} from '@library/core-models/src/schema/timeframe-evaluation.schema';
import {useProperties} from '@library/core-models/src/schema/schema-helper';

export const DayStatsSchema: RxJsonSchema | any = {
    "version": 5,
    "type": "object",
    "title": "Day Stats",
    "properties": {
        "id": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "timeframeEvaluations": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": useProperties(TimeframeEvaluationSchema)
            }
        },
        "eventTemplates": {
            "type": "array",
            "items": {
                "type": "object"
            }
        },
        "timeframes": {
            "type": "array",
            "items": {
                "type": "object"
            }
        },
        "lifeSectionStats": {
            "type": "object"
        },
        "averageWellbeing": {
            "type": ["number", "null"],
        },
        "averageConflictRating": {
            "type": ["number", "null"],
        },
        "averageResolutionRating": {
            "type": ["number", "null"],
        },
        "doneCount": {
            "type": "number",
        },
        "canceledCount": {
            "type": "number",
        },
        "pendingCount": {
            "type": "number",
        },
        "pendingDuration": {
            "type": "number",
        },
        "templateGroupIndex": {
            "type": "number",
        }
    },
    additionalProperties: true,
};
