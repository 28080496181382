import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PrivacyPolicyComponent } from './privacy-policy.component';

@NgModule({
    declarations: [PrivacyPolicyComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule
    ],
    exports: [PrivacyPolicyComponent]
})
export class PrivacyPolicyModule {}
