import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {CalendarDateFormatter, CalendarEventTitleFormatter} from 'angular-calendar';
import {DateFormatterDe} from '../formatters/date-formatter.de';
import {TooltipFormatter} from '../formatters/tooltip-formatter';
import {WeekDay} from 'calendar-utils';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {AppSettings} from '@plugin/settings';

@Component({
    selector: 'plugin-event-templates-week',
    templateUrl: './event-templates-week.component.html',
    styleUrls: ['./event-templates-week.component.scss'],
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: DateFormatterDe
        },
        {
            provide: CalendarEventTitleFormatter,
            useClass: TooltipFormatter
        }
    ]
})
export class EventTemplatesWeekComponent implements OnInit {
    @Input() get events(): EventDefinition[] { return this.eventsValue }
    @Input() refresher: Subject<any> = new Subject<any>();
    @Input() get dragging(): boolean { return this.draggingValue }

    @Output() eventsChange: EventEmitter<EventDefinition[]> = new EventEmitter(true);
    @Output() dayClicked: EventEmitter<WeekDay> = new EventEmitter(true);
    @Output() hourClicked: EventEmitter<Date> = new EventEmitter(true);
    @Output() eventClicked: EventEmitter<EventDefinition | any> = new EventEmitter(true);
    @Output() eventTimeChange: EventEmitter<any> = new EventEmitter();
    @Output() draggingChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('wrapper', {static: true}) wrapper: ElementRef;

    viewDate: Date;
    eventsValue: EventDefinition[];
    draggingValue: boolean;
    disableDragAndScroll: boolean;

    constructor(private settings: AppSettings) { }

    set events(value: EventDefinition[]) {
        this.eventsValue = value;
        this.refresher.next();
    }

    set dragging(value: boolean) {
        this.draggingValue = value;
        this.draggingChange.emit(value);
    }

    ngOnInit() {
        this.disableDragAndScroll = !this.settings.get('calendar-drag-scroll', false);
        this.viewDate = new Date(moment().locale('de').startOf('week').toISOString());
        setTimeout(() => {
            if (this.wrapper) {
                let now = moment().startOf('hour');
                let hour = now.hour();
                let ratio = hour / 24;
                let element: HTMLElement = this.wrapper.nativeElement;
                element.scrollTo({ top: element.scrollHeight * ratio, behavior: 'auto' });
            }
        }, 300)
    }
}
