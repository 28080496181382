import {NotificationInterceptor} from '../notification-interceptor';
import {NotificationUpdate} from '../notification-update';
import {Observable, Subject} from 'rxjs';
import {DatabaseCollectionProvider} from '@core/database';
import {RxCollection} from 'rxdb';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {LifeSectionModel, TimeframeModel} from '@core/models';
import { LifeSectionService } from '@library/plugin-life-section/src/public_api';

@Injectable({
    providedIn: 'root'
})
export class CalendarEventInterceptor implements NotificationInterceptor {
    private stream: Subject<NotificationUpdate> = new Subject();
    private db: RxCollection<EventDefinition>;
    private tf: RxCollection<TimeframeModel>;
    private lifeSections: LifeSectionModel[] = []; 

    get groupId(): string { return 'event' }

    constructor(private dbProvider: DatabaseCollectionProvider, private lifeSectionService: LifeSectionService) { }

    async init() {
        if (this.db)
            return;

        this.db = this.dbProvider.use(DATABASE_COLLECTIONS.EventCollection);
        this.tf = this.dbProvider.use(DATABASE_COLLECTIONS.TimeframeCollection);
        this.lifeSections = await this.lifeSectionService.getAllOnce();
        this.start();
    }

    listen(): Observable<NotificationUpdate> {
        this.init();
        return this.stream.asObservable();
    }

    getTitle(doc: EventDefinition): string {
        const isTimeframe = doc.meta && doc.meta.feature && doc.meta.feature === 'timeframe';
        if (isTimeframe) {
            return 'Ein neues Zeitfenster steht an:';
        } else return 'Ein neuer Termin steht an:';
    }

    getMessage(doc: EventDefinition): string {
        return `"${doc.title}" ${moment(doc.start).format('HH:mm')} - ${moment(doc.end).format('HH:mm')}`;
    }

    async handle(type: string, id: string) {
        switch (type) {
            case 'delete': {
                this.stream.next({ id: id, updateType: 'delete' });
                break;
            }
            case 'create':
            case 'update': {
                let doc = (await this.db.findOne(id).exec()).toJSON();
                if (doc) {
                  const lifeSection = this.lifeSections.filter(x => x.id === doc.meta.options.lifeSectionId)[0];
                  if (lifeSection && lifeSection.enableNotifications) {
                    this.stream.next({
                        id: id,
                        title: this.getTitle(doc),
                        message: this.getMessage(doc),
                        updateType: type,
                        vibrate: true,
                        trigger: moment(doc.start).subtract(5, 'minutes')
                    });
                  }
                }
                break;
            }
        }
    }

    start() {
        this.db.insert$.subscribe(x => this.handle('create', x.documentId));
        this.db.update$.subscribe(x => {
            if (x.documentData.meta.feature !== 'timeframe') {
               setTimeout(() => {
                   this.handle('update', x.documentId);
               },2000);
            }
        });
        this.db.remove$.subscribe(x => this.handle('delete', x.documentId));
        this.tf.update$.subscribe(x => {
            const mustDelete = x.documentData.done || x.documentData.canceled;
            if (mustDelete) {
                this.handle('delete', x.documentData.eventId);
            }else{
                setTimeout(() => { this.handle('update', x.documentData.eventId)},2000);
            }
        })

        this.lifeSectionService.observeChanges().subscribe(async (newLifeSections) => {
          this.lifeSections = newLifeSections;
        });
    }
}
