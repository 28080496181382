import {AsyncSubject, BehaviorSubject, Observable} from 'rxjs';
import 'rxjs-compat/add/operator/filter';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import {EventEmitter} from '@angular/core';

export interface ContextMenuEntry {
    id: any;
    title: string;
    icon: string;
}

export interface ContextMenuInteraction {
    setContextMenu(items: ContextMenuEntry[]);
    onInvoke(): Observable<ContextMenuEntry>;
}

export interface ContextMenuHandler {
    invoke(item: ContextMenuEntry);
    onUpdate(): Observable<ContextMenuEntry[]>;
}

export class ContextMenuService implements ContextMenuInteraction, ContextMenuHandler {
    item: EventEmitter<ContextMenuEntry> = new EventEmitter();
    items: EventEmitter<ContextMenuEntry[]> = new EventEmitter();

    constructor() { }

    setContextMenu(items: ContextMenuEntry[]) {
        this.items.emit(items);
    }

    invoke(item: ContextMenuEntry) {
        this.item.emit(item);
    }

    onInvoke(): Observable<ContextMenuEntry> {
        return this.item;
    }

    onUpdate(): Observable<ContextMenuEntry[]> {
        return this.items;
    }

    private static _instance: ContextMenuInteraction;
    static instance(): ContextMenuInteraction {
        if (!this._instance)
            this._instance = new ContextMenuService();
        return this._instance;
    }
}