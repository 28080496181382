import {RxJsonSchema} from 'rxdb';

export const LifeSectionSchema: RxJsonSchema | any = {
    "version": 2,
    "type": "object",
    "title": "Life Section",
    "properties": {
        "id": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "name": {
            "type": "string"
        },
        "shortName": {
            "type": "string"
        },
        "enableNotifications": {
            "type": "boolean"
        },
        "enableEvaluation": {
            "type": "boolean"
        },
        "enableSync": {
            "type": "boolean"
        },
        "color": {
            "type": "object",
            "properties": {
                "name": {
                    "type": "string"
                },
                "code": {
                    "type": "string"
                }
            }
        },
        "conflicts": {
            "type": "array",
            "item": {
                "text": {
                    "type": "string"
                },
                "solution": {
                    "type": "string"
                }
            }
        },
        "resolutions": {
            "type": "array",
            "item": {
                "text": {
                    "type": "string"
                }
            }
        },
        "tasks": {
            "type": ["array", "null"],
            "properties": {
                "type": "object"
            }
        }
    }
};
