import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'plugin-rating-bar',
  templateUrl: './plugin-rating-bar.component.html',
  styles: ['./plugin-rating-bar.component.scss']
})

export class PluginRatingBarComponent implements OnInit, OnChanges {
  @Input() rating: number;
  @Output() ratingChange: EventEmitter<number> = new EventEmitter();
  @Input() titles: string[];
  @Input() readonly: boolean;
  label: string;


  rate(index: number) {
    this.rating = index;
    this.ratingChange.emit(this.rating);
    this.setLabel();
  }

  private isAboveRating(index: number) {
    return index > this.rating;
  }

  getColor(index: number) {
    if (this.isAboveRating(index)) {
      return COLORS.GREY;
    } else {
      return COLORS.YELLOW;
    }
  }

  setLabel() {
    this.label = this.titles[this.rating - 1];
  }

  ngOnInit(): void {
    this.setLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.rating){
      this.setLabel();
    }
  }
}

enum COLORS {
  GREY = '#E0E0E0',
  YELLOW = '#FFCA28'
}
