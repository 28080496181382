import {DocumentIndex, EventTemplateActionModel, EventTemplateColorModel, EventTemplateModel, TaskModel} from '@core/models';
import {ResolutionModel} from '@library/core-models/src/resolution.model';
import {ConflictsModel} from '@library/core-models/src/conflicts.model';
import * as moment from 'moment';

export class EventTemplate implements EventTemplateModel {
    _id: any;
    _rev: any;

    id: string | number;
    index?: DocumentIndex;
    weekday: number;
    groupIndex: number;
    title: string;
    start: Date;
    end: Date;
    actions: EventTemplateActionModel[];
    allDay: boolean;
    color: EventTemplateColorModel;
    cssClass: string;
    draggable: boolean;
    isNew: boolean;
    meta: any;
    parent: string | number;
    resizable: { beforeStart?: boolean; afterEnd?: boolean };
    lifeSectionId: string;
    conflict: ConflictsModel;
    resolution: ResolutionModel;
    tasks: TaskModel[];

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.start = new Date(data.start);
            this.end = new Date(data.end);
        } else {
            this.groupIndex = 0;
        }
    }

    toJSON(wipeDbRelation: boolean = false): EventTemplateModel&any {
        const result: any =  {
            weekday: this.weekday,
            groupIndex: this.groupIndex,
            title: this.title,
            start: new Date(moment(this.start).toISOString()),
            end: new Date(moment(this.end).toISOString()),
            actions: this.actions,
            allDay: this.allDay,
            color: this.color,
            cssClass: this.cssClass,
            draggable: this.draggable,
            meta: this.meta || {},
            resizable: this.resizable,
            lifeSectionId: this.lifeSectionId,
            conflict: this.conflict,
            resolution: this.resolution,
            tasks: this.tasks,
        }

        if (!wipeDbRelation && this.id) {
            result.id = this.id;
            result.parent = this.parent;
        }

        return result;
    }
}
