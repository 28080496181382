import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PrivacyPolicyService } from './privacy-policy.service';

@Component({
    selector: 'privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
    content: string
    privacyPolicyAccepted: boolean = false;

    constructor(private privacyPolicyService: PrivacyPolicyService) {}

    ngOnInit() {
        this.privacyPolicyService.fetchLatest().then(content => {
            this.content = content
        })
    }

    submit() {
        this.privacyPolicyService.acceptPrivacyPolicy();
    }
}
