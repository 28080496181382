<div>
    <div [innerHTML]="content"></div>

    <div class="separator"></div>

    <ion-item lines="none">
        <ion-checkbox color="primary" [(ngModel)]="privacyPolicyAccepted"></ion-checkbox>
        <ion-label class="ion-text-wrap"><b>Ich habe die Datenschutzerklärung zur Kenntnis genommen.</b></ion-label>
    </ion-item>

    <div class="spacer"></div>

    <ion-button color="primary" expand="block" [disabled]="!privacyPolicyAccepted" fill="outline" (click)="submit()">Fortfahren</ion-button>

    <div class="spacer"></div>
</div>
