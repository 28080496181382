import {BaseModel, DocumentIndexCreator} from '@core/models';
import {DbContext} from '@core/database';
import PouchDB from 'pouchdb';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

export type DocumentChange<T> = {
    id: string;
    fullIndexedId: string;
    deleted: boolean;
    document: T;
};

export interface DatabaseRepositoryInterface {
    settings: any;
    initSync(): Promise<DatabaseRepositoryInterface>;
    installSync(replicationUrl: string): Promise<void>;
    isSyncInstalled(): Promise<boolean>;
    use<Model extends BaseModel | any>(model: string): DbContext<Model>;
    getDriver(): PouchDB.Database;
    changes<T>(modelName?: string): Observable<DocumentChange<T>>;
}


@Injectable({
    providedIn: 'root'
})
export class DatabaseRepository implements DatabaseRepositoryInterface {
    settings: any = null;
    initSync(): Promise<DatabaseRepositoryInterface> { throw new Error('not implemented!') }
    installSync(replicationUrl: string): Promise<void> { throw new Error('not implemented!') }
    isSyncInstalled(): Promise<boolean> { throw new Error('not implemented!') }
    use<Model extends BaseModel>(model: string, indexCreator?: DocumentIndexCreator<Model>): DbContext<Model> { throw new Error('not implemented!') }
    getDriver(): PouchDB.Database { throw new Error("not implemented!") }
    changes<T>(modelName?: string): Observable<DocumentChange<T>> { throw new Error('not implemented!') }
}
