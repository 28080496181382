import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PrivacyPolicyService {
    private privacyPolicyState = new Subject();

    constructor(private appSettings: AppSettings, private http: HttpClient) {}

    async fetchLatest() {
        return await this.http.get('https://trial.de-rena.de/static/privacy/index.html', {responseType: 'text'}).toPromise()
    }

    hasAcceptedPrivacyPolicy(): boolean {
        const value = this.appSettings.get(environment.latestPrivacyPolicy, null);

        return !!value ? true : false;
    }

    waitToAcceptPrivacyPolicy(): Promise<void> {
        return new Promise<void>(resolve => this.privacyPolicyState.subscribe(() => resolve()));
    }

    acceptPrivacyPolicy() {
        this.appSettings.set(environment.latestPrivacyPolicy, new Date().toISOString());
        this.privacyPolicyState.next();
    }
}
