import { Component, OnInit } from '@angular/core';
import { PinLockService } from './pin-lock.service';
import { AlertController } from '@ionic/angular';
import { DeviceFeedbackProvider } from '@library/core-shared/src/gesture';
import { TrialService } from '../trial.service';

@Component({
    selector: 'pin-lock',
    templateUrl: './pin-lock.component.html',
    styleUrls: ['./pin-lock.component.scss']
})
export class PinLockComponent implements OnInit {
    input: string;
    wrongPinEnteredCount: number;
    lockTimer: number;
    newPINTyped: boolean = false;
    newPINValue: string = '';
    showPINDescription: boolean = false;

    constructor(private pinLockService: PinLockService,
        private deviceFeedbackService: DeviceFeedbackProvider,
        private trialService: TrialService,
        private alertController: AlertController) { }

    submit() {
        if (this.pinLockService.hasPin()) {
            if (this.pinLockService.pinChange) {
                if (!this.newPINTyped && this.pinLockService.validatePin(this.input)) {
                    this.newPINValue = 'ready';
                    this.input = '';
                    this.showNewPINDialog();
                    return;
                } else if (!this.newPINTyped && this.newPINValue === 'ready') {
                    this.newPINValue = this.input;
                    this.newPINTyped = true;
                    this.input = '';
                    this.showNewPINDialog();
                    return;
                } else if (this.newPINTyped) {
                    if (this.newPINValue === this.input) {
                        this.pinLockService.setupPin(this.newPINValue);
                        this.newPINTyped = false;
                        this.newPINValue = '';
                        this.pinLockService.pinChange = false;
                        this.showPINChangeConfirmation();
                    } else {
                        this.input = '';
                        this.showErrorPINMsg();
                    }
                }
            }

            if (!this.pinLockService.validatePin(this.input) && this.newPINValue === '') {
                let lockTime = 0;
                if (++this.wrongPinEnteredCount >= 3) {
                    lockTime = (this.wrongPinEnteredCount - 3) * 30 + 15;
                    this.lockPinForm(lockTime);
                }

                this.alertController.create({
                    subHeader: 'Falsche Eingabe!',
                    message: lockTime === 0 ?
                        'Deine eingegebene PIN ist nicht korrekt. Bitte versuche es erneut.' :
                        `Du hast zu oft eine falsche PIN eingegeben! Die Eingabe ist deshalb für ${lockTime} Sekunden gesperrt.`,
                    buttons: [
                        { text: 'Okay', role: 'cancel' },
                        {
                            text: 'PIN vergessen', handler: async () => {
                                this.alertController.create({
                                    subHeader: 'PIN vergessen',
                                    message: '1. Möchtest Du Deine PIN zurücksetzen? <br /> <br />2. Hast Du von Deinem Coach/Berater eine PUK erhalten?',
                                    buttons: [
                                        {
                                            text: '2. PUK eingeben', handler: async () => {
                                                this.enterPuk();
                                            }
                                        },
                                        {
                                            text: '1. PIN zurücksetzen', handler: async () => {
                                                await this.pinLockService.resetPIN();
                                                this.showPINResetInformationMsg();
                                            }
                                        }
                                    ]
                                }).then(alert => alert.present());
                            }
                        }
                    ]
                }).then(alert => {
                    alert.present();
                    this.input = '';
                })
            }
        } else {
            this.pinLockService.setupPin(this.input);

            if (this.trialService.isTrial()) {
                this.alertController.create({
                    subHeader: 'Achtung!',
                    message: `Die PIN kann während der Testphase NICHT wiederhergestellt werden. Wenn Du sie vergisst, muss Du die App neu installieren und Deine Daten sind verloren. Sobald Du an DE-RENA teilnimmst und mit dem Coach verbunden bist, kann dieser die PIN im Notfall zurücksetzen.`,
                    buttons: ['Okay']
                }).then(async alert => {
                    alert.present();
                    this.input = '';
                });
            } else {
                this.alertController.create({
                    subHeader: 'Willkommen!',
                    message: `Deine PIN "${this.input}" wurde erfolgreich gespeichert. DE-RENA wird Dich nach Deiner PIN fragen, wenn Du länger nicht mehr angemeldet warst.`,
                    buttons: ['Okay']
                }).then(alert => {
                    alert.present();
                    this.input = '';
                });
            }
        }
    }

    backspace() {
        this.deviceFeedbackService.tap();

        if (this.input.length === 0)
            return;

        this.input = this.input.substr(0, this.input.length - 1);
    }

    clear() {
        this.deviceFeedbackService.tap();
        this.input = '';
    }

    enterDigit(digit: number) {
        this.deviceFeedbackService.tap();

        if (this.lockTimer > 0) {
            this.alertController.create({
                message: `Die Eingabe ist aus Sicherheitsgründen noch ${this.lockTimer} Sekunden gesperrt.`,
                buttons: ['Okay']
            }).then(alert => {
                alert.present();
                this.input = '';
            });
            return;
        }

        if (this.input.length < 4) {
            this.input = `${this.input}${digit}`;
            if (this.input.length === 4)
                this.submit();
        } else this.input = '';
    }

    showSetupDialog() {
        this.alertController.create({
            subHeader: 'Willkommen bei DE-RENA!',
            message: 'Bitte lege eine 4-stellige PIN fest, um Deine Daten vor Fremden zu schützen.',
            buttons: ['Verstanden']
        }).then(alert => alert.present());
    }

    showPINChangeDialog() {
        this.alertController.create({
            message: 'Bitte gib Deine PIN ein.',
            buttons: ['Verstanden']
        }).then(alert => alert.present());
    }
    showNewPINDialog() {
        this.alertController.create({
            message: this.newPINTyped ? 'Bitte bestätige Deine neue PIN' : 'Bitte gib Deine neue PIN ein.',
            buttons: ['Verstanden']
        }).then(alert => alert.present());
    }

    showPINChangeConfirmation() {
        this.alertController.create({
            message: 'Deine neue PIN wurde gespeichert!',
            buttons: ['Verstanden']
        }).then(alert => alert.present());
    }

    showErrorPINMsg() {
        this.alertController.create({
            message: 'Deine neue PIN ist falsch, bitte versuche es erneut.',
            buttons: ['Verstanden']
        }).then(alert => alert.present());
    }

    showPINResetInformationMsg() {
        this.alertController.create({
            message: 'Dein Coach/Berater wurde informiert. Er wird Dir eine PUK ausstellen, mit der Du eine neue PIN festlegen kannst.',
            buttons: ['Verstanden']
        }).then(alert => alert.present());
    }

    delay(seconds: number) {
        return new Promise<void>(resolve => {
            setTimeout(() => resolve(), seconds * 1000)
        })
    }

    async lockPinForm(seconds: number) {
        this.lockTimer = seconds;
        for (let i = 0; i < seconds; ++i) {
            await this.delay(1);
            this.lockTimer--;
        }

        this.lockTimer = 0;

        const alert = await this.alertController.create({
            subHeader: 'Sperre abgelaufen!',
            message: 'PIN-Eingabe ist nun wieder möglich.',
            buttons: ['Okay']
        });
        alert.present();
    }

    ngOnInit() {
        this.input = '';
        this.wrongPinEnteredCount = 0;
        this.lockTimer = 0;

        if (!this.pinLockService.hasPin()) {
            this.showPINDescription = true;
            this.showSetupDialog();
        }

        if (this.pinLockService.pinChange) {
            this.showPINDescription = true;
            this.showPINChangeDialog();
        }
    }
    showErrorPUKMsg() {
        this.alertController.create({
            message: 'Deine PUK darf nicht leer sein, bitte versuche es erneut.',
            buttons: [{ text: 'Verstanden', handler: () => this.enterPuk() }]
        }).then(alert => alert.present());
    }

    showErrorPukValidationMsg() {
        this.alertController.create({
            message: 'Deine PUK ist nicht korrekt, bitte versuche es erneut.',
            buttons: [
                { text: 'Abbrechen', role: 'cancel' },
                { text: 'Verstanden', handler: () => this.enterPuk() }
            ]
        }).then(alert => alert.present());
    }

    enterPuk() {
        this.alertController.create({
            subHeader: 'PUK eingeben',
            inputs: [
                {
                    name: 'input',
                    type: 'text',
                    placeholder: 'Bitte gib Deine PUK ein.'
                }
            ],
            buttons: [
                { text: 'Abbrechen', role: 'cancel' },
                {
                    text: 'Bestätigen', handler: async (value) => {
                        if (!value.input || value.input.length !== 8) {
                            this.showErrorPUKMsg();
                            return;
                        }
                        let res = await this.pinLockService.validatePuk(value.input);
                        if (!res) {
                            this.showErrorPukValidationMsg();
                            return;
                        }
                        this.showSetupDialog();
                    }
                }
            ]
        }).then(async (alert) => await alert.present());
    }
}
