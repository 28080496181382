import { Localization } from '../localization';
import { languages } from '../models/languages';

export const german: Localization = {
    language: languages.de,
    keys: {
        back: 'Zurück',
        today: 'Heute',
        no_timeframe: 'Zeitfenster nicht umgesetzt',
        no_timeframe_reason: 'Gib einen Grund an, um später zu wissen, weshalb Du dieses Zeitfenster nicht realisiert hast.',
        reason: 'Grund',
        get_from_base_week: 'Aus Musterwoche übernehmen',
        leave_app: 'Zum Verlassen der App nochmals drücken.',
        delete_timeframe: 'Soll dieser Zeitfenster wirklich gelöscht werden?',
        change: 'Ändern',
        continue: 'Weiter',
        cant_edit_timeframe: 'Dieses Zeitfenster kann nicht bearbeitet werden, da es bereits abgeschlossen und bewertet wurde.',
        reasoning: 'Begründung',
        remedy: 'Abhilfe',
        no_timeframe_2: 'Dieses Zeitfenster wurde nicht umgesetzt.',
        implemented: 'Umgesetzt',
        not_implemented: 'Nicht umgesetzt',
        edit: 'Bearbeiten',
        open_evaluation: 'Bewertung öffnen',
        nothing_planned: 'Für diesen Tag wurde nichts geplant.',
        choose_trial: 'Wähle die Test-Option, wenn Du DE-RENA 14 Tage testen möchtest.',
        try_for_days: '14 Tage testen',
        or: 'oder',
        scan_code: 'Scanne den Zugangscode, den Du von Deinem Coach erhalten hast. Klicke hierzu auf das kleine QR-Code Symbol rechts',
        accesscode: 'Zugangscode',
        login_with_code: 'Mit Code anmelden',
        basedate: 'Stammdaten',
        lifesections: 'Lebensbereiche',
        baseweek: 'Stammdaten',
        organization: 'Organisation',
        calendar: 'Kalender',
        my_day: 'Mein Tag',
        dayevaluation: 'Tagesbewertung',
        exercises: 'Übungen',
        evaluation: 'Auswertung',
        lifesection_spread: 'Lebensbereichverteilung',
        condition: 'Befinden',
        depression: 'Depressivität',
        balance: 'Balance',
        help_n_more: 'Hilfe und Weiteres',
        videos: 'Erklärvideos',
        final_survey: 'Abschlussfragebogen',
        content: 'Inhalte',
        appearance: 'Erscheinungsbild',
        use_stones: 'Stolpersteine verwenden',
        use_intents: 'Vorsätze verwenden',
        user_data: 'Teilnehmerdaten',
        user_id: 'Teilnehmernummer',
        edit_pin: 'PIN ändern',
        edit_soz: 'Soziodemographische Daten ändern',
        care_data_date: 'Stand Schulungsunterlagen',
        app_info: 'App-Informationen',
        datasync: 'Datensynchronisierung',
        search_updates: 'Nach Updates suchen',
        send_techdoc: 'Technischen Bericht senden',
        version: 'Version',
        about_derena: 'Über DE-RENA',
        privacy_pol: 'Datenschutzbestimmungen',
        ce_med: 'CE-Medizinprodukt',
        has_newest_version: 'Du hast bereits die neuste Version.',
        sending_report: 'Bericht wird gesendet.',
        send_tech_report: 'Möchtest du einen technischen Bericht über den Zustand deiner App an das Entwickler Team senden?',
        send: 'Senden',
        report_was: 'Bericht wurde gesendet.',
        error_occurred: 'Ein Fehler ist aufgetreten! Bitte versuche es später noch einmal.',
        cancel: 'Abbrechen',
        delete_all_data: 'Bist Du Dir sicher, dass alle App Daten gelöscht werden sollen?',
        reset_data: 'Daten zurücksetzen',
        settings: 'Einstellungen',
        connect: 'Verbinden',
        you_are_with: 'Du bist mit der',
        connected: 'verbunden',
        switch_clinic:
            'Wenn Du Dich mit einer anderen Klinik verbinden möchtest, dann scanne den Zugangscode und drücke den Verbinden Button.',
        enter_code: 'Gib Deinen Teilnehmer Zugangscode ein, damit die Auswertungsergebnisse auch Deinem Coach zur Verfügung stehen.',
        connect_now: 'Jetzt verbinden',
        should_lifesection: 'Soll der Lebensbereich',
        really_delete: 'wirklich gelöscht werden?',
        new_stone: 'Neuer Stolperstein',
        description: 'Bezeichnung',
        need_stones_res: 'Stolperstein und Abhilfe müssen angegeben werden.',
        need_resolution: 'Abhilfe darf nicht leer sein.',
        edit_stones_res: 'Stolperstein und Abhilfe ändern',
        save: 'Speichern',
        field_is_empty: 'Das Feld darf nicht leer sein! Bitte füllen Sie das Feld aus.',
        understood: 'Verstanden',
        lifesection_title: 'Lebensbereich Titel',
        abbreviation: 'Kürzel',
        calendar_notifications: 'Kalender Benachrichtigungen',
        rate_lifesection: 'Lebensbereich bewerten',
        sync_data: 'Daten synchronisieren',
        new_intent: 'Neuer Vorsatz',
        intent_is_empty: 'Vorsatz darf nicht leer sein!',
        change_intent: 'Vorsatz ändern',
        need_term: 'Bezeichnung" darf nicht leer sein und muss aus mindestens drei Zeichen bestehen!',
        term_too_long: 'Bezeichnung" darf nicht mehr als 24 Zeichen lang sein!',
        choose_color: 'Bitte wähle eine Farbe aus!',
        delete: 'Löschen',
        rename: 'Umbenennen',
        duplicate: 'Duplizieren',
        duplicating_week: 'Musterwoche wird dupliziert…',
        rename_week: 'Musterwoche umbenennen',
        duplicate_week: 'Musterwoche duplizieren',
        term_too_short: 'Bezeichnung muss mindestens 3 Zeichen lang sein.',
        term_too_long_2: 'Bezeichnung darf nicht mehr als 20 Zeichen lang sein.',
        duplicated_term: 'Bezeichnung ist bereits vergeben',
        saved_changes: 'Änderung gespeichert',
        cante_delete_active_week: 'Eine aktive Musterwoche kann nicht gelöscht werden.',
        are_you_sure: 'Bist Du Dir sicher?',
        was_deleted: 'wurde gelöscht.',
        new_template: 'Neue Vorlage',
        copying_timeframes: 'Zeitfenster werden kopiert…',
        add_week: 'Musterwoche hinzufügen',
        is_now_active: 'ist nun aktiv',
        create_new_week: 'Neue Musterwoche anlegen',
        term_too_short_2: 'Die Bezeichnung muss mindestens 3 Zeichen lang sein.',
        term_too_long_3: 'Die Bezeichnung darf nicht mehr als 20 Zeichen lang sein.',
        duplicated_term_2: 'Diese Bezeichnung ist bereits vergeben',
        new_week_created: 'Neue Musterwoche wurde erstellt',
        week_weekdays: 'Musterwoche Wochentage',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
        active_week: 'Aktive Musterwoche',
        switch_week: 'Musterwoche wechseln',
        should_the_week: 'Soll die Musterwoche',
        really_from: 'wirklich ab dem',
        set_as_standard: 'als Standard gesetzt werden?',
        creating_calendar_entries: 'Kalendereinträge werden erstellt',
        timeframe: 'Zeitfenster',
        task: 'Tätigkeiten',
        intent: 'Vorsatz',
        stones_and_res: 'Stolperstein & Bewältigung',
        task_not_saved: 'Achtung! Du hast Deine Tätigkeit nicht gespeichert. Möchtest Du wirklich fortfahren?',
        yes: 'Ja',
        no: 'Nein',
        discard_changes: 'Möchtest Du wirklich alle Änderungen verwerfen?',
        discard: 'Verwerfen',
        rating: 'Bewertung',
        opened: 'Offene',
        yesterday: 'Gestern',
        rating_was_saved: 'Deine heutige Bewertung wurde gespeichert! Möchtest Du jetzt den morgigen Tag planen?',
        rating_not_saved: 'Deine Tagesbewertung wurde noch nicht gespeichert. Möchtest Du Deine Änderungen verwerfen und fortfahren?',
        no_open_rating: 'Keine offenen Bewertungen vorhanden.',
        satisfaction: 'Zufriedenheit',
        how_satisfied: 'Wie zufrieden bist Du mit Deiner Balance an diesem Tag?',
        how_was_wellbeing: 'Wie hast Du Dich insgesamt an diesem Tag gefühlt?',
        day_not_rated: 'Dieser Tag wurde von Dir nicht bewertet.',
        cant_rate_day_yet: 'Du kannst den heutigen Tag noch nicht bewerten.',
        try_again_later: 'Versuche es später noch einmal.',
        refreshing: 'Aktualisieren…',
        connection_error: 'Verbindungsfehler, bitte versuchen Sie es später erneut',
        download: 'Herunterladen',
        playback_speed: 'Wiedergabegeschwindigkeit',
        my_lifesections_for: 'Meine Lebensbereichverteilung für den',
        outer_circle: 'Äußeren Kreis: Zeitfensterplannung',
        inner_circle: 'Inneren Kreis: Zeitfensterumsetzung',
        click_circle_info: 'Mehr Infos: Äußeren und inneren Kreis anklicken.',
        your_section_spread: 'Deine Lebensbereichverteilung',
        this_graphic_shows: 'Diese Grafik zeigt Dir für',
        the_section_spread: 'die Verteilung Deiner Lebensbereiche an.',
        lifesection_balance: 'Lebensbereich Balance',
        the_upper_bar:
            'Der obere Balken zeigt die geplante Zeit eines Lebensbereiches aus der Musterwoche an, der mittlere zeigt geplante Zeit im Kalender an und der untere zeigt die umgesetzte Zeit an. Unten auf der Seite kann der Zeitraum der Anzeige gewählt werden.',
        need_start_before_end: 'Der Start muss vor dem Ende liegen.',
        loading_lifesection_balance: 'Lebensbereich Balance wird geladen…',
        your_section_wellbeing: 'Dein Befinden in Lebensbereichen im gewählten Zeitraum',
        this_graphic_shows_timeframe: 'Diese Grafik zeigt für den Zeitraum vom',
        till: 'bis',
        click_the_color: 'Deine Befindenswerte. Klicke auf die Farben in der Legende, um Linien ein- und auszublenden.',
        day_wellbeing: 'Tagesbefinden',
        satisfaction_with_balance: 'Zufriedenheit mit Balance',
        this_graphic_phq: 'Diese Grafik zeigt dir die Ergebnisse deiner PHQ-9 Antworten an.',
        help_videos: 'Hilfe-Videos',
    },
};
