import {FeatureHandler, MetaFeature} from 'calendar/feature-events/meta-feature';
import {Injectable} from '@angular/core';
import {ActionSheetController, NavController} from '@ionic/angular';

export interface InternalRouteFeatureOptions {
    route: string;
    params?: string[];
    dialog?: {
        label: string;
        icon: string;
        showCancel: boolean;
    }
}

@Injectable({
    providedIn: 'root'
})
export class InternalRouteFeature implements FeatureHandler<InternalRouteFeatureOptions> {
    name: string = 'internal-route';

    constructor(private navController: NavController, private actionSheetController: ActionSheetController) { }

    private navigate(route: string, params: string[]) {
        this.navController.navigateForward([route, ...params]);
    }

    private async showActionSheet(options: InternalRouteFeatureOptions) {
        const buttons = [];
        buttons.push({
            text: options.dialog.label,
            icon: options.dialog.icon,
            handler: () => { this.navigate(options.route, options.params || []) }
        });

        if (options.dialog.showCancel) {
            buttons.push({
                text: 'Abbrechen',
                role: 'cancel',
                icon: 'close'
            });
        }

        let sheet = await this.actionSheetController.create({
            buttons: buttons,
            animated: true
        });
        sheet.present();
    }

    handle(meta: MetaFeature<InternalRouteFeatureOptions>): boolean {
        if (!meta.options.route)
            return true;

        const withDialog = !!meta.options.dialog;
        if (withDialog) {
            this.showActionSheet(meta.options);
        } else {
            this.navigate(meta.options.route, meta.options.params || []);
        }

        return true;
    }
}
