import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { reorderArray } from '@library/core-models/src/reorderable.model';
import { ResolutionModel } from '@library/core-models/src/resolution.model';
import { LifeSectionValidation } from '@library/plugin-life-section/src/lib/life-section.validation';
import { ValueRequiredValidator } from '@core/validation';
import { AlertController } from '@ionic/angular';

@Component({
    selector: 'plugin-life-section-resolution',
    templateUrl: './life-section-resolution.component.html'
})
export class LifeSectionResolutionComponent implements OnInit {
    @Output() itemsChange = new EventEmitter<ResolutionModel[]>();
    @Input() get items(): ResolutionModel[] { return this.itemsValue }

    itemsValue: ResolutionModel[];
    item: string;

    constructor(private validation: LifeSectionValidation, private alertController: AlertController) { }

    set items(val: ResolutionModel[]) {
        this.itemsValue = val;
        this.itemsChange.emit(this.itemsValue);
    }

    reorder(e) {
        this.items = reorderArray(this.items, e.detail['from'], e.detail['to']);
        e.detail.complete();
    }

    add() {
        let valid = this.validation.validateSingle(this.item, new ValueRequiredValidator(), 'Vorsatz darf nicht leer sein!');
        if (!valid)
            return;

        this.items.forEach(x => x.index++);

        let result = [];
        result.push({ text: this.item, index: 0 });
        result.push(...this.items);
        this.items = result;
        this.item = '';
    }

    delete(idx) {
        let result = this.items.filter(x => x.index !== idx);

        let index = 0;
        result.forEach(x => x.index = index++);
        this.items = result;
    }

    edit(idx) {
        this.alertController.create({
            header: 'Vorsatz ändern',
            inputs: [
                {
                    name: 'input',
                    type: 'text',
                    placeholder: 'Neuer Vorsatz',
                    value: this.items[idx].text
                }
            ],
            buttons: [
                { text: 'Abbrechen', role: 'cancel' },
                {
                    text: 'Speichern',
                    handler: (value) => {
                        if (!value || !value.input) {
                            this.openEditErrorAlert(idx);
                            return;
                        }
                        this.items[idx].text = value.input;
                    }
                }
            ]
        }).then(async alert => await alert.present());
    }

    openEditErrorAlert(idx) {
        this.alertController.create({
            message: 'Das Feld darf nicht leer sein! Bitte füllen Sie das Feld aus.',
            buttons: [
                { text: 'Verstanden', role: 'cancel' },
            ]
        }).then(async (alert) => {
            await alert.present();
            alert.onDidDismiss().then(() => {
                this.edit(idx);
            });
        });
    }

    ngOnInit() { }
}
