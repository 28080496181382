import { Injectable } from '@angular/core';
import { DatabaseCollectionProvider } from '@core/database';
import { RxCollection } from 'rxdb';
import { DATABASE_COLLECTIONS } from '@library/core-models/src/schema';
import { SurveyModel, SurveyLabelsModel } from '@core/models';
import { DatabaseService } from '@core/database';
import { StatsSummaryService } from '../../stats-summary/stats-summary.service';
import { AppSettings } from '@library/plugin-settings/src/public_api';
import { DeviceNotificationService } from '@library/plugin-device-notification/src/public_api';
import { SurveyEventService } from '@library/plugin-survey/src/lib/services/survey-event.service';
import { LoggingService } from '../../logging.service';
import { Severity } from 'sentry-cordova';
import { Moment } from 'moment';
import moment from 'moment';
import { DayEvaluationEventService } from '@library/plugin-day-evaluation/src/lib/services/day-evaluation-event.service';
import { LocalNotifications } from '@capacitor/local-notifications';

export enum SurveyState {
    Available = 0,
    AlreadyDone = 1,
    Expired = 2,
    AvailableInFuture = 3
}

const DEFAULT_SURVEY_LABELS: SurveyLabelsModel[] = [
    {
        menu_item_title: 'Depressivität',
        statistic_title: 'PHQ-9 Auswertung',
        statistic_description: 'Diese Grafik zeigt Dir die Ergebnisse Deiner PHQ-9 Antworten an.',
        statistic_graph_name: 'Depressivität',
        statistic_graph_color: '#0066CC',
        statistic_max_ticks: 27,
        survey_name: 'PHQ-9',
        stateball_good_label: 'Gesamtwert der PHQ-9: Minimale Symptomatik',
        stateball_medium_label: 'Gesamtwert der PHQ-9: Milde Symptomatik',
        stateball_bad_label: 'Gesamtwert der PHQ-9: Mittelgradige Symptomatik',
        stateball_critical_label: 'Gesamtwert der PHQ-9: Schwere depressive Symptomatik',
        stateball_good_max_value: 4,
        stateball_medium_max_value: 9,
        stateball_bad_max_value: 14,
        stateball_critical_max_value: 27
    },
    {
        menu_item_title: 'Erschöpfung',
        statistic_title: 'MFI-5 Auswertung',
        statistic_description: 'Diese Grafik zeigt Dir die Ergebnisse Deiner MFI-5 Antworten an.',
        statistic_graph_name: 'Erschöpfung',
        statistic_graph_color: '#0066CC',
        statistic_max_ticks: 20,
        survey_name: 'MFI-5',
        stateball_good_label: 'Gesamtwert der MFI-5: Minimale Symptomatik',
        stateball_medium_label: 'Gesamtwert der MFI-5: Milde Symptomatik',
        stateball_bad_label: 'Gesamtwert der MFI-5: Mittelgradige Symptomatik',
        stateball_critical_label: 'Gesamtwert der MFI-5: Schwere depressive Symptomatik',
        stateball_good_max_value: 4,
        stateball_medium_max_value: 8,
        stateball_bad_max_value: 12,
        stateball_critical_max_value: 20
    }
];


@Injectable({ providedIn: 'root' })
export class SurveyProviderService {
    private surveyCollection: RxCollection;
    private resultCollection: RxCollection;
    private ready = false;

    constructor(private collectionProvider: DatabaseCollectionProvider,
        private databaseService: DatabaseService,
        private statsUpdater: StatsSummaryService,
        private settings: AppSettings,
        private notificationService: DeviceNotificationService,
        private surveyService: SurveyEventService,
        private loggingService: LoggingService,
        private dayEvaluationEventService: DayEvaluationEventService) {
        this.init();
    }

    private async init() {
        await this.databaseService.databaseAwaiter();
        this.surveyCollection = this.collectionProvider.use<any>(DATABASE_COLLECTIONS.SurveyCollection);
        this.resultCollection = this.collectionProvider.use<any>(DATABASE_COLLECTIONS.SurveyResultCollection);
        this.ready = true;
    }

    async getCurrentSurvey(name: string): Promise<SurveyModel> {
        if (!this.ready) {
            await this.init();
        }
        const doc = await this.surveyCollection.findOne(name).exec();
        if (!doc)
            return null;

        return doc.toJSON();
    }

    async getSurveyState(survey: SurveyModel, date: Moment): Promise<SurveyState> {
        if (!this.ready) {
            await this.init();
        }
        const today = moment().startOf('day');
        if (today.isBefore(date, 'day'))
            return SurveyState.AvailableInFuture;

        const docId = `${survey.key}-${date.startOf('day').unix()}`;
        const document = await this.resultCollection.findOne(docId).exec();
        if (!!document)
            return SurveyState.AlreadyDone;

        return SurveyState.Available;
    }

    async saveSurveyResult(survey: SurveyModel, date: Moment, result: number[], additionalSurveyQuestionsResult?: number[]) {
        if (!this.ready) {
            await this.init();
        }
        const docId = `${survey.key}-${date.startOf('day').unix()}`;
        let surveyEvaluationType;
        if (survey.labels && survey.labels.survey_name) {
            surveyEvaluationType = survey.labels.survey_name;
        } else {
            const index = DEFAULT_SURVEY_LABELS.findIndex(defaultSurveyLabels => survey.title.includes(defaultSurveyLabels.survey_name));
            if (index !== -1) {
                surveyEvaluationType = DEFAULT_SURVEY_LABELS[index].survey_name;
            } else {
                surveyEvaluationType = DEFAULT_SURVEY_LABELS[0].survey_name;
            }
        }

        if (additionalSurveyQuestionsResult && additionalSurveyQuestionsResult.length) {
            try {
                if (additionalSurveyQuestionsResult.length > 2) {
                    additionalSurveyQuestionsResult.splice(2)
                }
                this.settings.set(docId, additionalSurveyQuestionsResult);
            } catch (err) {
                this.loggingService.write('error saving ssrs answers ', Severity.Critical);
            }
        }

        await this.resultCollection.upsert({
            key: docId,
            surveyKey: survey.key,
            surveyEvaluationType: surveyEvaluationType,
            surveyDate: date.startOf('day').toISOString(),
            submitDate: moment().toISOString(),
            answers: result
        });

        await this.statsUpdater.updateLastPhq9();

        try {
            await this.removeSurveyNotifications();
            await this.createSurveyNotifications();
        } catch (e) {
            this.loggingService.write('error creating survey notifications', Severity.Critical);
            console.warn('error creating survey notifications >', e);
        }
    }

    async getLastSurveyResult() {
        if (!this.ready) {
            await this.init();
        }
        return (await this.resultCollection.find().exec());
    }

    async getSurveyLabels(name?: string): Promise<SurveyLabelsModel> {
        if (!this.ready) {
            await this.init();
        }
        const surveyDoc = name ? await this.surveyCollection.findOne(name).exec() : await this.surveyCollection.findOne().exec();

        if (!surveyDoc) {
            return null;
        }
        const survey: SurveyModel = surveyDoc.toJSON();
        if (!survey.labels) {
            const index = DEFAULT_SURVEY_LABELS.findIndex(defaultSurveyLabels => survey.title.includes(defaultSurveyLabels.survey_name));
            if (index !== -1) {
                return DEFAULT_SURVEY_LABELS[index];
            } else {
                return DEFAULT_SURVEY_LABELS[0];
            }
        }

        return survey.labels;
    }

    async createSurveyNotifications() {
        !this.notificationService.initialized ? await this.notificationService.setup() : undefined;

        if (this.notificationService.supported && this.notificationService.initialized) {
            const events = await this.surveyService.getEvents(moment().startOf('day'), moment().add(1, 'month').endOf('day'));

            if (!events || !events.length) {
                return;
            }

            const start = moment(events[0].start).hours(13).minutes(0).seconds(0);
            let notificationIds = [];
            let notificationDates = [];

            await this.settings.awaiter();
            let notificationTime = this.settings.get('notification-time');
            if (notificationTime) {
                let hours = moment(notificationTime).get('hours');
                let minutes = moment(notificationTime).get('minutes');
                start.hours(hours);
                start.minutes(minutes);
            }

            for (let i = 1; i <= 14; i++) {
                let date = moment(start).add(i, 'days');
                let id = this.notificationService.schedule('DE-RENA Fragebogen', 'Bitte füllen Sie den Fragebogen aus.', true, date);
                notificationIds.push(id);
                notificationDates.push(date.toISOString());
            }

            this.settings.set('survey-notifications', notificationIds);
            this.settings.set('survey-notification-dates', notificationDates);

            setTimeout(async () => {
                await this.notificationChecker();
            }, 1000);
        }
    }

    async shouldRecreateSurveyNotifications() {
        const dates: string[] = this.settings.get('survey-notification-dates');
        if (dates && dates.length) {
            if (moment(dates[0]).isBefore(moment(), 'days')) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }

    async removeSurveyNotifications() {
        !this.notificationService.initialized ? await this.notificationService.setup() : undefined;

        if (this.notificationService.supported && this.notificationService.initialized) {
            const ids = this.settings.get('survey-notifications');
            if (!ids || !ids.length) {
                return;
            }

            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                await this.notificationService.remove(id);
            }

            this.settings.set('survey-notifications', []);
            this.settings.set('survey-notification-dates', []);
        }
    }

    private async notificationChecker() {
        await this.settings.awaiter();

        const allNotifications = await LocalNotifications.getPending();
        const ids: number[] = this.settings.get('survey-notifications');

        if (ids && allNotifications.notifications) {
            let filteredNotifications = allNotifications.notifications.filter(n => n.title === 'DE-RENA Fragebogen');
            for(let i = 0; i < filteredNotifications.length; i++) {
                let x = filteredNotifications[i];
                let savedId = ids.find(v => v === x.id);
                if (!savedId) {
                    await this.notificationService.remove(x.id);
                }
            }
        }
    }

    async createDayEvaluationNotifications() {
        let includeToday = false;
        const openEvaluations = await this.dayEvaluationEventService.getEvents(moment().startOf('day'), moment().endOf('day'));

        if(openEvaluations) {
            openEvaluations.find(e => e.meta.options.route === '/evaluate-day') ? includeToday = true : includeToday = false;
        }

        !this.notificationService.initialized ? await this.notificationService.setup() : undefined;

        if (this.notificationService.supported && this.notificationService.initialized) {
            const start = moment().hours(19).minutes(0).seconds(0);
            let notifications = [];

            await this.settings.awaiter();
            let notificationTime = this.settings.get('day-evaluation-notification-time');
            if (notificationTime) {
                start.hours(moment(notificationTime).hours());
                start.minutes(moment(notificationTime).minutes());
            }

            let iterator: number;
            includeToday ? iterator = 0 : iterator = 1;
            for (let i = iterator; i <= 14; i++) {
                let date = moment(start).add(i, 'days');
                let id = this.notificationService.schedule('DE-RENA Tagesbewertung', 'Bitte bewerten Sie Ihren Tag in der DE-RENA App.', true, date);
                notifications.push({id: id, date: date.toISOString()});
            }

            this.settings.set('day-evaluation-notifications', notifications);
        }
    }

    async removeDayEvaluationNotifications() {
        !this.notificationService.initialized ? await this.notificationService.setup() : undefined;

        if (this.notificationService.supported && this.notificationService.initialized) {
            const allNotifications = await LocalNotifications.getPending();

            if (allNotifications && allNotifications.notifications.length) {
                let filteredNotifications = allNotifications.notifications.filter(n => n.title === 'DE-RENA Tagesbewertung');
                for(let i = 0; i < filteredNotifications.length; i++) {
                    await this.notificationService.remove(filteredNotifications[i].id);
                }
            }
            this.settings.set('day-evaluation-notifications', []);
        }
    }

    async removeTodaysDayEvaluationNotification() {
        !this.notificationService.initialized ? await this.notificationService.setup() : undefined;

        if (this.notificationService.supported && this.notificationService.initialized) {
            let notifications: any[] = this.settings.get('day-evaluation-notifications');
            if (!notifications || !notifications.length) {
                return;
            }

            let todaysNotification = notifications.find(n => moment(n.date).isSame(moment(), 'day'));
            if(todaysNotification) {
                await this.notificationService.remove(todaysNotification.id);
                notifications = notifications.filter(e => e.id !== todaysNotification.id);
                this.settings.set('day-evaluation-notifications', notifications);
            }
        }
    }
}
