import {DatabaseRepositoryInterface, DbContext, DocumentChange} from '@core/database';
import {Observable, Subject} from 'rxjs';
import {CollectionsDefinitions} from '@library/core-database/src/lib/definitions';
import {RxCollection, RxDatabase, createRxDatabase as openDatabase, addRxPlugin as registerPlugin} from 'rxdb';
import AdapterIndexedDb from 'pouchdb-adapter-idb';
import {DATABASE_MODEL_SCHEMA} from '@library/core-models/src/schema';

registerPlugin(AdapterIndexedDb);

export class RxDbRepository implements DatabaseRepositoryInterface {
    settings: any;
    db: RxDatabase;
    collections: RxCollection[];
    collectionsDef: CollectionsDefinitions;
    documentChangeStream: Subject<DocumentChange<any>> = new Subject();

    constructor() {
        this.collectionsDef = DATABASE_MODEL_SCHEMA;
    }

    private async database(): Promise<RxDatabase> {
        if (!this.db)
            this.db = await openDatabase({ name: 'derena-app-db', adapter: 'idb' });
        return this.db;
    }

    changes<T>(modelName?: string): Observable<DocumentChange<T>> {
        return this.documentChangeStream.asObservable();
    }

    getDriver(): PouchDB.Database {
        return undefined;
    }

    initSync(): Promise<DatabaseRepositoryInterface> {
        return undefined;
    }

    installSync(replicationUrl: string): Promise<void> {
        return undefined;
    }

    isSyncInstalled(): Promise<boolean> {
        return undefined;
    }

    use<Model>(model: string): DbContext<Model> {
        if (!this.collectionsDef[model]) {
            throw new Error(`model ${model} does not exist! check DATABASE_MODEL_SCHEMA`);
        }

        return undefined;
    }
}
