import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export const DEFAULT_RESOLUTION_RATING: number = 4;

@Component({
    selector: 'plugin-resolution-rating',
    templateUrl: './resolution-rating.component.html',
    styleUrls: ['./resolution-rating.component.scss']
})
export class ResolutionRatingComponent implements OnInit {
    @Input() readOnly: boolean;
    @Input() text: string;

    ratingValue: number;
    @Input() get rating(): number { return this.ratingValue };
    @Output() ratingChange: EventEmitter<number> = new EventEmitter();
    set rating(value: number) {
        this.ratingValue = value;
        this.ratingChange.emit(value);
    }

    ngOnInit() {
        if (!this.ratingValue)
            this.ratingValue = DEFAULT_RESOLUTION_RATING;
    }
}
