import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {MatDatepicker} from '@angular/material/datepicker';
import {RecurrenceFrequency, RecurrenceWeekDay} from 'calendar/models/recurrence.model';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { GermanDateAdapter } from '../services/german-date-adapter';

@Component({
    selector: 'plugin-calendar-event',
    templateUrl: './calendar-event.component.html',
    styleUrls: ['./calendar-event.component.scss'],
    providers: [ { provide: DateAdapter, useClass: GermanDateAdapter } ]
})
export class CalendarEventComponent implements OnInit {
    @Input() event: EventDefinition<any>;
    @Input() disableTitle: boolean;
    @Input() disableRecurrence: boolean;
    @Input() disableAllDay: boolean;
    @Input() disableDateChange: boolean;

    @ViewChild('recurrenceEndDateRef', {static: true}) recurrenceEndDateRef: MatDatepicker<Date>;

    startDate: Date;
    endDate: Date;
    recurrenceEndDate: Date;

    startTime: string;
    endTime: string;

    showTimeOptions: boolean;
    recurrenceEndEnabled: boolean;
    recurrenceFrequency: RecurrenceFrequency | 'NONE';

    get start(): Date {
        let date = moment(this.startDate).format('YYYY-MM-DD');
        return moment(`${date}T${this.startTime}:00`).toDate();
    }

    get end(): Date {
        let date = moment(this.endDate).format('YYYY-MM-DD');
        return moment(`${date}T${this.endTime}:00`).toDate();
    }

    ngOnInit() {
        let start = moment(this.event.start);
        let end = moment(this.event.end);

        this.startDate = start.toDate();
        this.endDate = end.toDate();

        this.startTime = start.format('HH:mm');
        this.endTime = end.format('HH:mm');

        this.event.start = new Date(start.toISOString());
        this.event.end = new Date(end.toISOString());

        if (this.event.recurrence) {
            this.recurrenceFrequency = this.event.recurrence.frequency;

            if (this.event.recurrence.until) {
                this.recurrenceEndDate = new Date(moment(this.event.recurrence.until).toISOString());
                this.recurrenceEndEnabled = true;
            } else {
                this.recurrenceEndEnabled = false;
            }
        } else {
            this.recurrenceFrequency = 'NONE';
            this.showTimeOptions = false;
            this.recurrenceEndEnabled = false;
        }
    }

    toggleRecurrenceEnd() {
        if (!this.recurrenceEndEnabled)
            this.recurrenceEndDate = null;
        else {
            setTimeout(() => this.recurrenceEndDateRef.open(), 20);
        }

        this.saveChanges();
    }

    getRecurrenceWeekDay(): RecurrenceWeekDay {
        switch (moment(this.start).weekday()) {
            case 0: return 'SU';
            case 1: return 'MO';
            case 2: return 'TU';
            case 3: return 'WE';
            case 4: return 'TH';
            case 5: return 'FR';
            case 6: return 'SA';
            default: throw new Error('invalid weekday');
        }
    }

    saveChanges(adjustEnd: boolean = false) {
        if (adjustEnd) {
            let duration = moment(this.event.end).diff(moment(this.event.start), 'minutes');
            this.event.start = this.start;
            this.event.end = new Date(moment(this.event.start).add(duration, 'minutes').toISOString());
            this.startTime = moment(this.event.start).format('HH:mm');
            this.endTime = moment(this.event.end).format('HH:mm');
        } else {
            this.event.start = this.start;
            this.event.end = this.end;
        }

        if (!this.event.title && !this.disableTitle){
            this.event.title = null;
        }

        if (this.recurrenceFrequency !== 'NONE') {
            this.event.recurrence = {
                start: moment(this.event.start),
                frequency: this.recurrenceFrequency,
                until: this.recurrenceEndEnabled ? moment(this.recurrenceEndDate).endOf('day') : null,
                byDayOfWeek: this.recurrenceFrequency === 'WEEKLY' ? [this.getRecurrenceWeekDay()] : undefined,
                weekStart: 'SU'
            }
        } else this.event.recurrence = null;
    }
}
