import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {DatabaseCollectionProvider} from '@core/database';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {DayStatsModel, WeekTemplateModel} from '@core/models';
import * as moment from 'moment';
import {Moment} from 'moment';

export const SETTINGS_KEY = 'template-switch-instructions';

export type WeekTemplateSwitchInstruction = {
    timestamp: number;
    templateId: number;
}

@Injectable({ providedIn: 'root' })
export class WeekTemplateSwitchService {
    private instructions: WeekTemplateSwitchInstruction[];
    private ready: boolean;

    constructor(private collectionProvider: DatabaseCollectionProvider,
                private settings: AppSettings) { }

    async init() {
        await this.settings.awaiter();
        //this.settings.set(SETTINGS_KEY, []);
        this.instructions = this.settings.get<WeekTemplateSwitchInstruction[]>(SETTINGS_KEY, []);
    }



    cleanup() {
        const startOfDayTimestamp = moment().startOf('day').unix();
        const cleanedInstructions = this.instructions.filter(x => x.timestamp >= startOfDayTimestamp);
        const activeInstruction = this.getActiveInstruction(moment());
        if (activeInstruction) {
            const current = this.settings.get<number>('active-week-template', 0);
            if (current !== activeInstruction.templateId) {
                console.log(current, '!==', activeInstruction, '=>', activeInstruction.templateId);
                this.settings.set<number>('active-week-template', activeInstruction.templateId);
                cleanedInstructions.push(activeInstruction);
            }
        }
        this.instructions = cleanedInstructions;
        this.save();
    }

    getTemplateForDay(day: Moment): WeekTemplateModel {
        const timestamp = day.startOf('day').unix();
        const activeItem = this.getActiveInstruction(timestamp);
        const defaultTemplateId = activeItem ? activeItem.templateId : this.settings.get<number>('active-week-template', 0);
        const key = `week-template-${defaultTemplateId}`;
        console.log('day', day.toISOString());
        console.log('activeItem', activeItem);
        console.log('defaultTemplateId', defaultTemplateId);
        console.log('key', key);
        return this.settings.get<WeekTemplateModel>(key, null);
    }

    getActiveInstruction(day: Moment | number): WeekTemplateSwitchInstruction {
        const timestamp = typeof day === 'number' ? day : day.startOf('day').unix();
        const affectedItems = this.instructions.filter(x => x.timestamp <= timestamp);
        if (!affectedItems.length)
            return null;
        return affectedItems.sort(a => -a.timestamp)[0];
    }

    getNextInstruction(day: Moment | number): WeekTemplateSwitchInstruction {
        const timestamp = typeof day === 'number' ? day : day.startOf('day').unix();
        const affectedItems = this.instructions.filter(x => x.timestamp >= timestamp);
        if (!affectedItems.length)
            return null;
        return affectedItems.sort(a => a.timestamp)[0];
    }

    async addSwitchInstruction(templateId: number, day: Moment): Promise<void> {
        console.log('addSwitchInstruction', templateId, day.toISOString());
        const timestamp = day.startOf('day').unix();
        const query = this.collectionProvider
            .use<DayStatsModel>(DATABASE_COLLECTIONS.DayStatsCollection)
            .find()
            .where('id')
            .gte(timestamp.toString());

        const nextItem = this.getNextInstruction(timestamp);
        if (nextItem) {
            query.lt(nextItem.timestamp.toString())
        }

        const docs = await query.exec();
        console.log('docs count', docs.length);

        for (let doc of docs) {
            console.log('updating', doc._id, doc.id);
            try {
                await doc.atomicSet('templateGroupIndex', templateId);
            } catch(e) {
                console.log('update failed');
                console.error(e);
            }
        }

        this.instructions = this.instructions.filter(x => x.timestamp !== timestamp);
        this.instructions.push({ timestamp: timestamp, templateId: templateId });

        this.save();
    }

    save() {
        this.settings.set(SETTINGS_KEY, this.instructions);
    }
}
