import {Injectable} from '@angular/core';
import {DatabaseService, DocumentChange} from '@core/database';
import {EventTemplate} from '@library/plugin-event-templates/src/lib/models/event-template';
import {EventTemplateModel, LifeSectionModel, TimeframeModel} from '@core/models';
import {Observable, Subject} from 'rxjs';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {Moment} from 'moment';
import {RxCollection} from 'rxdb';
import * as moment from 'moment';
import uuid from 'uuid/v4';
import Color from 'color';
import {LifeSectionService} from '@plugin/life-section';

@Injectable({
    providedIn: 'root'
})
export class EventTemplateService {
    private subject: Subject<EventTemplate[]> = new Subject();
    private eventTemplateCollection: RxCollection<EventTemplateModel>;
    private eventCollection: RxCollection<EventDefinition>;
    private timeframeCollection: RxCollection<TimeframeModel>;
    private lifeSectionCollection: RxCollection<LifeSectionModel>;
    private lifeSections: LifeSectionModel[];
    private awaiter: Promise<void>;
    private readonly changes: Subject<DocumentChange<EventTemplate>> = new Subject();

    constructor(private databaseService: DatabaseService, private lifeSectionService: LifeSectionService) {
        databaseService.databaseAwaiter().then(db => {
            this.eventTemplateCollection = db.use(DATABASE_COLLECTIONS.EventTemplateCollection);
            this.timeframeCollection = db.use(DATABASE_COLLECTIONS.TimeframeCollection);
            this.eventCollection = db.use(DATABASE_COLLECTIONS.EventCollection);
            this.lifeSectionCollection = db.use(DATABASE_COLLECTIONS.LifeSectionCollection);

            let resolved = false;
            this.awaiter = new Promise(resolve => {
                this.lifeSectionService.getAll().subscribe(x => {
                    this.lifeSections = x;
                    if (!resolved) {
                        resolved = true;
                        resolve();
                    }
                });
            });

            this.lifeSectionCollection.remove$.subscribe(async x => {
                await this.eventTemplateCollection
                    .find()
                    .where('lifeSectionId')
                    .equals(x.documentId)
                    .remove();
            });

            this.eventTemplateCollection.update$.subscribe(x =>
                this.changes.next(<DocumentChange<EventTemplate>>{
                    deleted: false,
                    id: x.documentData.id,
                    fullIndexedId: x.documentData.id,
                    document: (() => {
                        let event = x.documentData;
                        let lifeSection = this.lifeSections.find(x => x.id === event.lifeSectionId);
                        event.title = lifeSection.name;
                        event.start = new Date(this.migrateDate(moment(event.start)).toISOString());
                        event.end = new Date(this.migrateDate(moment(event.end)).toISOString());
                        event.color = {
                            primary: lifeSection.color.code,
                            secondary: Color(lifeSection.color.code).lighten(0.4).rgb().toString()
                        };
                        return new EventTemplate(event)
                    })()
                })
            );

            this.eventTemplateCollection.insert$.subscribe(x =>
                this.changes.next(<DocumentChange<EventTemplate>>{
                    deleted: false,
                    id: x.documentData.id,
                    fullIndexedId: x.documentData.id,
                    document: (() => {
                        let event = x.documentData;
                        let lifeSection = this.lifeSections.find(x => x.id === event.lifeSectionId);
                        event.title = lifeSection.name;
                        event.start = new Date(this.migrateDate(moment(event.start)).toISOString());
                        event.end = new Date(this.migrateDate(moment(event.end)).toISOString());
                        event.color = {
                            primary: lifeSection.color.code,
                            secondary: Color(lifeSection.color.code).lighten(0.4).rgb().toString()
                        };
                        return new EventTemplate(event)
                    })()
                })
            );

            this.eventTemplateCollection.remove$.subscribe(x =>
                this.changes.next(<DocumentChange<EventTemplate>>{
                    deleted: true,
                    id: x.documentId,
                    fullIndexedId: x.documentId,
                    document: null
                })
            );
        });

    }

    async delete(item: EventTemplateModel): Promise<void> {
        await this.eventTemplateCollection.findOne(item.id.toString()).remove();
    }

    async createCalendarEntry(item: EventTemplateModel, date: Moment): Promise<EventDefinition> {
        let start = moment(`${date.format('YYYY-MM-DD')}T${moment(item.start).format('HH:mm:ss')}`);
        let end = moment(`${date.format('YYYY-MM-DD')}T${moment(item.end).format('HH:mm:ss')}`);

        const timeframeId = uuid();
        const eventId = uuid();

        await this.timeframeCollection.insert({
            id: timeframeId,
            eventId: eventId,
            lifeSectionId: item.lifeSectionId,
            start: start.toISOString(),
            end: end.toISOString(),
            tasks: item.tasks || [],
            conflict: item.conflict || null,
            resolution: item.resolution || null,
            canceled: false,
            done: false,
        });

        let event = await this.eventCollection.insert({
            id: eventId,
            title: item.title,
            start: start.toISOString(),
            end: end.toISOString(),
            day: start.unix(),
            recurrence: null,
            resizable: { beforeStart: true, afterEnd: true },
            color: item.color,
            draggable: true,
            allDay: false,
            meta: {
                feature: 'timeframe',
                options: {
                    timeframeId: timeframeId,
                    lifeSectionId: item.lifeSectionId
                }
            },
        });

        return event.toJSON();
    }

    async createCopies(original: EventTemplateModel, days: number[]) {
        const result = [];
        for (let day of days) {
            let target = moment().locale('de').startOf('week').add(day, 'days');
            let start = moment(`${target.format('YYYY-MM-DD')}T${moment(original.start).format('HH:mm:ss')}`);
            let end = moment(`${target.format('YYYY-MM-DD')}T${moment(original.end).format('HH:mm:ss')}`);
            let entryStr = JSON.stringify(original);
            let entry = JSON.parse(entryStr);
            entry._rev = undefined;
            entry.id = undefined;
            entry.start = start.toISOString();
            entry.end = end.toISOString();
            result.push(await this.save(entry));
        }
        console.log('result', result);
    }

    async duplicate(sourceGroupIndex: number, targetGroupIndex: number) {
        const items = await this.eventTemplateCollection.find({ selector: { groupIndex: sourceGroupIndex  } }).exec();
        for (let item of items) {
            console.log('item', item);
            const obj = item.toJSON();
            console.log('obj', obj)
            let entryStr = JSON.stringify(obj);
            let entry = JSON.parse(entryStr);
            entry._rev = undefined;
            entry.id = undefined;
            entry.groupIndex = targetGroupIndex;
            await this.save(entry)
        }
    }

    async save(item: EventTemplateModel): Promise<EventTemplate> {
        // Disable resizing on events with 30 minutes or less to prevent bugs from overlapping resize handles
        const duration = moment.duration(moment(item.end).diff(moment(item.start)));
        duration.asMinutes() <= 30 ? item.resizable = {beforeStart: false, afterEnd: false} :
        item.resizable = {beforeStart: true, afterEnd: true};


        console.log('item1', JSON.stringify(item))

        item.id = item.id || uuid();
        item.weekday = moment(item.start).weekday() - 1;

        if (item.weekday === -1) item.weekday = 6;
        delete item.recurrence;
        console.log('item2', JSON.stringify(item))
        console.log('ITEM', item);

        try {
            const result = await this.eventTemplateCollection.atomicUpsert(item);
            console.log(result)
            return new EventTemplate(result)
        } catch (e) {
            console.log({ error: e });
            console.log(item);
        }

    }

    changeStream(): Observable<DocumentChange<EventTemplate>> {
        return this.changes;
    }

    async single(id: string): Promise<EventTemplate> {
        return new EventTemplate((await this.eventTemplateCollection.findOne(id).exec()).toJSON());
    }

    migrateDate(date: Moment): Moment {
        const today = moment();
        const weekStart = today.locale('de').startOf('week');
        const hours = date.hour();
        const minutes = date.minute();
        const target = weekStart.add(moment(date).locale('de').weekday(), 'days');
        return moment(`${target.format('YYYY-MM-DD')}T${(''+hours).padStart(2, '0')}:${(''+minutes).padStart(2, '0')}:00`);
    }

    get(groupIndex: number, startDay: number, endDay?: number): Observable<EventTemplate[]> {
        endDay = endDay || startDay;

        this.eventTemplateCollection
            .find()
            .where('weekday')
            .gte(startDay)
            .lte(endDay)
            .exec()
            .then(async items => {
                    await this.awaiter;
                    this.subject.next(
                        items.map(
                            item => {
                                let event = item.toJSON();
                                let lifeSection = this.lifeSections.find(x => x.id === event.lifeSectionId);
                                event.title = lifeSection.name;
                                event.start = new Date(this.migrateDate(moment(event.start)).toISOString());
                                event.end = new Date(this.migrateDate(moment(event.end)).toISOString());
                                event.color = {
                                    primary: lifeSection.color.code,
                                    secondary: Color(lifeSection.color.code).lighten(0.4).rgb().toString()
                                };
                                return new EventTemplate(event)
                            }
                        ).filter(x => x.groupIndex === groupIndex)
                    )
                }
            );

        return this.subject;
    }
}
