import {CollectionsDefinitions} from '@library/core-database/src/lib/definitions';
import {EventSchema} from '@library/core-models/src/schema/event.schema';
import {TimeframeSchema} from '@library/core-models/src/schema/timeframe.schema';
import {LifeSectionSchema} from '@library/core-models/src/schema/life-section.schema';
import {DayStatsSchema} from '@library/core-models/src/schema/day-stats.schema';
import {TimeframeEvaluationSchema} from '@library/core-models/src/schema/timeframe-evaluation.schema';
import {SettingsSchema} from '@library/core-models/src/schema/settings.schema';
import {DayEvaluationSchema} from '@library/core-models/src/schema/day-evaluation.schema';
import {EventTemplateSchema} from '@library/core-models/src/schema/event-template.schema';
import {SurveySchema} from '@library/core-models/src/schema/survey.schema';
import {SurveyResultSchema} from '@library/core-models/src/schema/survey-result.schema';
import {FormsSchema} from '@library/core-models/src/schema/forms.schema';
import {PatientStatsSchema} from '@library/core-models/src/schema/patient-stats.schema';

export const DATABASE_COLLECTIONS = {
    EventCollection: 'EventCollection',
    EventTemplateCollection: 'EventTemplateCollection',
    TimeframeCollection: 'TimeframeCollection',
    LifeSectionCollection: 'LifeSectionCollection',
    DayStatsCollection: 'DayStatsCollection',
    TimeframeEvaluationCollection: 'TimeframeEvaluationCollection',
    SettingsCollection: 'SettingsCollection',
    DayEvaluationCollection: 'DayEvaluationCollection',
    SurveyCollection: 'SurveyCollection',
    SurveyResultCollection: 'SurveyResultCollection',
    FormCollection: 'FormCollection',
    PatientStatsCollection: 'PatientStatsCollection'
};

export const DATABASE_SCHEMA_NAMES = {
    events: 'EventCollection',
    timeframes: 'TimeframeCollection',
    life_sections: 'LifeSectionCollection',
    day_stats: 'DayStatsCollection',
    timeframe_evaluations: 'TimeframeEvaluationCollection',
    settings: 'SettingsCollection',
    day_evaluations: 'DayEvaluationCollection',
    event_templates: 'EventTemplateCollection',
    surveys: 'SurveyCollection',
    survey_results: 'SurveyResultCollection',
    forms: 'FormCollection',
    patient_stats: 'PatientStatsCollection'
};

export const DATABASE_MODEL_SCHEMA: CollectionsDefinitions = {
    EventCollection: {
        name: 'events',
        autoMigrate: true,
        schema: EventSchema,
        migrationStrategies: {
            1: function (doc) {
                return doc;
            },
            2: function (doc) {
                if(doc.recurrence && doc.recurrence.byDayOfWeek && !doc.recurrence.byDayOfWeek.isArray()) {
                    doc.recurrence.byDayOfWeek = [doc.recurrence.byDayOfWeek];
                }
                return doc;
            }
        }
    },
    TimeframeCollection: {
        name: 'timeframes',
        autoMigrate: true,
        schema: TimeframeSchema,
        migrationStrategies: {
            1: function (doc) {
                doc.tasks = [];
                return doc;
            }
        }
    },
    LifeSectionCollection: {
        name: 'life_sections',
        autoMigrate: true,
        schema: LifeSectionSchema,
        migrationStrategies: {
            1: function (doc) {
                doc.shortName = doc.name.substr(0, 3);
                return doc;
            },
            2: function (doc) {
                doc.tasks = [];
                return doc;
            }
        }
    },
    DayStatsCollection: {
        name: 'day_stats',
        autoMigrate: true,
        schema: DayStatsSchema,
        migrationStrategies: {
            1: function (doc) { return doc },
            2: function (doc) {
                if (!doc.pendingDuration)
                    doc.pendingDuration = 0;
                return doc
            },
            3: function (doc) {
                if (!doc.pendingDuration)
                    doc.eventTemplates = [];
                return doc
            },
            4: function (doc) {
                if (!doc.timeframes)
                    doc.timeframes = [];
                return doc;
            },
            5: function (doc) {
                doc.templateGroupIndex = 0;
                return doc;
            }
        }
    },
    TimeframeEvaluationCollection: {
        name: 'timeframe_evaluations',
        autoMigrate: true,
        schema: TimeframeEvaluationSchema
    },
    SettingsCollection: {
        name: 'settings',
        autoMigrate: true,
        schema: SettingsSchema
    },
    DayEvaluationCollection: {
        name: 'day_evaluations',
        autoMigrate: true,
        schema: DayEvaluationSchema
    },
    EventTemplateCollection: {
        name: 'event_templates',
        autoMigrate: true,
        schema: EventTemplateSchema,
        migrationStrategies: {
            1: function (doc) {
                return doc;
            }
        }
    },
    SurveyCollection: {
        name: 'surveys',
        autoMigrate: true,
        schema: SurveySchema,
        migrationStrategies: {
            1: function (doc) {
                if (!doc.type)
                    doc.type = 'dynamic';
                return doc;
            },
            2: function (doc){
                return doc;
            },
            3: function (doc){
                if (!doc.labels) {
                    doc.labels = {
                        menu_item_title: 'Depressivität',
                        statistic_title: 'PHQ-9 Auswertung',
                        statistic_description: 'Diese Grafik zeigt Dir die Ergebnisse Deiner PHQ-9 Antworten an.',
                        statistic_graph_name: 'Depressivität',
                        statistic_graph_color: '#0066CC',
                        statistic_max_ticks: 27,
                        survey_name: 'PHQ-9',
                        stateball_good_label: 'Gesamtwert der PHQ-9: Minimale Symptomatik',
                        stateball_medium_label: 'Gesamtwert der PHQ-9: Milde Symptomatik',
                        stateball_bad_label: 'Gesamtwert der PHQ-9: Mittelgradige Symptomatik',
                        stateball_critical_label: 'Gesamtwert der PHQ-9: Schwere depressive Symptomatik',
                        stateball_good_max_value: 4,
                        stateball_medium_max_value: 9,
                        stateball_bad_max_value: 14,
                        stateball_critical_max_value: 27
                    };
                }
                return doc;
            }
        }
    },
    SurveyResultCollection: {
        name: 'survey_results',
        autoMigrate: true,
        schema: SurveyResultSchema,
        migrationStrategies: {
            1: function (doc) {
                if (!doc.surveyEvaluationType) {
                    doc.surveyEvaluationType = 'PHQ-9';
                }
                return doc;
            }
        }
    },
    FormCollection: {
        name: 'forms',
        autoMigrate: true,
        schema: FormsSchema
    },
    PatientStatsCollection: {
        name: 'patient_stats',
        autoMigrate: true,
        schema: PatientStatsSchema
    }
};
