<ion-card *ngIf="!disableTitle">
    <ion-card-header>
        <ion-item class="no-padding">
            <ion-icon name="create" class="ion-margin-end"></ion-icon>
            <ion-label>
                <h5>Titel oder Bezeichnung</h5>
            </ion-label>
        </ion-item>
    </ion-card-header>
    <ion-card-content>
        <ion-item>
            <ion-input [(ngModel)]="event.title" spellcheck="true" autoComplete="true" autocorrect="true" placeholder="" (ngModelChange)="saveChanges()"></ion-input>
        </ion-item>
    </ion-card-content>
</ion-card>

<ion-card *ngIf="!disableDateChange">
    <ion-card-header>
        <ion-item class="no-padding">
            <ion-icon name="calendar" class="ion-margin-end"></ion-icon>
            <ion-label>
                <h5>Datum & Uhrzeit</h5>
            </ion-label>
        </ion-item>
    </ion-card-header>
    <ion-card-content>
        <ion-item *ngIf="!disableAllDay" lines="none">
            <div class="full-width">Ganztägig</div>
            <mat-slide-toggle style="text-align: right" color="primary" [(ngModel)]="event.allDay" (ngModelChange)="saveChanges()"></mat-slide-toggle>
        </ion-item>
        <ion-item lines="none">
            <input matInput style="max-width: 50%;" [matDatepicker]="startDateRef" [(ngModel)]="startDate" placeholder="" (click)="startDateRef.open()" (ngModelChange)="saveChanges()">
            <mat-datepicker touchUi #startDateRef></mat-datepicker>
            <ion-datetime slot="end" style="text-align: right" display-format="HH:mm" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55" doneText="Fertig" cancelText="Abbrechen" [(ngModel)]="startTime" (ngModelChange)="saveChanges(true)"></ion-datetime>
        </ion-item>
        <ion-item lines="none">
            <input matInput style="max-width: 50%;" [matDatepicker]="endDateRef" [(ngModel)]="endDate" placeholder="" (click)="endDateRef.open()" (ngModelChange)="saveChanges()">
            <mat-datepicker touchUi #endDateRef></mat-datepicker>
            <ion-datetime slot="end" style="text-align: right" display-format="HH:mm" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55" doneText="Fertig" cancelText="Abbrechen" [(ngModel)]="endTime" (ngModelChange)="saveChanges()"></ion-datetime>
        </ion-item>
    </ion-card-content>
</ion-card>

<ion-card *ngIf="disableDateChange">
    <ion-card-header>
        <ion-item class="no-padding">
            <ion-icon name="calendar" class="ion-margin-end"></ion-icon>
            <ion-label>
                <h5>Uhrzeit</h5>
            </ion-label>
        </ion-item>
    </ion-card-header>
    <ion-card-content>
        <ion-item *ngIf="!disableAllDay" lines="none">
            <div class="full-width">Ganztägig</div>
            <mat-slide-toggle style="text-align: right" color="primary" [(ngModel)]="event.allDay" (ngModelChange)="saveChanges()"></mat-slide-toggle>
        </ion-item>
        <ion-item lines="none">
            <ion-label position="fixed" slot="start">Startzeit</ion-label>
            <ion-datetime slot="end" display-format="HH:mm" doneText="Fertig" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55" cancelText="Abbrechen" [(ngModel)]="startTime" (ngModelChange)="saveChanges(true)"></ion-datetime>
        </ion-item>
        <ion-item lines="none">
            <ion-label position="fixed" slot="start">Endzeit</ion-label>
            <ion-datetime slot="end" display-format="HH:mm" doneText="Fertig" minuteValues="0,5,10,15,20,25,30,35,40,45,50,55" cancelText="Abbrechen" [(ngModel)]="endTime" (ngModelChange)="saveChanges()"></ion-datetime>
        </ion-item>
    </ion-card-content>
</ion-card>

<ion-card *ngIf="!disableRecurrence">
    <ion-card-header>
        <ion-item class="no-padding">
            <ion-icon name="infinite" class="ion-margin-end"></ion-icon>
            <ion-label>
                <h5>Wiederholungen</h5>
            </ion-label>
        </ion-item>
    </ion-card-header>
    <ion-card-content>
        <ion-radio-group class="recurrence-selection" [(ngModel)]="recurrenceFrequency" (ngModelChange)="saveChanges()">
            <ion-item-group>
                <ion-item lines="none">
                    <ion-label>Einmalig</ion-label>
                    <ion-radio value="NONE" checked></ion-radio>
                </ion-item>
                <ion-item lines="none">
                    <ion-label>Täglich</ion-label>
                    <ion-radio value="DAILY"></ion-radio>
                </ion-item>
                <ion-item lines="none">
                    <ion-label>Wöchentlich</ion-label>
                    <ion-radio value="WEEKLY"></ion-radio>
                </ion-item>
                <ion-item lines="none">
                    <ion-label>Monatlich</ion-label>
                    <ion-radio value="MONTHLY"></ion-radio>
                </ion-item>
                <ion-item lines="none">
                    <ion-label>Jährlich</ion-label>
                    <ion-radio value="YEARLY"></ion-radio>
                </ion-item>
            </ion-item-group>
        </ion-radio-group>
        <ion-item-group>
            <ion-item lines="none">
                <mat-checkbox class="checkbox-margin" [(ngModel)]="recurrenceEndEnabled" (change)="toggleRecurrenceEnd()">
                    <div class="spacer">&nbsp;</div>
                </mat-checkbox>
                <mat-form-field class="full-width">
                    <input matInput placeholder=""
                           [matDatepicker]="recurrenceEndDateRef" [(ngModel)]="recurrenceEndDate"
                           [disabled]="!recurrenceEndEnabled"
                           (focus)="recurrenceEndDateRef.open()" (ngModelChange)="saveChanges()">
                    <mat-datepicker touchUi #recurrenceEndDateRef></mat-datepicker>
                    <mat-hint>Wiederholen bis</mat-hint>
                </mat-form-field>
            </ion-item>
        </ion-item-group>
    </ion-card-content>
</ion-card>

<ng-content></ng-content>

<mat-form-field [hidden]="true">
    <input matInput />
</mat-form-field>
