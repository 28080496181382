import {SurveyStepModel} from 'survey/models/survey-step.model';
import {BehaviorSubject, Observable} from 'rxjs';

export const MAX_DISPLAYED_STEPS = 7;

export class SurveyStepperModel {
    private readonly subject: BehaviorSubject<SurveyStepModel[]>;

    currentDisplayedIndex: number;
    currentStep: SurveyStepModel;
    steps: SurveyStepModel[];
    displayedSteps: SurveyStepModel[];

    get currentStepIndex(): number {
        return this.currentStep.index
    }

    constructor(steps: SurveyStepModel[]) {
        this.steps = steps;
        this.currentStep = this.steps[0];
        this.currentDisplayedIndex = 0;
        this.assignDisplayedSteps();

        this.subject = new BehaviorSubject(this.displayedSteps);
    }

    assignDisplayedSteps() {
        this.displayedSteps = this.steps.slice(this.currentStepIndex, this.currentStepIndex + MAX_DISPLAYED_STEPS);
    }

    update(newIndex) {
        this.currentDisplayedIndex = newIndex;
        if (this.currentDisplayedIndex < MAX_DISPLAYED_STEPS - 1)
            return;

        this.assignDisplayedSteps();
        this.subject.next(this.displayedSteps);
    }

    getSteps(): Observable<SurveyStepModel[]> {
        return this.subject;
    }
}