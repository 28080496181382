import {Injectable} from '@angular/core';
import {DatabaseCollectionProvider, DatabaseRepository, DatabaseService, DbContext} from '@core/database';
import {LifeSectionModel, TimeframeEvaluationModel, TimeframeModel} from '@core/models';
import {TimeframeService} from '@library/plugin-timeframe/src/lib/timeframe.service';
import {Moment} from 'moment';
import {DayStatisticService} from '@library/plugin-statistics/src/lib/services/day-statistic.service';
import * as moment from 'moment';
import {RxCollection, RxDocument} from 'rxdb';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';

@Injectable({
    providedIn: 'root'
})
export class TimeframeEvaluationService {
    private collection: RxCollection<TimeframeEvaluationModel>;
    private lifeSectionCollection: RxCollection<LifeSectionModel>;

    constructor(private databaseService: DatabaseService,
                private timeframeService: TimeframeService,
                private dayStatisticService: DayStatisticService)
    {
        databaseService.databaseAwaiter().then(db => {
            this.collection = db.use<TimeframeEvaluationModel>(DATABASE_COLLECTIONS.TimeframeEvaluationCollection);
            this.lifeSectionCollection = db.use<LifeSectionModel>(DATABASE_COLLECTIONS.LifeSectionCollection);
        })
    }

    async get(timeframeId: string, date: Moment): Promise<TimeframeEvaluationModel> {
        await this.databaseService.databaseAwaiter();

        let item = await this.collection.findOne(timeframeId).exec();
        if (item) {
            return item.toJSON();
        }

        return (await this.collection.insert({
            timeframeId: timeframeId,
            timeframeSnapshot: await this.timeframeService.single(timeframeId),
            conflict: { },
            resolution: { },
            eventDate: <any>date.startOf('day').toISOString()
        })).toJSON();
    }

    async submit(item: TimeframeEvaluationModel): Promise<TimeframeEvaluationModel> {
        if (item.readonly)
            return;

        let timeframe: RxDocument<TimeframeModel> = await this.timeframeService.single(item.timeframeId, false);
        await timeframe.atomicUpdate(doc => {
            doc.done = true;
            doc.canceled = false;
            doc.cancelReason = null;
            return doc;
        });

        let tasks = [];
        let timeframeData = timeframe.toJSON();
        for (let task of timeframeData.tasks) {
            if (task.multi || !task.checked)
                tasks.push(task);
        }

        if (tasks.length) {
            tasks.forEach(x => x.checked = false);
            let lifeSection = await this.lifeSectionCollection.findOne(timeframeData.lifeSectionId).exec();
            // filters the tasks to avoid double entries in the lifesection
            tasks = tasks.filter(task => lifeSection.tasks.findIndex(lifesectionTask => lifesectionTask.text.trim() == task.text.trim()) == -1);
            await lifeSection.atomicUpdate(doc => {
                doc.tasks.push(...tasks);
                return doc;
            });
        }

        let evaluation = await this.collection.findOne(item.timeframeId).exec();
        await evaluation.atomicUpdate(doc => {
            doc.timeframeSnapshot.resolution = item.timeframeSnapshot.resolution;
            doc.timeframeSnapshot.conflict = item.timeframeSnapshot.conflict;

            doc.conflict = item.conflict || null;
            doc.wellbeing = item.wellbeing || null;
            doc.resolution = item.resolution || null;

            if (!doc.timeframeSnapshot.resolution)
                doc.resolution = null;
            if (!doc.timeframeSnapshot.conflict)
                doc.conflict = null;
            else if (doc.conflict && !doc.conflict.occurred)
                item.conflict.solutionRating = null;

            doc.timeframeSnapshot = timeframe.toJSON();
            doc.created = <any>new Date(Date.now()).toISOString();
            doc.readonly = true;
            doc.durationVariance = item.durationVariance;

            return doc;
        });

        await this.dayStatisticService.updateDayStatistic(moment(item.eventDate));
        return evaluation.toJSON();
    }
}
