import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {CalendarDataSource} from 'calendar/datasources/calendar.datasource';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'plugin-calendar-event-edit',
  templateUrl: './calendar-event-edit.component.html',
  styleUrls: ['./calendar-event-edit.component.scss']
})
export class CalendarEventEditComponent implements OnInit {
    event: EventDefinition<any>;

    constructor(private eventDataSource: CalendarDataSource<any>,
                private router: Router,
                private route: ActivatedRoute,
                private location: Location) { }

    saveEvent(event: EventDefinition<any>) {
        this.eventDataSource.save(event).then(() => {
            this.event = null;
            setTimeout(async () => {
                this.location.back();
            }, 800);
        });
    }

    init(id: any) {
        setTimeout(async () => this.event = await this.eventDataSource.single(id), 800);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['id'])
                this.init(params['id']);
        });

    }
}
