import {EventTemplateModel, LifeSectionModel, LifeSectionStatsModel, TimeframeEvaluationModel, TimeframeModel} from '@core/models';
import {UnorderedMap} from '@core/shared';
import * as moment from 'moment';

export class LifeSectionStats implements LifeSectionStatsModel {
    lifeSectionId: string;
    lifeSection: LifeSectionModel;

    canceledTimeframeIds: string[];
    doneTimeframeIds: string[];
    pendingTimeframeIds: string[];

    averageConflictRating: number;
    averageResolutionRating: number;
    averageWellbeing: number;

    conflictRating: UnorderedMap<number>;
    resolutionRating: UnorderedMap<number>;
    timeframeWellbeing: UnorderedMap<number>;

    totalActualDuration: number;
    totalPlannedDuration: number;
    totalTemplateDuration: number;
    pendingDuration: number;
    canceledDuration: number;

    private readonly _templates: EventTemplateModel[];
    private readonly _timeframes: TimeframeModel[];
    private readonly _evaluations: TimeframeEvaluationModel[];

    constructor(data?: LifeSectionStatsModel) {
        this._timeframes = [];
        this._evaluations = [];
        this._templates = [];

        if (data) {
            Object.assign(this, data);

            this.conflictRating = new UnorderedMap(data.conflictRating);
            this.resolutionRating = new UnorderedMap(data.resolutionRating);
            this.timeframeWellbeing = new UnorderedMap(data.timeframeWellbeing);
        }
    }

    addTimeframe(item: TimeframeModel) {
        if (item.lifeSectionId !== this.lifeSectionId)
            throw new Error('can not add timeframe to life-section-stats! life-section does not match!');
        this._timeframes.push(item);
    }

    addEvaluation(item: TimeframeEvaluationModel) {
        if (item.timeframeSnapshot.lifeSectionId !== this.lifeSectionId)
            throw new Error('can not add evaluation to life-section-stats! life-section does not match!');
        this._evaluations.push(item);
    }

    addTemplate(item: EventTemplateModel) {
        if (item.lifeSectionId !== this.lifeSectionId)
            throw new Error('can not add template to life-section-stats! life-section does not match!');
        this._templates.push(item);
    }

    updateTimeframeStats(item: TimeframeModel) {
        const start = moment(item.start);
        const end = moment(item.end);

        const timeDiff = end.diff(start, 'minutes');
        this.totalPlannedDuration += timeDiff;

        if (!item.done) {
            this.pendingDuration += timeDiff;
        }
    }

    updateEvaluationStats(item: TimeframeEvaluationModel) {
        if (!item.timeframeSnapshot.done)
            return;

        if (item.conflict) {
            this.conflictRating.set(item.timeframeId, item.conflict.solutionRating);
        }

        if (item.resolution) {
            this.resolutionRating.set(item.timeframeId, item.resolution.rating);
        }

        if (item.wellbeing) {
            this.timeframeWellbeing.set(item.timeframeId, item.wellbeing);
        }

        const start = moment(item.timeframeSnapshot.start);
        const end = moment(item.timeframeSnapshot.end);

        this.totalActualDuration += end.diff(start, 'minutes');
        if (item.durationVariance)
            this.totalActualDuration += item.durationVariance;
    }

    updateTemplateStats(item: EventTemplateModel) {
        this.totalTemplateDuration += moment(item.end).diff(item.start, 'minutes');
    }

    getAverage(ratings: UnorderedMap<number>): number {
        if (!ratings.count())
            return null;

        if (ratings.count() === 1)
            return [...ratings.values()][0];

        let sum = [...ratings.values()].reduce(
            (previous, current) => current += previous
        );

        return Math.floor(sum / ratings.size);
    }

    writeStats() {
        this.totalTemplateDuration = 0;
        this.totalActualDuration = 0;
        this.totalPlannedDuration = 0;

        for (let item of this._evaluations)
            this.updateEvaluationStats(item);

        for (let item of this._timeframes)
            this.updateTimeframeStats(item);

        for (let item of this._templates)
            this.updateTemplateStats(item);

        this.doneTimeframeIds = this._timeframes.filter(x => x.done).map(x => x.id);
        this.canceledTimeframeIds = this._timeframes.filter(x => x.canceled).map(x => x.id);
        this.pendingTimeframeIds = this._timeframes.filter(x => !x.done && !x.canceled).map(x => x.id);

        this.writeAverageRatings();
    }

    writeAverageRatings() {
        this.averageConflictRating = this.getAverage(this.conflictRating);
        this.averageResolutionRating = this.getAverage(this.resolutionRating);
        this.averageWellbeing = this.getAverage(this.timeframeWellbeing);
    }

    toData(): LifeSectionStatsModel {
        return {
            lifeSectionId: this.lifeSectionId,
            canceledTimeframeIds: this.canceledTimeframeIds,
            doneTimeframeIds: this.doneTimeframeIds,
            pendingTimeframeIds: this.pendingTimeframeIds,

            averageConflictRating: this.averageConflictRating,
            averageResolutionRating: this.averageResolutionRating,
            averageWellbeing: this.averageWellbeing,

            conflictRating: this.conflictRating.toJSON(),
            resolutionRating: this.resolutionRating.toJSON(),
            timeframeWellbeing: this.timeframeWellbeing.toJSON(),

            totalActualDuration: this.totalActualDuration,
            totalPlannedDuration: this.totalPlannedDuration,
            totalTemplateDuration: this.totalTemplateDuration
        }
    }
}
