import {RxJsonSchema} from 'rxdb';

export const DayEvaluationSchema: RxJsonSchema | any = {
    "version": 0,
    "type": "object",
    "title": "Day Evaluation",
    "properties": {
        "day": {
            "type": "string",
            "primary": true,
            "final": true
        },
        "wellbeing": {
            "type": ["number"]
        },
        "balanceSatisfaction": {
            "type": ["number"]
        },
        "created": {
            "type": ["number"]
        }
    }
};
