import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CloudConnectComponent} from '@library/plugin-cloud-connect/src/lib/cloud-connect.component';
import {CloudConnectService} from '@library/plugin-cloud-connect/src/lib/cloud-connect.service';
import {SDKBrowserModule} from '@library/plugin-cloud-connect/src/lib/api';

@NgModule({
    imports: [
        CommonModule,
        SDKBrowserModule.forRoot()
    ],
    declarations: [
        CloudConnectComponent
    ],
    providers: [
        CloudConnectService
    ],
    exports: [
        CloudConnectComponent
    ]
})
export class CloudConnectModule {
    constructor() {}
}
