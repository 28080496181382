import {DbContext, FindFilter} from '@library/core-database/src/lib/db/db-context';
import Database = PouchDB.Database;
import {BaseModel, DocumentIndex, DocumentIndexCreator} from '@core/models';
import {isNumber, isObject, isString} from 'util';
import FindRequest = PouchDB.Find.FindRequest;
import {Observable, Subject} from 'rxjs';
import uuid from 'uuid-random';

export class PouchDbContext<T extends BaseModel> implements DbContext<T> {
    private onChangeSubject: Subject<any> = new Subject();

    constructor(private db: Database, private modelName: string, private indexCreator: DocumentIndexCreator<T>) {
        console.log('init context');
    }

    async remove(item: T): Promise<any> {
        item['doc__deleted'] = true;
        item = await this.save(item);
        if (item['_fullIndexedId'])
            item = await this.findById(item['_fullIndexedId'], true);
        else
            item = await this.findById(item._id);

        item['_id'] = item['_fullIndexedId'] ? item['_fullIndexedId'] : this.getInternalId(item);
        await this.db.remove(<any>item);
        return item;
    }

    async find(filter?: FindFilter): Promise<T[]> {
        if (!filter) {
            filter = {
                selector: {
                    $$namespace$$: this.getNamespace(),
                    $not: { 'doc__deleted': true }
                }
            };
        } else if (!filter.selector) {
            filter.selector = {
                $$namespace$$: this.getNamespace(),
                $not: { 'doc__deleted': true }
            };
        } else {
            if (filter.selector.$and) {
                filter.selector.$and.push({ $$namespace$$: this.getNamespace() })
            } else {
                filter.selector['$$namespace$$'] = this.getNamespace();
            }
            filter.selector['$not'] = { 'doc__deleted': true };
        }

        let result = (await this.db.find(filter));
        return (result.docs as any).map(item => {
            delete item['$$namespace$$'];
            item['_fullIndexedId'] = result['_id'];
            item['_id'] = item['_id'].replace(`${this.getNamespace()}/`, '');
            return item;
        });
    }

    async findById(id: string | any, indexGiven?: boolean): Promise<T> {
        console.log('find', id, indexGiven, this.modelName);
        if (indexGiven) {
            let result = (await this.db.get(id, { latest: true })) as any;
            delete result['$$namespace$$'];
            result['_fullIndexedId'] = result['_id'];
            result['_id'] = result['_id'].replace(`${this.getNamespace()}/`, '');
            return result;
        } else {
            let result = (await this.db.get(this.getInternalId(id), { latest: true })) as any;
            delete result['$$namespace$$'];
            result['_fullIndexedId'] = result['_id'];
            result['_id'] = result['_id'].replace(`${this.getNamespace()}/`, '');
            return result;
        }
    }

    async save(item: T): Promise<T> {
        if (!item) {
            throw new Error('can not save to pouchDb. item is null!');
        }

        item = JSON.parse(JSON.stringify(item));
        if (item['_fullIndexedId']) {
            item['_id'] = item['_fullIndexedId'];
            delete item['_fullIndexedId'];
        } else {
            const isNew = !item['_id'];
            if (isNew) {
                item['_id'] = this.nextDocId(item);
            } else {
                item['_id'] = this.getInternalId(item);
            }
        }

        item['$$namespace$$'] = this.getNamespace();

        let result = await this.db.put(<any>item);
        item._id = result.id.split('/').pop();
        item['_fullIndexedId'] = result.id;
        item._rev = result.rev;
        return item;
    }

    async upsert(item: any | T) {
        console.warn('using context.upsert is insecure. please use context.save instead.');
        return (await this.db.put(item)).id;
    }

    async exists(filter: FindRequest<T> | string | T): Promise<boolean> {
        console.log('exists', filter);
        if (isObject(filter) && filter['selector'])
            return (await this.db.find(<any>filter)).docs.length !== 0;

        try {
            const id = this.getInternalId(<any>filter);
            await this.db.get(id);
            return true;
        } catch (e) {
            return false;
        }
    }

    getInternalId(item: string | number | T): string {
        if (isString(item) || isNumber(item))
            return `${this.getNamespace()}/${item}`;
        return `${this.getPrefix(item)}/${item['_id']}`;
    }

    getChangeStream(): Observable<any> {
        return this.onChangeSubject.asObservable();
    }

    private getNamespace(): string {
        return `App/Model/${this.modelName}`;
    }

    private getPrefix(item: T): string {
        let idx = this.indexCreator(item);
        if (idx.index)
            return `${this.getNamespace()}/${this.indexCreator(item).index}`;
        return `${this.getNamespace()}`
    }

    private nextDocId(item: T): string {
        return `${this.getNamespace()}/${this.indexCreator(item, uuid()).fullIndexedId}`;
    }
}
