import {Component, OnInit, ViewChild} from '@angular/core';
// @ts-ignore
import PouchDB from 'pouchdb';
// @ts-ignore
import PouchDBFind from 'pouchdb-find';
import {DatabaseCollectionProvider, DatabaseService, SyncSettings} from '@core/database';
import {SideMenu} from './menu/side-menu';
import {FeaturedEventsManagerService} from 'calendar/services/featured-events-manager.service';
import {DayEvaluationEventService} from '@library/plugin-day-evaluation/src/lib/services/day-evaluation-event.service';
import {AppSettings} from '@plugin/settings';
import {AlertController, LoadingController, NavController, Platform} from '@ionic/angular';
import {DeviceNotificationService} from '@plugin/device-notifications';
import {NotificationSchedulerService} from './notifications/notification-scheduler.service';
import {CalendarEventInterceptor} from './notifications/listeners/calendar-event.interceptor';
import {SurveyEventService} from 'survey/services/survey-event.service';
import {DayStatisticService} from '@plugin/statistics';
import {SetupService} from './setup.service';
import {Subscription, timer} from 'rxjs';
import {environment} from '../environments/environment';
import {PinLockService} from './pin-lock/pin-lock.service';
import {StatsSummaryService} from './stats-summary/stats-summary.service';
import * as moment from 'moment';
import {AppGlobals} from './app.global';
import {ThemeService} from './theme.service';
import {DeviceService} from './device.service';
import {CloudConnectService} from '@plugin/cloud-connect';

import * as Sentry from 'sentry-cordova';
import {Severity} from 'sentry-cordova';
import {LoggingService} from './logging.service';
import {MatDrawer} from '@angular/material/sidenav';
import {DataUpdateService} from '../updates/data-update.service';
import {UpdateService} from './update.service';
import { DATABASE_COLLECTIONS } from '@library/core-models/src/schema';
import { LifeSectionModel, SurveyModel } from '@library/core-models';
import { SurveyProviderService } from './survey/services/survey-provider.service';
import {JobService} from './job-runner/job.service';
import { TrialService } from './trial.service';
import { LifeSectionService } from '@library/plugin-life-section/src/public_api';
import { PrivacyPolicyService } from './privacy-policy/privacy-policy.service';
import { AppointmentsService } from './appointments.service';
import { Router } from '@angular/router';
import { BarcodeScanner } from 'capacitor-barcode-scanner';
import { LiveUpdateService } from '../updates/live-update.service';

if (environment.enableSentryMonitoring) {
    Sentry.init({ release: environment.version, dsn: 'https://23b8e2b40ff14025a3713762d606b10f@sentry.solutec.de/2' });
}

PouchDB.plugin(PouchDBFind);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild('drawer', { static: true }) drawer: MatDrawer;

    appPages: Array<any> = SideMenu;
    view: 'app' | 'login' | 'pin' | 'privacy-policy';
    ready: boolean;
    value: any;
    mode: string;
    syncSettings: SyncSettings;
    disableSync: boolean;
    connecting: boolean;
    trialExpired = false;
    settingsSub: Subscription;
    private medianExtendedTrialCode = 'Median2023';
    private lifeSections: LifeSectionModel[] = [];

    constructor(
        private featureManager: FeaturedEventsManagerService,
        private themeService: ThemeService,
        private dayEvaluationEvent: DayEvaluationEventService,
        private surveyEvent: SurveyEventService,
        private navController: NavController,
        private notificationService: DeviceNotificationService,
        private notificationScheduler: NotificationSchedulerService,
        private calendarEventInterceptor: CalendarEventInterceptor,
        private jobService: JobService,
        private dayStatsService: DayStatisticService,
        private databaseService: DatabaseService,
        private settings: AppSettings,
        private appGlobals: AppGlobals,
        private alertService: AlertController,
        private db: DatabaseCollectionProvider,
        private platform: Platform,
        private pinLockService: PinLockService,
        private loadingController: LoadingController,
        private statsSummaryService: StatsSummaryService,
        private liveUpdateService: LiveUpdateService,
        private setupService: SetupService,
        private deviceService: DeviceService,
        private connectService: CloudConnectService,
        private updateService: UpdateService,
        private dataUpdateService: DataUpdateService,
        private log: LoggingService,
        private surveyProvider: SurveyProviderService,
        private trialService: TrialService,
        private lifeSectionService: LifeSectionService,
        private privacyPolicyService: PrivacyPolicyService,
        private appointmentsService: AppointmentsService,
        private router: Router
    ) {
        this.liveUpdateService.disableRollback();
    }

    gotToSettings() {
        this.navController.navigateForward('/settings');
    }

    cloudConnect() {
        this.navController.navigateForward('/connect');
    }

    async boot() {
        await this.databaseService.databaseAwaiter();
        let syncSettings = await this.db.getSyncSettings();
        await this.settings.awaiter();
        if (!environment.disableSync && !syncSettings) {
            this.view = 'login';
            if (this.settings.has('trial-expired') && this.settings.get('trial-expired') === true) {
              this.trialExpired = true;
            }
            try {
            } catch (e) { console.warn('no cordova environment') }
            return;
        }

        if (!await this.setupService.hasDefaultData())
            await this.setupService.createDefaultData();

        await this.notificationService.setup();
        this.notificationScheduler.register(this.calendarEventInterceptor);
        await this.notificationScheduler.start();

        // checks if lifesection.enableNotifications has changed and adjusts the local notifications
        this.lifeSections = await this.lifeSectionService.getAllOnce();
        this.lifeSectionService.observeChanges().subscribe(async (newLifeSections) => {
          let shouldUpdateNotifications = false;
          for(const lifesection of this.lifeSections) {
            const index = newLifeSections.findIndex(x => x.id === lifesection.id);
            if (index === -1 || lifesection.enableNotifications !== newLifeSections[index].enableNotifications) {
              shouldUpdateNotifications = true;
              break;
            }
          }
          if (shouldUpdateNotifications) {
            this.notificationScheduler.stop();
            this.notificationScheduler.register(this.calendarEventInterceptor);
            await this.notificationScheduler.start();
          }
        });

        this.dayEvaluationEvent.activate(this.settings, this.db, this.dayStatsService);
        this.featureManager.addProvider(this.dayEvaluationEvent);

        this.surveyEvent.activate(this.settings, this.db);
        this.featureManager.addProvider(this.surveyEvent);

        try {
            if (this.surveyProvider.shouldRecreateSurveyNotifications()) {
                await this.surveyProvider.removeSurveyNotifications();
                await this.surveyProvider.createSurveyNotifications();
            }

            await this.surveyProvider.removeDayEvaluationNotifications();
            await this.surveyProvider.createDayEvaluationNotifications();

        } catch (e) {
            this.log.writeEvent('error creating survey notifications', Sentry.Severity.Warning);
            console.warn('error creating survey notifications >', e);
        }


        try {
        } catch (e) { console.warn('no cordova environment') }

        this.view = 'pin';
        await this.pinLockService.waitForPin();

        if (!navigator.onLine) {
            this.checkForPatientTermination();
        }

        this.view = 'app';
        await this.statsSummaryService.update();
        this.checkForDataUpdates();

        if(this.settings.get('version') === null) {
            const os = this.platform.is('android') ? '-android' : this.platform.is('ios') ? '-ios' : '-gen';
            this.settings.set('version', environment.version + os);
        }

        if (this.settings.get('init-timestamp') === null) {
            this.settings.set('init-timestamp', moment().subtract(1, 'month').unix());
        }
        if (this.settings.get('notification-time') === null) {
            this.settings.set('notification-time', moment().hour(13).minute(0).second(0).toISOString());
        }
        if (this.settings.get('day-evaluation-notification-time') === null) {
            this.settings.set('day-evaluation-notification-time', moment().hour(19).minute(0).second(0).toISOString());
        }
        console.log('before fetch')
        await this.settings.fetchRemote();


        console.log('running pending jobs')
        await this.jobService.runAllPending();

        console.log('after fetch');
        await this.databaseService.pullData([DATABASE_COLLECTIONS.SettingsCollection]);

        this.databaseService.startLiveDBReplication().then(async () => {
            console.log('sync finished, setting last-data-sync date');
            this.settings.set('last-data-sync', moment().toISOString());

            this.checkForPatientTermination();
            await this.checkForAppointments();
            this.updateSettingsSub();
         });

         // uncomment this to enable the alert popup for the sociodemographic data
        // await this.formService.check();

        console.log('before stats summary')
        this.statsSummaryService.startUpdater();

        if (this.settings.has('trial-start') && this.settings.get('trial-expired') !== true) {
            console.log('this.settings.has(\'trial-start\') && this.settings.get(\'trial-expired\') !== true');
            this.trialService.startTrialTimeInterval();

            const notificationIds = this.settings.get('trial-notifications');

            if (!notificationIds || !notificationIds.length) {
                this.trialService.createTrialExpirationNotifications();
            }
        }

        const apiDomain = await this.databaseService.apiDomain();
        if (!apiDomain.includes('trial')) {
            this.trialService.dequeueTrialNotifications();
        }

        setTimeout(async () => {
            await this.updateService.checkForUpdates(true);
        }, 5000);

        setTimeout(async () => {
            await this.liveUpdateService.checkForUpdates();
        }, 5000);
    }

    checkForPatientTermination() {
        const terminationDate = this.settings.has('termination-date') ? this.settings.get('termination-date') : false;
        const continuedAfterTermination = this.settings.has('after-termination-connect-date') ? this.settings.get('after-termination-connect-date') : false;
        if (terminationDate && !continuedAfterTermination) {
            this.router.navigate(['termination-page']);
        }
    }

    async getLoader() {
        return await this.loadingController.create({
            id: 'create-entries',
            animated: true,
            message: 'Daten werden synchronisiert'
        });
    }

    async checkForAppointments() {
        await this.settings.loadFromDatabase();
        const appointments = this.settings.get('appointments', []);
        if (appointments.length) {
            this.appointmentsService.handle(appointments);
        }
    }

    updateSettingsSub() {
        if (this.settingsSub) {
            this.settingsSub.unsubscribe();
        }
        if (this.databaseService.settingsStream) {
            this.settingsSub = this.databaseService.settingsStream.docs$.subscribe(async (doc) => {
                if (doc.key === 'appointments') {
                    this.appointmentsService.handle(doc.value);
                }

                if (doc.key === 'termination-date') {
                    if (doc.value) {
                        this.settings.set('termination-date', doc.value);
                        this.router.navigate(['termination-page']);
                    }
                }
            });
        }
    }

    checkForDataUpdates() {
        setTimeout(async () => {
            if (await this.dataUpdateService.checkForUpdates()) {
                await this.dataUpdateService.installUpdate();
                await this.settings.loadFromDatabase();
            }
        }, 5000)
    }

    async onClickConnect(trial: boolean = false) {
        let extendedTrial = false;
        if (!this.value) {
            return;
        }

        if (!navigator.onLine) {
            this.displayNoInternetErrorMsg();
            return;
        }

        if (this.value === this.medianExtendedTrialCode) {
            extendedTrial = true;
            trial = true;
            if (this.settings.get('trial-expired') === true && this.settings.get('trial-extended')) {
                const dialog = await this.alertService.create({
                    header: 'Testzeitraum ist abgelaufen',
                    message: 'Setze Dich mit Deinem Coach in Verbindung, um einen neuen Zugang zu erhalten.',
                    buttons: [{ text: 'Okay', role: 'cancel' }]
                });
                dialog.present();
                return;
            } else {
                const token = await this.trialService.getTrialToken();
                this.value = token;
            }
        }

        if(!trial) {
          this.settings.set('trial-expired', true);
        }

        this.connect()
        .then(x => {
                if (extendedTrial) {
                    this.setTrialSettings(true);
                    this.trialService.startTrialTimeInterval();
                }

                setTimeout(() => {
                    window.location.href = '/tutorial';
                    this.connecting = false;
                }, 500)
            })
            .catch(async e => {
                console.log('catch', e);
                const AUTH_ERROR_ALREADY_IN_USE = 0x01;
                if (e.params && e.params.code === AUTH_ERROR_ALREADY_IN_USE) {
                    const dialog = await this.alertService.create({
                        header: 'Zugangscode wurde bereits verwendet',
                        message: 'Setze Dich mit Deinem Coach in Verbindung, um einen neuen zu erhalten.',
                        buttons: [{ text: 'Okay', role: 'cancel' }]
                    });
                    dialog.present();
                }
                this.connecting = false;
            });
    }

    scanCode() {
        BarcodeScanner
            .scan()
            .then(({ code }) => {
                this.value = code;
                this.onClickConnect();
            });
    }

    async connect() {
        if (this.connecting)
            return;

        this.connecting = true;

        this.log.write(`connecting new user ${this.value}`, Severity.Info);

        if (!this.privacyPolicyService.hasAcceptedPrivacyPolicy()) {
            const previousView = this.view
            this.view = 'privacy-policy';
            await this.privacyPolicyService.waitToAcceptPrivacyPolicy();
            this.view = previousView;
        }

        let done = false;
        return new Promise(async (resolve, reject) => {
            const loader = await this.getLoader();
            loader.present();

            await this.deviceService.complete();

            this.connectService.connect(this.value, this.deviceService.toJSON()).subscribe(
                async step => {
                    switch (step.stage) {
                        case 'init':
                        case 'connect':
                        case 'auth':
                            console.log('step', step);
                            break;
                        case 'already-connecting':
                            resolve(null);
                            break;
                        case 'finished':
                            console.log('step', step);
                            try {
                                let delayer = timer(2000).toPromise();
                                this.syncSettings = step.params.settings;
                                this.settings.set('study-number', step.params.settings.studyNumber);
                                this.settings.set('internalId', step.params.settings.internalId);
                                await this.db.saveSyncSettings(this.syncSettings);
                                await this.databaseService.syncNow();
                                await delayer;
                                await loader.dismiss();
                                done = true;
                                resolve(this.syncSettings);
                            } catch (e) {
                                await loader.dismiss();
                                console.error('connection error', e);
                                reject(e)
                                location.reload()
                            }
                            break;
                    }
                },
                async error => {
                    Sentry.captureException(error.originalError || error);
                    console.error('connection error', error);
                    if (!done)
                        await loader.dismiss();
                    reject(error);
                }
            )
        })

    }

    ngOnInit() {
        this.disableSync = environment.disableSync;
        this.view = 'app';
        this.platform.ready().then(async name => {
            try {
                await this.boot();
                console.log('boot done')
            } catch (e) {
                console.error(e);
            }

            await this.databaseService.databaseAwaiter();
            this.ready = true;
            this.mode = name;
            this.syncSettings = await this.db.getSyncSettings();
            this.updateDepressionChartMenuEntryTitle();

            if (!this.privacyPolicyService.hasAcceptedPrivacyPolicy()) {
                const previousView = this.view
                this.view = 'privacy-policy';
                await this.privacyPolicyService.waitToAcceptPrivacyPolicy();
                this.view = previousView;
            }

            if (this.platform.is('cordova')) {
                this.platform.resume.subscribe(async () => {
                    await this.databaseService.databaseAwaiter();
                    await this.settings.awaiter();

                    if (!navigator.onLine) {
                        this.checkForPatientTermination()
                    }

                    await this.databaseService.pullData([DATABASE_COLLECTIONS.SettingsCollection]);
                    if (this.databaseService.settingsStream) {
                        this.databaseService.settingsStream.cancel();
                    }


                    this.databaseService.startLiveDBReplication([DATABASE_COLLECTIONS.SettingsCollection]).then(async () => {
                        this.settings.set('last-data-sync', moment().toISOString());
                        await this.settings.loadFromDatabase();
                        this.checkForPatientTermination();
                        await this.checkForAppointments();
                        this.updateSettingsSub();
                    });

                    try {
                        if (this.surveyProvider.shouldRecreateSurveyNotifications()) {
                            await this.surveyProvider.removeSurveyNotifications();
                            await this.surveyProvider.createSurveyNotifications();
                        }
                    } catch (e) {
                        this.log.writeEvent('error creating survey notifications', Sentry.Severity.Warning);
                        console.warn('error creating survey notifications >', e);
                    }

                    await this.statsSummaryService.update()
                    await this.updateService.checkForUpdates(true);
                    this.checkForDataUpdates();
                });
            }
        })
    }

    async updateDepressionChartMenuEntryTitle() {
        await this.databaseService.databaseAwaiter();
        const surveyCollection = this.db.use(DATABASE_COLLECTIONS.SurveyCollection);
        const docs = await surveyCollection.find().exec();
        if (!docs || docs.length === 0) {
            return;
        }
        const survey: SurveyModel = (docs[0].toJSON() as SurveyModel);
        if (!survey.labels) {
            survey.labels = await this.surveyProvider.getSurveyLabels();
        }
        const surveyChartTitle = survey.labels.menu_item_title;
        const group = this.appPages.find(appGroup => appGroup.title === 'Auswertung');
        const item = group.items.find(el => el.feature === 'depression-chart');
        if (item.title !== surveyChartTitle) {
            item.title = surveyChartTitle;
        }
    }

    async connectToTrial() {
        await this.settings.awaiter();
        if (this.settings.get('trial-expired') === true) {
            location.reload();
            return;
        }
        const token = await this.trialService.getTrialToken();
        this.value = token;
        this.onClickConnect(true);
        this.setTrialSettings(false);
        this.trialService.startTrialTimeInterval();
    }

    private setTrialSettings(extendedTrial: boolean = false) {
        this.settings.set('feature-depression-chart', true);
        this.settings.set('feature-conflicts', true);
        this.settings.set('feature-resolutions', true);
        this.settings.set('trial-start', moment().toISOString());

        if (extendedTrial) {
            this.settings.set('trial-extended', true);
        }
    }

    async displayNoInternetErrorMsg() {
        const dialog = await this.alertService.create({
            message: 'Es konnte keine Internetverbindung hergestellt werden.',
            buttons: [
                {
                    text: 'Okay',
                    role: 'cancel'
                }
            ]
        });
        dialog.present();
    }
}
