import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SurveyGuard} from './survey/services/survey.guard';

const routes: Routes = [
    { path: '', redirectTo: 'todo', pathMatch: 'full' },
    { path: 'calendar', loadChildren: './calendar/calendar-page.module#CalendarPageModule' },
    { path: 'calendar-event', loadChildren: './calendar-event/calendar-event-page.module#CalendarEventPageModule' },
    { path: 'survey', loadChildren: './survey/survey-page.module#SurveyPageModule', canActivate: [SurveyGuard]},
    { path: 'charts', loadChildren: './charts-example/charts-example.module#ChartsExampleModule'},
    { path: 'connect', loadChildren: './connect/connect-page.module#ConnectPageModule'},
    { path: 'life-sections', loadChildren: './life-section/life-section-page.module#LifeSectionPageModule'},
    { path: 'life-section', loadChildren: './life-section-edit/life-section-edit-page.module#LifeSectionEditPageModule'},
    { path: 'slogan-editor', loadChildren: './slogan-editor/slogan-editor.module#SloganEditorModule'},
    { path: 'evaluate-day', loadChildren: './day-evaluation/day-evaluation-page.module#DayEvaluationPageModule'},
    { path: 'evaluate/timeframe', loadChildren: './timeframe-evaluation/timeframe-evaluation-page.module#TimeframeEvaluationPageModule'},
    { path: 'statistics', loadChildren: './statistics/statistic-page.module#StatisticPageModule'},
    { path: 'todo', loadChildren: './todo/todo-page.module#TodoPageModule'},
    { path: 'event-templates', loadChildren: './event-templates/event-templates-page.module#EventTemplatesPageModule'},
    { path: 'event-template', loadChildren: './event-template-edit/event-template-edit-page.module#EventTemplateEditPageModule'},
    { path: 'event-template-selection', loadChildren: './event-template-selection/event-template-selection-page.module#EventTemplateSelectionPageModule'},
    { path: 'exercise', loadChildren:'./exercise/exercise-page.module#ExercisePageModule'},
    { path: 'settings', loadChildren:'./settings/settings.module#SettingsModule'},
    { path: 'form', loadChildren:'./generic-form/generic-form.module#GenericFormModule'},
    { path: 'medical-certificate', loadChildren: './settings/medical-certificate/medical-certificate.module#MedicalCertificatePageModule' },
    { path: 'help-videos', loadChildren: './help-videos/help-videos.module#HelpVideosPageModule' },
    { path: 'tutorial', loadChildren: './app-tour-page/app-tour-page.module#AppTourPageModule' },
    { path: 'termination-page', loadChildren: './termination/termination.module#TerminationPageModule' },
    { path: 'final-survey', loadChildren: () => new Promise(() => { window.open('https://www.survio.com/survey/d/T5Q3N4R6W3F9X3B4M', '_system', 'location=yes') })}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
